import { Navigate, Route, Routes } from 'react-router-dom';
import FittingDashboard from './fitting-dashboard/fitting-dashboard';

const FittingRouting = () => {
  return (
    <Routes>
      <Route path='' element={<FittingDashboard />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default FittingRouting;
