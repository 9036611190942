import { useAuthState } from 'core/providers/AuthProvider';
import { FirestoreProvider } from 'core/providers/FirestoreProvider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthenticatedRoute = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthState();

  if (!isAuthenticated) {
    return <Navigate to='login' state={{ from: location }} replace />;
  }
  return isAuthenticated ? (
    <FirestoreProvider>
      <Outlet />
    </FirestoreProvider>
  ) : (
    <Navigate to='login' state={{ from: location }} replace />
  );
};

export default AuthenticatedRoute;
