import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export const multiTimestampsToSeconds = (stamps: any) => {
  const seconds = { ...stamps };
  Object.keys(stamps).forEach((stamp) => {
    const value = stamps[stamp];
    seconds[stamp] = value ? timestampToSeconds(value) : undefined;
  });
  return seconds;
};

export const timestampToSeconds = (stamp: Timestamp) => stamp.seconds;

export const secondsToDate = (seconds: number) => new Date(seconds * 1000);

export const secondsToDateString = (seconds: number) => dayjs(new Date(seconds * 1000)).format('DD/MM/YYYY, HH:mm');

export const todayAsUSString = () => dayjs(new Date()).format('YYYY-MM-DD');

export const USStringToUKString = (date: string) => dayjs(date).format('DD/MM/YYYY');

export const appointmentTimesToDate = (appointment: IAppointment) => {
  const start = appointment.start.split(':');
  const end = appointment.end.split(':');
  return {
    start: dayjs(appointment.date).hour(Number(start[0])).minute(Number(start[1])).toDate(),
    end: dayjs(appointment.date).hour(Number(end[0])).minute(Number(end[1])).toDate(),
  };
};
