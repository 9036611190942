import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { AppointmentType, getAppointmentTypeNameFromKey } from 'core/constants/appointment-type';
import dayjs from 'dayjs';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import { getAppointmentStatusNameFromKey } from 'core/constants/appointment-status';
import { getCustomerStatusNameFromKey } from 'core/constants/customer-status';

interface ICalendarAppointmentDetailDialog {
  appointment: IAppointment;
}

const CalendarAppointmentDetailDialog = ({ appointment }: ICalendarAppointmentDetailDialog) => {
  const { status, type, customer, assignee, date, start, end, details, confirmationEmailSent } = appointment;
  const sections = [
    {
      key: 'status',
      label: 'Status',
      value: getAppointmentStatusNameFromKey(status),
    },
    {
      key: 'type',
      label: 'Type',
      value: getAppointmentTypeNameFromKey(type),
    },
    {
      key: 'customer',
      label: 'Customer name',
      value: customer.fullName,
    },
    {
      key: 'customerStatus',
      label: 'Customer status',
      value: getCustomerStatusNameFromKey(customer.status),
    },
    {
      key: 'customerAddress',
      label: 'Customer address',
      value: customer.address?.formatted_address ?? 'Unknown address',
    },
    {
      key: 'customerPhoneNumber',
      label: 'Customer phone number',
      value: customer.phoneNumber ?? 'Unknown',
    },
    {
      key: 'assignee',
      label: 'Assignee',
      value: assignee.fullName,
    },
    {
      key: 'date',
      label: 'Date',
      value: dayjs(date).format('dddd, D MMMM YYYY'),
    },
    {
      key: 'time',
      label: 'Time',
      value: `${start} - ${end}`,
    },
    {
      key: 'details',
      label: 'Details',
      value: details,
      hidden: !isNotNullOrEmpty(details),
    },
    {
      key: 'confirmation',
      label: 'Confirmation email sent',
      value: confirmationEmailSent ? 'Yes' : 'No',
      hidden: [AppointmentType.HOME_TEST, AppointmentType.SHOP_TEST].every((appType) => appType !== type),
    },
  ];

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        {sections
          .filter((section) => !section.hidden)
          .map((section) => (
            <div key={section.key} className='mt-4 first:mt-0'>
              <p className='body-02 text-gray-500'>{section.label}</p>
              <p className='body-01 break-all whitespace-pre-wrap'>{section.value}</p>
            </div>
          ))}
      </div>
    );
  };
  return <SharedDialogBase title='Appointment detail' customContentTemplate={customContent()} showButtons />;
};

export default CalendarAppointmentDetailDialog;
