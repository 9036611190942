import { formatToEuro } from 'core/utilities/currency-helpers';

interface IOrderFinanceDetails {
  grossPrice: number;
  discount: number;
  discountedPrice: number;
  totalPaid: number;
}

const OrderFinanceDetails = ({ grossPrice, discount, discountedPrice, totalPaid }: IOrderFinanceDetails) => {
  const fields = [
    { label: 'Goods/Services', value: formatToEuro(grossPrice) },
    { label: 'Discount/Grant', value: formatToEuro(discount) },
    { label: 'Total to pay', value: formatToEuro(discountedPrice) },
    { label: 'Amount paid', value: formatToEuro(totalPaid) },
    { label: 'Balance', value: formatToEuro(discountedPrice - totalPaid) },
  ];

  return (
    <div>
      {fields.map((field) => (
        <div key={field.label} className='grid grid-cols-2 gap-2 mt-1'>
          <p className='font-semibold'>{field.label}:</p>
          <p className='text-right'>{field.value}</p>
        </div>
      ))}
    </div>
  );
};

export default OrderFinanceDetails;
