import _ from 'lodash';
import { StringIndexable } from './interface-helpers';
import { IAuditLogChange } from 'core/api/audit-log/audit-log-api-interface';

export const getObjectChanges = (a?: StringIndexable, b?: StringIndexable, objKey?: string): IAuditLogChange[] => {
  if (!a) {
    return [];
  }

  return Object.entries(a).reduce((filtered: IAuditLogChange[], [k, v]) => {
    const type = typeof v;
    const base = {
      key: objKey ? `${objKey}.${k}` : k,
      updated: v,
    };
    if (!b) {
      filtered.push(base);
    } else if (type === 'object' && !Array.isArray(v)) {
      filtered.push(...getObjectChanges(v, b[k], k));
    } else {
      if (!_.isEqual(v, b[k])) {
        filtered.push({
          ...base,
          ...(b[k] && { original: b[k] }),
        });
      }
    }
    return filtered;
  }, []);
};
