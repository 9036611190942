export const YesNo = {
  YES: 'yes',
  NO: 'no',
};

export const YesNoOptions = [
  {
    label: 'Yes',
    value: YesNo.YES,
  },
  {
    label: 'No',
    value: YesNo.NO,
  },
];

export const getYesNoFromKey = (key: string) => {
  return YesNoOptions.find((p) => p.value === key);
};

export const getYesNoNameFromKey = (key: string) => {
  const p = YesNoOptions.find((p) => p.value === key);
  return p?.label ?? 'Unknown';
};
