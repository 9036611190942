export const Clinic = {
  DUBLIN: 'dublin',
  CORK: 'cork',
};

export const Clinics = [
  {
    label: 'Dublin',
    value: Clinic.DUBLIN,
    address: {
      lng: -6.18745,
      lat: 53.38065,
      fullAddress: 'Brookwood House, 136 Harmonstown Rd, Raheny, Dublin 5',
    },
  },
  {
    label: 'Cork',
    value: Clinic.CORK,
    address: {
      lng: -8.592477,
      lat: 51.887865,
      fullAddress: 'The Cottages, 5 Main St, West End, Cork, P31 TY22',
    },
  },
];

export const getClinicFromKey = (key: string) => {
  return Clinics.find((clinic) => clinic.value === key);
};

export const getClinicNameFromKey = (key: string) => {
  const clinic = Clinics.find((clinic) => clinic.value === key);
  return clinic?.label ?? 'Unknown';
};
