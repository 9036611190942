import { firestore, storage } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { collection, doc, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import ApiService from '../api.service';
import { IAddDocumentRequestDto, IDocumentsQueryOptions } from './documents-api-interface';

const documentsQueryList: StringIndexable = {
  customerUid: { property: 'customerUid', operator: '==' },
};

const subscribeToDocuments = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: IDocumentsQueryOptions,
  ordering?: string[]
) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.DOCUMENTS);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = documentsQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const getSignedFileUrl = async (expiration: number, filePath: string) => {
  const response = await ApiService.callFunction<string>(
    { expiration, filePath },
    FirebaseFunctionNames.GET_FILE_SIGNED_URL
  );
  return response;
};

const getFileBase64 = async (filePath: string) =>
  ApiService.callFunction<string>({ filePath }, FirebaseFunctionNames.GET_FILE_BASE_64);

const regenerateCustomerDocuments = async (customerUid: string) => {
  const response = await ApiService.callFunction<void>(
    { customerUid },
    FirebaseFunctionNames.REGENERATE_CUSTOMER_DOCUMENTS
  );
  return response;
};

const upload = async (dataUrl: string, contentType: string, path: string) => {
  const imageRef = ref(storage, path);
  return ApiService.uploadDataUrl(imageRef, dataUrl, contentType);
};

const set = async (dto: IAddDocumentRequestDto) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DOCUMENTS, dto.uid);
  return ApiService.set(docRef, dto);
};

const remove = async (uid: string) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DOCUMENTS, uid);
  return ApiService.remove(docRef);
};

const DocumentApiService = {
  subscribeToDocuments,
  getSignedFileUrl,
  regenerateCustomerDocuments,
  upload,
  set,
  remove,
  getFileBase64,
};

export default DocumentApiService;
