import { useEffect, useState } from 'react';
import Logo from 'assets/images/ilah.png';

const AuthPageWrapper = ({ children }: any) => {
  const [screenHeight, setScreenHeight] = useState(`${window.innerHeight}px`);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));

    return () => {
      window.removeEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));
    };
  }, []);
  return (
    <div className='bg-gray-100 flex flex-col items-center justify-center p-4' style={{ minHeight: screenHeight }}>
      <img src={Logo} alt='pride-pharmacy-logo' className='max-h-[72px] mb-8 lg:mb-10 mx-auto px-16' />
      <div className='bg-white rounded-md p-8 shadow-md w-full md:w-[500px]'>{children}</div>
      {/* <div className='mt-8 lg:mt-10 mx-auto flex flex-col items-center'>
        <img src={CBS} alt='pride-pharmacy-logo' className='max-h-[26px]' />
        <p className='mt-2.5 body-03 text-slate-300'>Powered by Creative Business Solutions Ltd</p>
      </div> */}
    </div>
  );
};

export default AuthPageWrapper;
