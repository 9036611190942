import { Permissions } from 'core/constants/permissions';
import RoleHandler from 'core/routing/role-handler';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuditLogList from './audit-log-list/audit-log-list';

const AuditLogRouting = () => {
  return (
    <Routes>
      <Route element={<RoleHandler requiredPermissions={[Permissions.AUDIT_LOG_GET]} includeLayout={false} />}>
        <Route path='' element={<AuditLogList />} />
      </Route>
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AuditLogRouting;
