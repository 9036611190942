import { HearingLossCausesFormFields } from 'core/config/form-fields';
import { ISharedFormField } from 'shared/components/form/form-interface';

interface IHearingLossCauses {
  getFormFields: (fields: ISharedFormField[], formKey: string) => JSX.Element[];
}

const HearingLossCauses = ({ getFormFields }: IHearingLossCauses) => {
  return (
    <div className='bg-white shadow-md rounded-md mt-4'>
      <div className='border-b md:border-r md:border-b-0 last:border-0'>
        <div className='border-b headline-06 p-4'>Hearing loss causes</div>
        <div className='px-4 py-4'>{getFormFields(HearingLossCausesFormFields, 'hearingLossCauses')}</div>
      </div>
    </div>
  );
};

export default HearingLossCauses;
