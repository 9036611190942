export const AidPowerOption = {
  RECHARGEABLE: 'rechargeable',
  BATTERY: 'battery',
};

export const AidPowerOptions = [
  {
    label: 'Rechargeable',
    value: AidPowerOption.RECHARGEABLE,
  },
  {
    label: 'Battery',
    value: AidPowerOption.BATTERY,
  },
];

export const getAidPowerOptionFromKey = (key: string) => {
  return AidPowerOptions.find((powerOption) => powerOption.value === key);
};

export const getAidPowerOptionNameFromKey = (key: string) => {
  const powerOption = AidPowerOptions.find((powerOption) => powerOption.value === key);
  return powerOption?.label ?? 'Unknown';
};
