import { useFlags } from '@atlaskit/flag';
import ProgressBar from '@atlaskit/progress-bar';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { IUser } from 'core/api/users/users-api-interface';
import { showErrorFlag } from 'core/utilities/flags-helper';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import SharedButton from 'shared/components/buttons/button';
import WeeklyCalendarRow from 'shared/components/calendar/weekly-calendar-row/weekly-calendar-row';
dayjs.extend(weekday);

interface IAppointmentListWeeklyView {
  date: string;
  resources: IUser[];
  changeDate: Function;
}
const AppointmentListWeeklyView = ({ date, resources, changeDate }: IAppointmentListWeeklyView) => {
  const [fetchingAppointments, setFetchingAppointments] = useState(true);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const daysOfTheWeek = useMemo(() => ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'], []);
  const dayjsOfTheWeek = useMemo(
    () =>
      daysOfTheWeek.map((day, index) => {
        return {
          label: day,
          date: dayjs(date).weekday(index + 1),
        };
      }),
    [date, daysOfTheWeek]
  );
  const [zoom, setZoom] = useState(1);
  const columnWidth = 330 * zoom;
  const resourceColumnWidth = 180;
  const rowHeight = 100 * zoom;

  const flags = useFlags();

  const getAppointments = useCallback(
    async (availableResources: IUser[], dates: string[]) => {
      setFetchingAppointments(true);
      const promises = availableResources.map((resource) =>
        AppointmentsApiService.listAll({
          assigneeUid: resource.uid,
          dates,
        })
      );
      try {
        const results = await Promise.all(promises);
        const appointments = results.flatMap((snapshot) => snapshot.docs.map((doc) => doc.data()));
        setAppointments(appointments);
        setFetchingAppointments(false);
      } catch (error) {
        setFetchingAppointments(false);
        showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
      }
    },
    [flags]
  );

  useEffect(() => {
    setAppointments([]);
    if (resources.length > 0) {
      getAppointments(
        resources,
        dayjsOfTheWeek.map((day) => day.date.format('YYYY-MM-DD'))
      );
    } else {
      setFetchingAppointments(false);
    }
  }, [dayjsOfTheWeek, getAppointments, resources]);

  const getWeekLabel = () => {
    const start = dayjsOfTheWeek[0].date;
    const end = dayjsOfTheWeek[dayjsOfTheWeek.length - 1].date;
    if (start.month() !== end.month()) {
      return `${start.format('D MMM')} - ${end.format('D MMM')}`;
    }
    return `${start.format('D')} - ${end.format('D MMM')}`;
  };

  const changeWeek = (direction: string) => {
    let nextDay: string;
    if (direction === 'forward') {
      nextDay = dayjs(date).add(7, 'day').format('YYYY-MM-DD');
    } else {
      nextDay = dayjs(date).subtract(7, 'day').format('YYYY-MM-DD');
    }
    changeDate(nextDay);
  };

  const changeZoom = (direction: string) => {
    let nextZoom: number;
    if (direction === 'forward') {
      nextZoom = zoom + 0.1;
    } else {
      nextZoom = zoom - 0.1;
    }

    if (nextZoom < 0.5 || nextZoom > 1) {
      return;
    }
    setZoom(nextZoom);
  };

  const currentTimeLinePosition = () => {
    const dayMargin = (dayjs(new Date()).weekday() - 1) * columnWidth + resourceColumnWidth - 1;
    let hourMargin = (dayjs().hour() - 9) * (columnWidth / 11) + dayjs().minute();
    if (hourMargin > columnWidth) {
      hourMargin = columnWidth;
    }
    return `${dayMargin + hourMargin}px`;
  };

  const isDate = (date: dayjs.Dayjs) => {
    return date.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD');
  };

  return (
    <>
      <div className='border-b sticky left-0 top-0 self-start z-40 bg-white p-2 flex items-center'>
        <button
          className='absolute left-3 p-3 rounded-full hover:bg-gray-100 opacity-80'
          onClick={() => changeWeek('backward')}
        >
          <ChevronLeft />
        </button>
        <div className='absolute left-20 w-[105px]'>
          <SharedButton
            onClick={() => changeDate(dayjs().format('YYYY-MM-DD'))}
            type='button'
            appearance='default'
            label='Today'
          />
        </div>
        <div className='text-center flex-grow p-3'>{getWeekLabel()}</div>
        <div className='absolute right-20 border rounded-md bg- flex space-x-4 items-center text-center'>
          <SharedButton onClick={() => changeZoom('backward')} type='button' appearance='default' label='-' />
          <p>{`${(zoom * 100).toFixed(0)}%`}</p>
          <SharedButton onClick={() => changeZoom('forward')} type='button' appearance='default' label='+' />
        </div>
        <button
          className='p-3 absolute right-3 rounded-full hover:bg-gray-100 opacity-80'
          onClick={() => changeWeek('forward')}
        >
          <ChevronRight />
        </button>
      </div>
      {fetchingAppointments && <ProgressBar ariaLabel='Loading appointments' isIndeterminate />}
      <div className='flex sticky top-[65px] z-40'>
        <div
          style={{ minWidth: `${resourceColumnWidth}px` }}
          className='h-[68px] sticky left-0 top-0 z-40 bg-slate-100 self-start border-b border-r border-r-gray-400'
        />
        {dayjsOfTheWeek.map((day) => (
          <div
            key={day.label}
            className={`border-r border-r-gray-400 ${isDate(day.date) ? 'bg-blue-50' : 'bg-white'}`}
            style={{ minWidth: `${columnWidth}px` }}
          >
            <div className='flex flex-col items-center p-3 border-b border-r-gray-400'>
              <p className='body-03 font-semibold'>{day.label}</p>
              <p className='body-01 font-extralight mt-px'>{day.date.format('DD')}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='relative'>
        {dayjs().format('YYYY-MM-DD') === date && (
          <div
            className='absolute h-full w-px bg-red-600 z-30'
            style={{
              left: currentTimeLinePosition(),
            }}
          />
        )}
        {resources.map((resource) => (
          <WeeklyCalendarRow
            key={resource.uid}
            resource={resource}
            appointments={appointments.filter((app) => app.assignee.uid === resource.uid)}
            days={dayjsOfTheWeek}
            showAppointmentMenu={true}
            resourceColumnWidth={resourceColumnWidth}
            columnWidth={columnWidth}
            rowHeight={rowHeight}
          />
        ))}
      </div>
    </>
  );
};

export default AppointmentListWeeklyView;
