import { useDialog } from 'core/providers/DialogProvider';
import React from 'react';
import SharedButton from 'shared/components/buttons/button';
import SharedCustomerListDialog, {
  ISharedCustomerListDialogRow,
} from 'shared/components/dialogs/customer-list-dialog/customer-list-dialog';

export interface IReportBreakdownCustomCell {
  label: string;
  count: number;
  breakdowns: {
    key: string;
    count: number;
    label: string;
    customerListRows: ISharedCustomerListDialogRow[];
  }[];
}

const ReportBreakdownCustomCell = (data: IReportBreakdownCustomCell) => {
  const { label, count, breakdowns } = data;
  const dialog = useDialog();
  return (
    <div className='grid grid-cols-2 gap-x-4 gap-y-1'>
      <p className='body-01 mb-4'>{label}</p>
      <p className='body-01 mb-4'>{count}</p>
      {breakdowns
        .filter((breakdown) => breakdown.count !== 0)
        .map((breakdown) => (
          <React.Fragment key={breakdown.key}>
            <p>{breakdown.label}</p>
            <div>
              <SharedButton
                onClick={() => dialog?.openDialog(<SharedCustomerListDialog rows={breakdown.customerListRows!} />)}
                type='button'
                spacing='none'
                appearance='link'
                label={breakdown.count.toString()}
              />
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

export default ReportBreakdownCustomCell;
