import { useState } from 'react';
import SharedButton from 'shared/components/buttons/button';
import imageCompression from 'browser-image-compression';

interface IFileCapture {
  fieldProps: any;
}

const options = {
  maxSizeMB: 0.1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const FileCapture = ({ fieldProps }: IFileCapture) => {
  const [selectedFile, setSelectedFile] = useState<string>();
  const reader = new FileReader();

  const imageChanged = async (event: any) => {
    const file: File = event.target.files[0];
    const { name, type } = file;
    let optimisedFile: File = file;
    setSelectedFile(name);
    if (type.includes('image')) {
      optimisedFile = await imageCompression(file, options);
    }
    if (optimisedFile) {
      reader.onloadend = () => {
        fieldProps.onChange({
          valid: true,
          dataUrl: reader.result,
          name,
          type,
        });
      };
      reader.readAsDataURL(optimisedFile);
    } else {
      setSelectedFile(undefined);
      fieldProps.onChange({
        valid: false,
      });
    }
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <p className='body-02 my-2'>{selectedFile ?? 'No file selected'}</p>
        <div className='relative'>
          <SharedButton
            onClick={undefined}
            type='button'
            appearance='default'
            label={selectedFile ? 'Change File' : 'Select File'}
          />
          <input
            className='w-full h-full absolute top-0 left-0 right-0 bottom-0 opacity-0'
            type='file'
            name='file'
            accept='image/*,.pdf,.doc'
            onChange={(e) => imageChanged(e)}
          />
        </div>
      </div>
    </>
  );
};

export default FileCapture;
