import ClassStringBuilder from 'core/utilities/class-string-builder';
import { Link, useLocation } from 'react-router-dom';
import { ISidebarItem } from './sidebar-item-interface';

const SharedSidebarItem = ({ icon, label, route, setSidebarOpen }: ISidebarItem) => {
  const location = useLocation();
  const active = location.pathname.startsWith(`/${route}`);
  const indicatorClasses = ['w-1', active ? 'bg-tertiary' : 'bg-white'];

  const backgroundClasses = ['border-b', active && 'bg-gray-100', 'hover:bg-gray-100'];
  return (
    <div className={ClassStringBuilder(backgroundClasses)}>
      <Link
        to={route}
        onClick={() => {
          setSidebarOpen(false);
        }}
      >
        <div className='flex w-full'>
          <div className={ClassStringBuilder(indicatorClasses)} />
          <div className='flex items-center py-5 px-4 lg:px-6'>
            {icon} <p className='ml-4 label-01'>{label}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SharedSidebarItem;
