import { EarHealthFormFields } from 'core/config/form-fields';
import SharedButton from 'shared/components/buttons/button';
import { ISharedFormField } from 'shared/components/form/form-interface';

interface IEarHealth {
  getFormFields: (fields: ISharedFormField[], formKey: string) => JSX.Element[];
  copyEarHealthDetail: (key: string) => void;
}

const EarHealth = ({ getFormFields, copyEarHealthDetail }: IEarHealth) => {
  const ears = [
    {
      key: 'leftEarHealth',
      label: 'Left ear health',
    },
    {
      key: 'rightEarHealth',
      label: 'Right ear health',
    },
  ];
  return (
    <div className='bg-white shadow-md rounded-md mt-4 grid grid-cols-1 md:grid-cols-2'>
      {ears.map((ear) => (
        <div key={ear.key} className='border-b md:border-r md:border-b-0 last:border-0'>
          <div className='border-b headline-06 p-4'>{ear.label}</div>
          <div className='px-4 py-4'>
            {getFormFields(EarHealthFormFields, ear.key)}
            <div className='mt-6'>
              <SharedButton
                onClick={() => copyEarHealthDetail(ear.key)}
                type='button'
                appearance='link'
                spacing='none'
                label={ear.key === 'leftEarHealth' ? 'Copy to right ear' : 'Copy to left ear'}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EarHealth;
