import { StringIndexable } from 'core/utilities/interface-helpers';
import { useState } from 'react';
import SharedButton from '../buttons/button';
import SharedCard from '../card/card';
import SharedTableBody from './table-body/table-body';
import SharedTableHead from './table-head/table-head';
import { ISharedTable, ISharedTableCustomCellTemplate } from './table-interface';

const SharedTable = <T extends StringIndexable>({
  columns,
  customTemplates = [],
  rows,
  isLoading = false,
  emptyText = 'No results',
  cardWrapped = true,
  pagination,
}: ISharedTable<T>) => {
  const [limit, setLimit] = useState(pagination ? pagination.limit : 100);
  const textCellTemplate = (data: string) => <p className='whitespace-pre-wrap'>{data}</p>;

  const templates: ISharedTableCustomCellTemplate[] = [
    {
      template: textCellTemplate,
      id: 'text',
    },
    ...customTemplates,
  ];

  const showMore = () => {
    if (pagination) {
      pagination.nextPageFunction();
      setLimit((prevState) => prevState + pagination.limit);
    } else {
      setLimit((prevState) => prevState + 100);
    }
  };

  const tableContents = (
    <>
      <table className='w-full'>
        <SharedTableHead columns={columns} />
        <SharedTableBody rows={rows.slice(0, limit)} templates={templates} columns={columns} isLoading={isLoading} />
      </table>
      {limit <= rows.length && (
        <div className='body-02 p-3 flex justify-center w-full border-t'>
          <SharedButton onClick={showMore} type='button' appearance='default' label='Show more' />
        </div>
      )}
      {rows.length === 0 && !isLoading && (
        <div className='border-t'>
          <p className='body-02 h-[56px] flex items-center justify-center'>{emptyText}</p>
        </div>
      )}
    </>
  );

  return cardWrapped ? <SharedCard>{tableContents}</SharedCard> : tableContents;
};

export default SharedTable;
