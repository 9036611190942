export const AidStyle = {
  BTE: 'bte',
  RIC: 'ric',
  CIC: 'cic',
  CIC_R_NW: 'cicRNW',
  CIC_NW: 'cicNW',
  CIC_312_W: 'cic312W',
  ITC: 'itc',
  IIC: 'iic',
  IIC_NW: 'iicNW',
  ITE_HALF_SHELL: 'iteHalfShell',
  ITE_FULL_SHELL: 'iteFullShell',
};

export const AidStyles = [
  {
    label: 'BTE',
    value: AidStyle.BTE,
  },
  {
    label: 'RIC',
    value: AidStyle.RIC,
  },
  {
    label: 'CIC',
    value: AidStyle.CIC,
  },
  {
    label: 'CIC R NW',
    value: AidStyle.CIC_R_NW,
  },
  {
    label: 'CIC NW',
    value: AidStyle.CIC_NW,
  },
  {
    label: 'CIC 312 W',
    value: AidStyle.CIC_312_W,
  },
  {
    label: 'ITC',
    value: AidStyle.ITC,
  },
  {
    label: 'IIC',
    value: AidStyle.IIC,
  },
  {
    label: 'IIC NW',
    value: AidStyle.IIC_NW,
  },
  {
    label: 'ITE 1/2 Shell',
    value: AidStyle.ITE_HALF_SHELL,
  },
  {
    label: 'ITE Full Shell',
    value: AidStyle.ITE_FULL_SHELL,
  },
];

export const getAidStyleFromKey = (key: string) => {
  return AidStyles.find((style) => style.value === key);
};

export const getAidStyleNameFromKey = (key: string) => {
  const style = AidStyles.find((style) => style.value === key);
  return style?.label ?? 'Unknown';
};
