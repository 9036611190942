import { useResponsiveState } from 'core/providers/ResponsiveProvider';
import { PageElement } from 'core/utilities/interface-helpers';
import { ArrowLeftCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';

interface IPageHeader {
  title: string;
  actions?: PageElement[];
  subtitle?: string;
  showBack?: boolean;
  backPath?: string;
  backLabel?: string;
}

const PageHeader = ({ title, actions, subtitle, showBack = false, backPath, backLabel = 'Go back' }: IPageHeader) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsiveState();

  return (
    <div className='flex-grow-1 flex-shrink-0 lg:basis-[88px] flex flex-col lg:flex-row lg:items-center lg:justify-between border-b'>
      <div className='py-4 lg:py-6'>
        {showBack && (
          <div
            className='flex items-center text-gray-600 mb-4 cursor-pointer'
            onClick={() => (backPath ? navigate(backPath) : navigate(-1))}
          >
            <ArrowLeftCircle size={20} />
            <p className='body-02 ml-2 font-semibold'>{backLabel}</p>
          </div>
        )}
        <p className={isMobile ? 'headline-05 mb-0' : 'headline-04 mb-2'}>{title}</p>
        {subtitle && <p className='body-01 text-gray-500'>{subtitle}</p>}
      </div>
      <div className='flex items-center lg:justify-end min-w-[190px]'>
        {actions?.map((action) => (
          <div key={action.key} className='mr-4 last:mr-0 mb-4 lg:mb-0'>
            {action.element}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageHeader;
