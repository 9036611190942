import { Navigate, Route, Routes } from 'react-router-dom';
import BookingDashboard from './booking-dashboard/booking-dashboard';

const BookingRouting = () => {
  return (
    <Routes>
      <Route path='' element={<BookingDashboard />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default BookingRouting;
