export const LeadType = {
  SURVEY: 'survey',
  MVF: 'mvf',
  PRIZE_DRAW: 'prizeDraw',
  PHONE_IN: 'phoneIn',
  SHOP_VISIT: 'shopVisit',
  CAMPAIGN: 'campaign',
  UNIQUE_BOOKING: 'uniqueBooking',
  REFERRAL: 'referral',
  PRISM: 'prism',
  NMG: 'nmg',
  FACEBOOK: 'facebook',
  ILAH_DIGITAL: 'ilahDigital',
};

export const LeadTypes = [
  {
    label: 'Survey',
    value: LeadType.SURVEY,
  },
  {
    label: 'MVF',
    value: LeadType.MVF,
  },
  {
    label: 'PrizeDraw',
    value: LeadType.PRIZE_DRAW,
  },
  {
    label: 'Phone In',
    value: LeadType.PHONE_IN,
  },
  {
    label: 'Shop Visit',
    value: LeadType.SHOP_VISIT,
  },
  {
    label: 'Campaign',
    value: LeadType.CAMPAIGN,
  },
  {
    label: 'Unique Booking',
    value: LeadType.UNIQUE_BOOKING,
  },
  {
    label: 'Referral',
    value: LeadType.REFERRAL,
  },
  {
    label: 'Prism',
    value: LeadType.PRISM,
  },
  {
    label: 'NMG',
    value: LeadType.NMG,
  },
  {
    label: 'CBS',
    value: LeadType.FACEBOOK,
  },
  {
    label: 'ILAH Digital',
    value: LeadType.ILAH_DIGITAL,
  },
];

export const getLeadTypeFromKey = (key: string) => {
  return LeadTypes.find((type) => type.value === key);
};

export const getLeadTypeNameFromKey = (key: string) => {
  const type = LeadTypes.find((type) => type.value === key);
  return type?.label ?? 'Unknown';
};
