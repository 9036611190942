import { OrderDOBFormFields, OrderPPSNFormFields } from 'core/config/form-fields';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { IOrderFormValues } from '../order-form-interface';
import SignatureCanvas from 'react-signature-canvas';
import { AidGrantOption } from 'core/constants/hearing-aid-grant-options';
import { Dispatch, SetStateAction } from 'react';

interface IOrderFormCustomerDeclaration {
  formValues: IOrderFormValues;
  getFormFields: (fields: ISharedFormField[], formKey: string) => JSX.Element[];
  setSignature: Dispatch<SetStateAction<SignatureCanvas | null>>;
  showFormError: (fieldKey: string, formKey?: string) => boolean;
  editing: boolean;
  previousSignature?: string;
}

const OrderFormCustomerDeclaration = ({
  formValues,
  getFormFields,
  setSignature,
  showFormError,
  editing,
  previousSignature,
}: IOrderFormCustomerDeclaration) => {
  const showSigError = showFormError('customerSignature');

  return (
    <div className='bg-white w-full rounded-md shadow-md h-fit mt-4'>
      <div className='p-4 border-b headline-06'>Customer declaration/authority</div>
      <div className='p-4'>
        {formValues.financial.grant && formValues.financial.grant.value !== AidGrantOption.NO_GRANT && (
          <div className='body-02 mb-6'>
            <p className='text-gray-500'>Welfare authority</p>
            <p>I authorise Independent Living and Hearing Ltd to check for my/our eligibility for the welfare grant</p>
            {getFormFields(OrderPPSNFormFields, 'customer')}
          </div>
        )}
        <div className='body-02'>
          <p className='text-gray-500'>GDPR Policy</p>
          <p>
            We as a company confirm your information will only be used by the Independent Living and Hearing Ltd
            organisation to share products and services with you and it will not be shared with any third party.
          </p>
          <p className='mt-4'>
            I have checked all of the details on this form and confirm that they are correct. I hereby request that I be
            supplied with the stated goods. I acknowledge receipt of the terms and conditions of the sale and agree to
            abide by them
          </p>
          {getFormFields(OrderDOBFormFields, 'customer')}
          <p className='label-02 font-semibold mb-1 text-gray-500 mt-4'>Customer signature:</p>
          {editing && previousSignature ? (
            <img src={previousSignature} alt='signature' className='max-h-[180px] py-6' />
          ) : (
            <SignatureCanvas
              penColor='black'
              canvasProps={{ className: `border w-full h-[200px] ${showSigError && 'border-red-500 border-2'}` }}
              ref={(ref) => {
                setSignature(ref);
              }}
            />
          )}
          {showSigError && <p className='mt-1 label-02 text-red-600'>A signature is required</p>}
        </div>
      </div>
    </div>
  );
};

export default OrderFormCustomerDeclaration;
