import TextField from '@atlaskit/textfield';
import { useCallback, useState } from 'react';
import _ from 'lodash';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag } from 'core/utilities/flags-helper';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { ICustomerSearch, ICustomerSearchSuggestion } from './customer-search-interface';

const CustomerSearch = ({ fieldProps, clearAfterSelect = false }: ICustomerSearch) => {
  const [suggestions, setSuggestions] = useState<ICustomerSearchSuggestion[]>();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>('');

  const flags = useFlags();
  const handleSelect = async (suggestion: ICustomerSearchSuggestion) => {
    setValue(createSuggestionString(suggestion));
    fieldProps.onChange({
      customerId: suggestion.objectID,
      valid: true,
    });
    setSuggestions(undefined);
    if (clearAfterSelect) {
      setValue('');
    }
  };

  const getSuggestions = async (term: string) => {
    try {
      setSuggestions([]);
      const result = await CustomersApiService.searchForCustomer(term);
      setSuggestions(result.hits as ICustomerSearchSuggestion[]);
      setLoading(false);
    } catch (error) {
      showErrorFlag(
        'An error occurred',
        'We could not retrieve the address suggestions for your query, please try again.',
        flags
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAutocomplete = useCallback(
    _.debounce((term: string) => getSuggestions(term), 500),
    []
  );

  const inputChanged = (value: string) => {
    setValue(value);
    fieldProps.onChange({
      valid: false,
    });

    if (value.trim() === '') {
      setSuggestions(undefined);
      debouncedAutocomplete.cancel();
      return;
    }
    setLoading(true);
    debouncedAutocomplete(value);
  };

  const createSuggestionString = (suggestion: ICustomerSearchSuggestion) => {
    return `${suggestion.fullName} - ${suggestion.address ? suggestion.address.formatted_address : 'Unknown'}`;
  };

  return (
    <div className='relative z-10'>
      <TextField {...fieldProps} value={value} onChange={(e) => inputChanged(e.currentTarget.value)} />
      {suggestions && (
        <div className='absolute w-full bg-white border-2 rounded-sm mt-2 max-h-[200px] overflow-y-auto z-20'>
          {loading ? (
            <div className='body-02 p-1.5'>Searching...</div>
          ) : (
            <>
              {suggestions.map((suggestion: ICustomerSearchSuggestion) => {
                return (
                  <div
                    key={suggestion.objectID}
                    className='cursor-pointer hover:bg-gray-50 body-02 p-1.5 border-t-2 first:border-t-0'
                    onClick={() => handleSelect(suggestion)}
                  >
                    {createSuggestionString(suggestion)}
                  </div>
                );
              })}
              {suggestions.length === 0 && <p className='body-02 p-1.5'>No results</p>}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerSearch;
