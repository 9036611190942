import { StringIndexable } from 'core/utilities/interface-helpers';

export interface PermissionSet {
  group: string;
  name: string;
  permissions: string[];
}

export const Permissions = {
  SURVEYING_CREATE: 'surveying:create',
  SURVEYING_GET: 'surveying:get',
  SURVEYING_DELETE: 'surveying:delete',
  SURVEYING_UPDATE: 'surveying:update',
  APPOINTMENTS_CREATE: 'appointments:create',
  APPOINTMENTS_GET: 'appointments:get',
  APPOINTMENTS_DELETE: 'appointments:delete',
  APPOINTMENTS_UPDATE: 'appointments:update',
  CUSTOMERS_CREATE: 'customers:create',
  CUSTOMERS_GET: 'customers:get',
  CUSTOMERS_DELETE: 'customers:delete',
  CUSTOMERS_UPDATE: 'customers:update',
  CUSTOMERS_LIST: 'customers:list',
  USERS_CREATE: 'users:create',
  USERS_GET: 'users:get',
  USERS_DELETE: 'users:delete',
  USERS_UPDATE: 'users:update',
  BOOKING_DASHBOARD: 'booking:dashboard',
  FITTING_DASHBOARD: 'fitting:dashboard',
  WELFARE_DASHBOARD: 'welfare:dashboard',
  FITTING_DASHBOARD_FULL: 'fitting:dashboard_full',
  REPORTING_GET: 'reporting:get',
  REPORTING_ALL: 'reporting:all',
  REPORTING_SELF: 'reporting:self',
  AFTERCARE_DASHBOARD: 'aftercare:dashboard',
  ORDER_CREATE: 'orders:create',
  ORDER_GET: 'orders:get',
  ORDER_DELETE: 'orders:delete',
  ORDER_UPDATE: 'orders:update',
  ORDER_LIST: 'orders:list',
  AUDIT_LOG_GET: 'audit-log:get',
};

export const PermissionTypes = {
  CREATE: 'create',
  GET: 'get',
  DELETE: 'delete',
  UPDATE: 'update',
};

export const PermissionSets = {
  AUDIT_LOG_MANAGE: {
    group: 'audit-log',
    name: 'audit-log:manage',
    permissions: Object.values(PermissionTypes).map((type) => `audit-log:${type}`),
  },
  SURVEYING_MANAGE: {
    group: 'surveying',
    name: 'surveying:manage',
    permissions: Object.values(PermissionTypes).map((type) => `surveying:${type}`),
  },
  SURVEYING_READ: {
    group: 'surveying',
    name: 'surveying:read',
    permissions: [Permissions.SURVEYING_GET],
  },
  APPOINTMENTS_MANAGE: {
    group: 'appointments',
    name: 'appointments:manage',
    permissions: Object.values(PermissionTypes).map((type) => `appointments:${type}`),
  },
  APPOINTMENTS_CREATE: {
    group: 'appointments',
    name: 'appointments:create',
    permissions: [Permissions.APPOINTMENTS_CREATE],
  },
  APPOINTMENTS_READ: {
    group: 'appointments',
    name: 'appointments:read',
    permissions: [Permissions.APPOINTMENTS_GET],
  },
  CUSTOMERS_MANAGE: {
    group: 'customers',
    name: 'customers:manage',
    permissions: Object.values(PermissionTypes).map((type) => `customers:${type}`),
  },
  CUSTOMERS_READ: {
    group: 'customers',
    name: 'customers:read',
    permissions: [Permissions.CUSTOMERS_GET],
  },
  CUSTOMERS_LIST: {
    group: 'customers',
    name: 'customers:list',
    permissions: [Permissions.CUSTOMERS_LIST],
  },
  USERS_MANAGE: {
    group: 'users',
    name: 'users:manage',
    permissions: Object.values(PermissionTypes).map((type) => `users:${type}`),
  },
  USERS_READ: {
    group: 'users',
    name: 'users:read',
    permissions: [Permissions.USERS_GET],
  },
  BOOKING_DASHBOARD: {
    group: 'booking',
    name: 'booking:dashboard',
    permissions: [Permissions.BOOKING_DASHBOARD],
  },
  FITTING_MANAGE: {
    group: 'fitting',
    name: 'fitting:manage',
    permissions: [Permissions.FITTING_DASHBOARD, Permissions.FITTING_DASHBOARD_FULL],
  },
  FITTING_READ: {
    group: 'fitting',
    name: 'fitting:read',
    permissions: [Permissions.FITTING_DASHBOARD],
  },
  WELFARE_READ: {
    group: 'welfare',
    name: 'welfare:read',
    permissions: [Permissions.WELFARE_DASHBOARD],
  },
  ALL_REPORTING: {
    group: 'reporting',
    name: 'reporting:all',
    permissions: [Permissions.REPORTING_GET, Permissions.REPORTING_ALL],
  },
  SELF_REPORTING: {
    group: 'reporting',
    name: 'reporting:self',
    permissions: [Permissions.REPORTING_GET, Permissions.REPORTING_SELF],
  },
  AFTERCARE_DASHBOARD: {
    group: 'aftercare',
    name: 'aftercare:dashboard',
    permissions: [Permissions.AFTERCARE_DASHBOARD],
  },
  ORDERS_MANAGE: {
    group: 'orders',
    name: 'orders:manage',
    permissions: Object.values(PermissionTypes).map((type) => `orders:${type}`),
  },
  ORDERS_READ: {
    group: 'orders',
    name: 'orders:read',
    permissions: [Permissions.ORDER_GET],
  },
  ORDERS_LIST: {
    group: 'orders',
    name: 'orders:list',
    permissions: [Permissions.ORDER_LIST],
  },
};

export const RolesPermissionSets: StringIndexable = {
  superAdmin: Object.values(PermissionSets),
  admin: Object.values(PermissionSets).filter(
    (permission) =>
      permission.name !== 'users:manage' &&
      permission.name !== 'reporting:all' &&
      permission.name !== 'audit-log:manage'
  ),
  surveyor: [PermissionSets.SURVEYING_MANAGE, PermissionSets.USERS_READ, PermissionSets.CUSTOMERS_READ],
  booker: [
    PermissionSets.BOOKING_DASHBOARD,
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.ORDERS_LIST,
    PermissionSets.USERS_READ,
    PermissionSets.FITTING_MANAGE,
  ],
  specialisedBooker: [
    PermissionSets.BOOKING_DASHBOARD,
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.ORDERS_LIST,
    PermissionSets.USERS_READ,
    PermissionSets.FITTING_MANAGE,
  ],
  uniqueBooker: [
    PermissionSets.BOOKING_DASHBOARD,
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.USERS_READ,
  ],
  hearingSpecialist: [
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.APPOINTMENTS_READ,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.USERS_READ,
    PermissionSets.FITTING_READ,
    PermissionSets.APPOINTMENTS_CREATE,
  ],
  audiologist: [
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.USERS_READ,
  ],
  aftercareAgent: [
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.ORDERS_LIST,
    PermissionSets.USERS_READ,
    PermissionSets.AFTERCARE_DASHBOARD,
    PermissionSets.FITTING_MANAGE,
  ],
  waxSpecialist: [
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.USERS_READ,
  ],
  hearingAidDispenser: [
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.ORDERS_LIST,
    PermissionSets.USERS_READ,
    PermissionSets.FITTING_MANAGE,
  ],
  teleHearTechnician: [
    PermissionSets.CUSTOMERS_MANAGE,
    PermissionSets.CUSTOMERS_LIST,
    PermissionSets.APPOINTMENTS_MANAGE,
    PermissionSets.ORDERS_MANAGE,
    PermissionSets.ORDERS_LIST,
    PermissionSets.USERS_READ,
    PermissionSets.AFTERCARE_DASHBOARD,
    PermissionSets.FITTING_MANAGE,
  ],
};
