import { GoogleMap, DirectionsRenderer, Marker } from '@react-google-maps/api';

interface IRouteMapPin {
  position: google.maps.LatLngLiteral;
  key: string;
  iconUrl: string;
  labelText: string;
}

interface IRouteMap {
  directions?: {
    result?: google.maps.DirectionsResult;
    pins: IRouteMapPin[];
  };
}

const RouteMap = ({ directions }: IRouteMap) => {
  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  return (
    <GoogleMap mapContainerStyle={containerStyle} zoom={10}>
      {directions?.result && (
        <DirectionsRenderer
          options={{
            directions: directions.result,
            suppressMarkers: true,
          }}
        />
      )}
      {directions?.pins.map((pin) => (
        <Marker
          key={pin.key}
          label={{
            text: pin.labelText,
            color: 'white',
          }}
          icon={{
            url: pin.iconUrl,
            labelOrigin: new google.maps.Point(18, 16),
          }}
          position={pin.position}
        />
      ))}
    </GoogleMap>
  );
};

export default RouteMap;
