import { useFlags } from '@atlaskit/flag';
import { IAuditLog, IAuditLogQueryOptions } from 'core/api/audit-log/audit-log-api-interface';
import AuditLogApiService from 'core/api/audit-log/audit-log-api.service';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useCallback, useEffect, useState } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import AuditLogTable from '../audit-log-table/audit-log-table';
import { QuerySnapshot } from 'firebase/firestore';
import SharedFilters, { ISharedFilterField } from 'shared/components/filters/filters';
import { FilterType } from 'core/enums/filter-type';
import { ParamKeyValuePair, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UsersSlice } from 'modules/users/users-slice';

const AuditLogList = () => {
  const [logsSnap, setLogsSnap] = useState<QuerySnapshot<IAuditLog>>();
  const flags = useFlags();
  const [loading, setLoading] = useState(true);
  const initialLimit = 50;
  const [limit, setLimit] = useState(initialLimit);
  const users = useSelector(UsersSlice.selectAll);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<IAuditLogQueryOptions>({});

  const getAuditLogs = useCallback(async () => {
    setLoading(true);
    try {
      const result = await AuditLogApiService.listAll(filterValues, ['createdAt'], limit);
      setLogsSnap(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      showErrorFlag('An error occurred', 'The audit logs could not be retrieved, please try again.', flags);
    }
  }, [filterValues, flags, limit]);

  useEffect(() => {
    getAuditLogs();
  }, [getAuditLogs]);

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value };
      });
    });
  }, [searchParams]);

  const showMoreOverride = () => {
    setLimit((prevState) => prevState + initialLimit);
  };

  const filters: ISharedFilterField[] = [
    {
      label: 'User',
      key: 'user',
      filterType: FilterType.Select,
      options: users.map((user) => ({
        value: user.uid,
        label: user.fullName,
      })),
    },
  ];

  const handleFilterChange = (key: string, value: string) => {
    if (!value) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, value]);
      setSearchParams(newValues);
    }
  };

  return (
    <>
      <PageHeader title='Audit log' />
      <SharedFilters
        filterInputs={filters}
        filterValues={filterValues}
        changeHandler={handleFilterChange}
        gridStyles='grid-cols-4 gap-6'
      />
      <div className='mt-4'>
        <AuditLogTable
          logsSnap={logsSnap}
          showCustomer
          dataLoading={loading}
          pagination={{
            limit: initialLimit,
            nextPageFunction: showMoreOverride,
          }}
        />
      </div>
    </>
  );
};

export default AuditLogList;
