import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { AppointmentStatus } from 'core/constants/appointment-status';
import { AppointmentType } from 'core/constants/appointment-type';
import dayjs from 'dayjs';

const hours = Array.from(Array(24).keys());
const workingHours = hours.filter((hour) => hour >= 9 && hour <= 19);

export const getAppointment15MinuteTimeSlots = () => {
  const timeSlots = workingHours.flatMap((hour, index) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    let hours = [`${h}:00`, `${h}:15`, `${h}:30`, `${h}:45`];
    if (index === workingHours.length - 1) {
      hours = [`${h}:00`, `${h}:15`, `${h}:30`];
    }
    return hours;
  });
  return timeSlots;
};

export const getAppointment60MinuteTimeSlots = () => {
  const timeSlots = workingHours.map((hour) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    return `${h}:00`;
  });
  return timeSlots;
};

export const doesAppointmentNeedConfirmation = (appointment: IAppointment) => {
  if (
    (appointment.type === AppointmentType.HOME_TEST || appointment.type === AppointmentType.SHOP_TEST) &&
    dayjs(appointment.date) >= dayjs().hour(0).minute(1) &&
    appointment.status !== AppointmentStatus.CANCELLED &&
    dayjs(appointment.date).subtract(5, 'days') > dayjs(appointment.createdAt.toDate()) &&
    !appointment.confirmedByOffice
  ) {
    return true;
  } else {
    return false;
  }
};

export const isShopAppointment = (appointmentType: string) =>
  [
    AppointmentType.SHOP_FITTING,
    AppointmentType.SHOP_SERVICE,
    AppointmentType.SHOP_TEST,
    AppointmentType.SHOP_WAX_REMOVAL,
  ].some((type) => type === appointmentType);
