import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { AppointmentType, AppointmentTypes, getAppointmentTypeNameFromKey } from 'core/constants/appointment-type';
import { getRandomColor } from 'core/utilities/color-helpers';
import { USStringToUKString } from 'core/utilities/date-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import ReportBreakdownCustomCell, {
  IReportBreakdownCustomCell,
} from '../report-breakdown-custom-cell/report-breakdown-custom-cell';

const ServiceCallAttendanceReport = () => {
  const getCustomerListMetaElement = (app: IAppointment) => {
    return (
      <div>
        <p className='font-semibold'>{getAppointmentTypeNameFromKey(app.type)}</p>
        <p className='body-02'>
          {USStringToUKString(app.date)} at {app.start}
        </p>
      </div>
    );
  };

  const processRows = useCallback(async (querySnapshot: QuerySnapshot<IAppointment>) => {
    const rows: IReportBaseTableRows<IReportBreakdownCustomCell>[] = [];
    querySnapshot.forEach((appointment) => {
      const { customer, assignee, type } = appointment.data();
      const rowIndex = rows.findIndex((row) => row.key === assignee.uid);
      const customerListRow = {
        metaElement: getCustomerListMetaElement(appointment.data()),
        customer,
      };
      if (rowIndex !== -1) {
        rows[rowIndex].data.count += 1;
        const typeIndex = rows[rowIndex].data.breakdowns.findIndex((appType) => appType.key === type);
        if (typeIndex !== -1) {
          rows[rowIndex].data.breakdowns[typeIndex].count += 1;
          rows[rowIndex].data.breakdowns[typeIndex].customerListRows?.push(customerListRow);
        }
      } else {
        rows.push({
          key: assignee.uid,
          color: getRandomColor(),
          data: {
            label: assignee.fullName,
            count: 1,
            breakdowns: AppointmentTypes.map((appType) => ({
              key: appType.value,
              count: type === appType.value ? 1 : 0,
              label: appType.label,
              customerListRows: customer.status === appType.value ? [customerListRow] : [],
            })),
          },
        });
      }
    });
    return rows;
  }, []);

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'orgainser',
    },
  ];

  const tableColumns = [{ label: 'Organiser', key: 'orgainser', templateId: 'orgainser', width: 100 }];

  return (
    <>
      <PageHeader title='Service call attendance report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeAppointmentDate: before,
          afterAppointmentDate: after,
          types: [
            AppointmentType.SHOP_SERVICE,
            AppointmentType.SERVICE,
            AppointmentType.TELE_HEAR,
            AppointmentType.WAX_REMOVAL,
            AppointmentType.SHOP_WAX_REMOVAL,
          ],
        })}
        subscriber={AppointmentsApiService.subscribeToAppointments}
        totalLabel={'Total number of service appointments:'}
        emptyText={'No appointments created during the selected time frame'}
        customTemplates={customTemplates}
        queryOrdering={['fullDate']}
      />
    </>
  );
};

export default ServiceCallAttendanceReport;
