import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { StoreState } from 'store';

const initialState = [] as IStoredCustomer[];

const slice = createSlice({
  name: 'customers',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    addMultiple(state, action: PayloadAction<IStoredCustomer[]>) {
      state.push(...action.payload);
    },
  },
});

const { resetState, addMultiple } = slice.actions;

const selectAll = (state: StoreState) => state.customers;

const selectByUid = (state: StoreState, uid: string) => state.customers.find((customer) => customer.uid === uid);

const selectByStatus = (state: StoreState, statuses: string[]) =>
  state.customers.filter((customer) => statuses.some((status) => customer.status === status));

export const CustomersSlice = {
  resetState,
  addMultiple,
  selectAll,
  selectByUid,
  selectByStatus,
};

export default slice.reducer;
