import { OrderProductCostFormFields } from 'core/config/form-fields';
import { formatToEuro } from 'core/utilities/currency-helpers';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { IOrderFormValues } from '../order-form-interface';

interface IOrderFormProductCost {
  formValues: IOrderFormValues;
  getFormFields: (fields: ISharedFormField[], formKey: string) => JSX.Element[];
}

const OrderFormProductCost = ({ formValues, getFormFields }: IOrderFormProductCost) => {
  const getTotalDue = () => {
    const { grossPrice, discount } = formValues.financial;
    return formatToEuro((grossPrice ?? 0) - (discount ?? 0));
  };

  return (
    <div className='bg-white w-full rounded-md shadow-md h-fit mt-4'>
      <div className='p-4 border-b headline-06'>Product cost</div>
      <div className='p-4'>
        {getFormFields(OrderProductCostFormFields, 'financial')}
        <div className='headline-07 mt-4'>Total price: {getTotalDue()}</div>
      </div>
    </div>
  );
};

export default OrderFormProductCost;
