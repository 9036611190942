import { useFlags } from '@atlaskit/flag';
import { ICustomerNote } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { AddCustomerNoteFormFields } from 'core/config/form-fields';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import SharedButton from 'shared/components/buttons/button';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedForm from 'shared/components/form/form';
import { IAddCustomerNoteFormOutput, ICustomerOverviewNotes } from './customer-overview-notes-interface';
import { v4 as uuidv4 } from 'uuid';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import SharedConfirmationDialog from 'shared/components/dialogs/confirmation-dialog/confirmation-dialog';
import { hasRole } from 'core/utilities/permission-helpers';
import { ControlType } from 'core/enums/control-type';
import Tooltip from '@atlaskit/tooltip';

const CustomerOverviewNotes = ({ customerId, customerName }: ICustomerOverviewNotes) => {
  const [showAddNoteInput, setShowAddNoteInput] = useState(false);
  const [notes, setNotes] = useState<ICustomerNote[]>([]);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const flags = useFlags();
  const dialog = useDialog();
  const [deletingNote, setDeletingNote] = useState(false);
  const [formFields, setFormFields] = useState(AddCustomerNoteFormFields);
  const { userData, userRoles } = useAuthState();
  const [editingUid, setEditingUid] = useState<string>();
  const isSuperAdmin = hasRole(['superAdmin'], userRoles);

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<ICustomerNote>) => {
      const noteList: ICustomerNote[] = [];
      querySnapshot.forEach((noteSnapShot) => {
        noteList.push(noteSnapShot.data());
      });
      setNotes(noteList);
      setLoading(false);
    };
    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Customer activity could not be retrieved, please try again.', flags);
    };
    let unsubscribe: Unsubscribe;
    unsubscribe = CustomersApiService.subscribeToCustomerNotes(customerId, handleSnapshot, handleSubscriptionError);
    return () => {
      unsubscribe();
    };
  }, [customerId, flags]);

  const cancelButton: IButton = {
    onClick: () => {
      setShowAddNoteInput(false);
      setFormFields(AddCustomerNoteFormFields);
    },
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const saveNote = async (data: IAddCustomerNoteFormOutput) => {
    setFormSubmitting(true);
    if (editingUid) {
      await CustomersApiService.updateCustomerNote({
        uid: editingUid,
        content: data.note,
        editDetail: {
          updatedAt: new Date(),
          editorName: userData?.fullName!,
          editorUid: userData?.uid!,
        },
        customerUid: customerId,
      });
    } else {
      await CustomersApiService.setCustomerNote({
        uid: uuidv4(),
        content: data.note,
        createdAt: new Date(),
        authorName: userData?.fullName!,
        authorUid: userData?.uid!,
        customerName: customerName,
        customerUid: customerId,
      });
    }
    setShowAddNoteInput(false);
    setFormSubmitting(false);
    setEditingUid(undefined);
    setFormFields(AddCustomerNoteFormFields);
    try {
    } catch (error) {
      showErrorFlag('Customer creation failed', `Unable to create this customer, please try again.`, flags);
      setFormSubmitting(false);
    }
  };

  const deleteNote = async (uid: string) => {
    setDeletingNote(true);
    try {
      await CustomersApiService.deleteCustomerNote(customerId, uid);
      showSuccessFlag('Deletion successful', 'The note was successfully deleted', flags);
      dialog?.closeDialog();
      setDeletingNote(false);
    } catch (error) {
      setDeletingNote(false);
      showErrorFlag('Deletion failed', 'Unable to delete this note, please try again', flags);
    }
  };

  const editNote = (note: ICustomerNote) => {
    setFormFields([
      {
        key: 'note',
        control: ControlType.TextArea,
        required: true,
        defaultValue: note.content,
      },
    ]);
    setShowAddNoteInput(true);
    setEditingUid(note.uid);
  };

  return (
    <div className='bg-white rounded-md shadow-md'>
      <div className='flex justify-between items-center p-4 border-b'>
        <p className='headline-06'>Customer notes</p>
        <SharedButton
          onClick={() => {
            setShowAddNoteInput(true);
          }}
          type='button'
          appearance='link'
          spacing='none'
          label='Add a note'
        />
      </div>
      {showAddNoteInput && (
        <div className='p-4 border-b'>
          <SharedForm
            onSubmit={saveNote}
            fields={formFields}
            buttonLabel='Save'
            loading={formSubmitting}
            cancelButton={cancelButton}
          />
        </div>
      )}
      {loading && (
        <div className='p-4'>
          <div className='h-[24px] w-[75%] bg-gray-100 rounded-md animate-pulse' />
          <div className='mt-1 h-[18px] w-[30%] bg-gray-100 rounded-md animate-pulse' />
        </div>
      )}
      {notes.map((note) => (
        <div key={note.uid} className='p-4 border-b last:border-0 body-03'>
          <p className='body-01 whitespace-pre-wrap'>{note.content}</p>
          <div className='flex space-x-1 items-center flex-wrap mt-1'>
            <p className='text-gray-500'>
              {`Created ${dayjs(note.createdAt.toDate()).format('DD/MM/YYYY [at] HH:mm')} by ${note.authorName}`}
            </p>
            {note.editDetail && (
              <Tooltip
                content={`${dayjs(note.editDetail.updatedAt.toDate()).format('DD/MM/YYYY [at] HH:mm')} by ${
                  note.editDetail.editorName
                }`}
              >
                {(tooltipProps) => (
                  <p className='text-gray-500' {...tooltipProps}>
                    • Edited
                  </p>
                )}
              </Tooltip>
            )}
          </div>
          <div className='mt-3 flex space-x-4'>
            {isSuperAdmin && (
              <SharedButton
                type='button'
                appearance='link'
                label='Delete'
                spacing='none'
                onClick={() =>
                  dialog?.openDialog(
                    <SharedConfirmationDialog
                      loading={deletingNote}
                      title='Delete note'
                      textContent='Are you sure you want to delete this note? This action is irreversible.'
                      confirmButton={{
                        appearance: 'danger',
                        label: 'Delete',
                        onClick: () => {
                          deleteNote(note.uid);
                        },
                      }}
                    />
                  )
                }
              />
            )}
            {(note.authorUid === userData?.uid || isSuperAdmin) && (
              <SharedButton
                type='button'
                appearance='link'
                label='Edit'
                spacing='none'
                onClick={() => editNote(note)}
              />
            )}
          </div>
        </div>
      ))}
      {!loading && notes.length === 0 && <p className='body-02 text-gray-500 p-4'>No notes for this customer</p>}
    </div>
  );
};

export default CustomerOverviewNotes;
