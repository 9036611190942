import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { useAuthState } from 'core/providers/AuthProvider';

interface IDeleteAppointmentDialog {
  uid: string;
  customerUid: string;
}

const DeleteAppointmentDialog = ({ uid, customerUid }: IDeleteAppointmentDialog) => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const { userData } = useAuthState();

  const deleteAppointment = async () => {
    setLoading(true);
    try {
      await AppointmentsApiService.remove(uid, customerUid, {
        fullName: userData!.fullName,
        uid: userData!.uid,
      });
      showSuccessFlag('Deletion successful', 'The appointment was successfully deleted', flags);
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      showErrorFlag('Deletion failed', 'Unable to delete this appointment, please try again', flags);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Cancel',
        type: 'button',
      },
    },
    {
      key: 'deleteUser',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => deleteAppointment(),
        appearance: 'danger',
        label: 'Delete appointment',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title='Delete this appointment'
      textContent='Are you sure you want to delete this appointment? This action cannot be undone.'
      customButtons={customButtons}
    />
  );
};

export default DeleteAppointmentDialog;
