export const AppointmentStatus = {
  SCHEDULED: 'scheduled',
  CANCELLED: 'cancelled',
};

export const AppointmentStatuses = [
  {
    label: 'Scheduled',
    value: AppointmentStatus.SCHEDULED,
  },
  {
    label: 'Cancelled',
    value: AppointmentStatus.CANCELLED,
  },
];

export const getAppointmentStatusNameFromKey = (key: string) => {
  const status = AppointmentStatuses.find((status) => status.value === key);
  return status?.label ?? 'Unknown';
};
