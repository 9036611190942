import { Field } from '@atlaskit/form';
import { ValueType } from '@atlaskit/select';
import { AddEditAppointmentFormFields } from 'core/config/form-fields';
import { SelectOption } from 'core/utilities/interface-helpers';
import { useCallback, useEffect, useState } from 'react';
import SharedForm from 'shared/components/form/form';
import PageHeader from 'shared/components/page-header/page-header';
import Select from '@atlaskit/select';
import { useSelector } from 'react-redux';
import { UsersSlice } from 'modules/users/users-slice';
import {
  getAppointment15MinuteTimeSlots,
  getAppointment60MinuteTimeSlots,
  isShopAppointment,
} from 'core/utilities/appointment-helpers';
import { AppointmentType, AppointmentTypes, getAppointmentTypeFromKey } from 'core/constants/appointment-type';
import { IUser } from 'core/api/users/users-api-interface';
import { IAddAppointmentRequestDto, IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useFlags } from '@atlaskit/flag';
import { useAuthState } from 'core/providers/AuthProvider';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CustomersApiService from 'core/api/customers/customers-api.service';
import SharedCalendar from 'shared/components/calendar/calendar';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import { DatePicker } from '@atlaskit/datetime-picker';
import { allFieldsValid } from 'core/utilities/form-helpers';
import { useDialog } from 'core/providers/DialogProvider';
import SelectCustomerDialog from '../select-customer-dialog/select-customer-dialog';
import { todayAsUSString } from 'core/utilities/date-helpers';
import { AppointmentStatus } from 'core/constants/appointment-status';
import Checkbox from '@atlaskit/checkbox';
import AppointmentConfirmationDialog from '../appointment-confirmation-dialog/appointment-confirmation-dialog';
import dayjs from 'dayjs';
import { getObjectChanges } from 'core/utilities/object-helpers';
import * as turf from '@turf/turf';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import SectionMessage from '@atlaskit/section-message';
import TimelinePreviewDialog from '../timeline-preview-dialog/timeline-preview-dialog';
import SharedButton from 'shared/components/buttons/button';
import { Roles } from 'core/config/roles';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { Clinics } from 'core/constants/clinic';

const today = todayAsUSString();
const allTimeOptions = getAppointment15MinuteTimeSlots().map((slot) => ({
  value: slot,
  label: slot,
}));

const AddEditAppointmentForm = () => {
  const [appointmentType, setAppointmentType] = useState<SelectOption | null>(null);
  const [assignee, setAssignee] = useState<SelectOption | null>(null);
  const [date, setDate] = useState<string>(today);
  const [start, setStart] = useState<SelectOption | null>(null);
  const [end, setEnd] = useState<SelectOption | null>(null);
  const [resources, setResources] = useState<IUser[]>([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [formError, setFormError] = useState<string>();
  const users = useSelector(UsersSlice.selectAll);
  const oneHourAppointmentTimeSlots = getAppointment60MinuteTimeSlots();
  const [searchParams] = useSearchParams();
  const startTimeOptions = allTimeOptions.filter(({ value }) => !(end && value >= end.value));
  const endTimeOptions = allTimeOptions.filter(({ value }) => !(start && value <= start.value));
  const [formFields, setFormFields] = useState(AddEditAppointmentFormFields);
  const [appointmentData, setAppointmentData] = useState<IAppointment>();
  const [sendSMSReminder, setSendSMSReminder] = useState<boolean>(false);
  const [recommendedResources, setRecommendedResources] = useState<IUser[]>([]);
  const [customer, setCustomer] = useState<ICustomer>();
  const [clinic, setClinic] = useState<SelectOption | null>(Clinics[0]);

  // Hooks
  const flags = useFlags();
  const { userData, userRoles } = useAuthState();
  const navigate = useNavigate();
  const customerId = searchParams.get('customer');
  const dialog = useDialog();
  const { appointmentId } = useParams();
  const editing = appointmentId !== undefined;

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createAppointmentsSubscription = useCallback(
    (appointmentId?: string) => {
      const handleSnapshot = (querySnapshot: QuerySnapshot<IAppointment>) => {
        const list: IAppointment[] = [];
        querySnapshot.forEach((snap) => {
          if (snap.id !== appointmentId) {
            list.push(snap.data());
          }
        });
        setAppointments(list);
      };
      return AppointmentsApiService.subscribeToAppointments(handleSnapshot, handleSubscriptionError, {
        date,
      });
    },
    [date, handleSubscriptionError]
  );

  // Used to reset form errors
  useEffect(() => {
    setFormError(undefined);
  }, [assignee, date, start, end, appointmentType]);

  // Function called for loading the form with previous data when editing
  useEffect(() => {
    const populateAppointmentForm = async (uid: string) => {
      const { data } = await AppointmentsApiService.get(uid);
      setAppointmentData(data);
      const type = getAppointmentTypeFromKey(data.type);
      if (type) {
        setAppointmentType(type);
      }
      const assignee = users.find((user) => user.uid === data.assignee.uid);
      if (assignee) {
        setAssignee({
          value: assignee.uid,
          label: assignee.fullName,
        });
      }
      setDate(data.date);
      const start = allTimeOptions.find((time) => time.value === data.start);
      if (start) setStart(start);
      const end = allTimeOptions.find((time) => time.value === data.end);
      if (end) setEnd(end);
      if (data.details) {
        setFormFields(AddEditAppointmentFormFields.map((field) => ({ ...field, defaultValue: data.details })));
      }
      setSendSMSReminder(data.sendSMSReminder ?? false);
    };

    if (appointmentId) {
      populateAppointmentForm(appointmentId);
    }
  }, [appointmentId, users]);

  const getCustomer = useCallback(
    async (uid: string) => {
      try {
        const { data } = await CustomersApiService.get(uid);
        setCustomer(data);
      } catch (error) {
        showErrorFlag(
          'Customer not found',
          'Details for the customer were not found, cannot create appointment',
          flags
        );
        navigate(-1);
      }
    },
    [flags, navigate]
  );

  // React based on route params provided
  useEffect(() => {
    if (appointmentId) {
      return;
    }

    if (!customerId || customerId === 'undefined') {
      dialog?.openDialog(<SelectCustomerDialog />);
      return;
    }

    getCustomer(customerId);
  }, [appointmentId, customerId, dialog, getCustomer]);

  // Used to find the best assignee based on the customer location
  const getRecommendedResources = useCallback(
    async (resources: IUser[]) => {
      const { address } = customer ?? appointmentData!.customer;
      if (!address) {
        setRecommendedResources([]);
      } else {
        const recommended = resources.filter((resource) => {
          if (!resource.areas) {
            return false;
          }
          const polys = resource.areas
            .map((areaString) =>
              areaString.split('##').map((point) => {
                const { lat, lng } = JSON.parse(point) as google.maps.LatLngLiteral;
                return [lng, lat];
              })
            )
            .map((positions) => {
              const first = positions[0];
              return turf.polygon([[...positions, first]]);
            });

          return polys.some((poly) => turf.booleanPointInPolygon([address.longitude, address.latitude], poly));
        });
        setRecommendedResources(recommended);
      }
    },
    [appointmentData, customer]
  );

  // Find possible resources based on the appointment type
  useEffect(() => {
    if (appointmentType) {
      let appointmentRoles: string[];
      switch (appointmentType?.value) {
        case AppointmentType.HOME_TEST:
        case AppointmentType.SHOP_TEST:
          appointmentRoles = ['hearingSpecialist'];
          break;
        case AppointmentType.WAX_REMOVAL:
        case AppointmentType.SHOP_WAX_REMOVAL:
          appointmentRoles = ['waxSpecialist', 'hearingSpecialist'];
          break;
        case AppointmentType.IMPRESSIONS:
          appointmentRoles = ['hearingSpecialist', 'audiologist', 'hearingAidDispenser'];
          break;
        case AppointmentType.ADMIN:
          appointmentRoles = ['hearingSpecialist', 'audiologist', 'hearingAidDispenser', 'waxSpecialist'];
          break;
        case AppointmentType.TELE_HEAR:
          appointmentRoles = ['teleHearTechnician'];
          break;
        default:
          appointmentRoles = ['audiologist', 'hearingAidDispenser', 'hearingSpecialist'];
      }
      let calendarResources: IUser[];
      if (Roles.filter((role) => role.key !== 'hearingSpecialist').some((role) => userRoles?.includes(role.key))) {
        calendarResources = users
          .filter((user) => user.roles.some((role) => appointmentRoles.includes(role)))
          .sort((a, b) => (a.priority ?? 10) - (b.priority ?? 10));
      } else {
        calendarResources = (userData ? [userData] : []).filter((user) =>
          appointmentRoles.some((role) => user.roles.includes(role))
        );
      }
      setResources(calendarResources);
      getRecommendedResources(calendarResources);
    }
  }, [appointmentType, getRecommendedResources, userData, userRoles, users]);

  // Populate calendar with existing appointments
  useEffect(() => {
    let unsubscribe: Unsubscribe;
    unsubscribe = createAppointmentsSubscription(appointmentId);
    return () => {
      unsubscribe();
    };
  }, [appointmentId, createAppointmentsSubscription]);

  const formValid = allFieldsValid([appointmentType, assignee, date, start, end, sendSMSReminder]);

  const saveAppointment = async (data: { details?: string }) => {
    setFormError(undefined);
    setFormSubmitting(true);
    if (!formValid) {
      setFormError('Please complete all the required fields');
      setFormSubmitting(false);
      return;
    }
    try {
      const { fullName, uid, roles } = users.find((user) => user.uid === assignee!.value)!;
      const timeSplit = start!.value.split(':');
      const hour = parseInt(timeSplit[0]);
      const min = parseInt(timeSplit[1]);
      const defaults = {
        date,
        fullDate: dayjs(date).set('hour', hour).set('minute', min).toDate(),
        details: data.details,
        start: start?.value!,
        end: end?.value!,
        type: appointmentType?.value!,
        assignee: {
          fullName,
          uid,
          roles,
        },
        status: AppointmentStatus.SCHEDULED,
        sendSMSReminder,
        ...(isShopAppointment(appointmentType?.value!) && { clinic: clinic?.value }),
      };
      const user = {
        uid: userData?.uid!,
        fullName: userData?.fullName!,
      };
      if (!editing || !appointmentData) {
        if (!customer) {
          throw new Error('No customer details found');
        }
        const previousAppointments = await AppointmentsApiService.listAll({
          status: AppointmentStatus.CANCELLED,
          types: [appointmentType?.value!],
          customerId: customer.uid,
        });
        const payload: IAddAppointmentRequestDto = {
          ...defaults,
          uid: uuid(),
          customer,
          createdAt: new Date(),
          organiser: user,
          priority: !previousAppointments.empty,
        };
        const { customer: c, status, uid, priority, ...rest } = { ...payload };
        await AppointmentsApiService.set(payload, getObjectChanges(rest, {}));
        dialog?.openDialog(
          <AppointmentConfirmationDialog
            customer={customer}
            start={payload.start}
            type={payload.type}
            date={payload.date}
            appointmentUId={payload.uid}
          />
        );
      } else {
        const { fullDate, ...rest } = { ...defaults };
        await AppointmentsApiService.update(
          appointmentData.uid,
          appointmentData.customer.uid,
          user,
          defaults,
          getObjectChanges(rest, appointmentData)
        );
        dialog?.openDialog(
          <AppointmentConfirmationDialog
            customer={appointmentData.customer}
            start={defaults.start}
            type={defaults.type}
            date={defaults.date}
            appointmentUId={appointmentData.uid}
          />
        );
      }
      setFormSubmitting(false);
      navigate(-1);
      showSuccessFlag('Appointment saved', `The appointment was successfully saved.`, flags);
    } catch (error) {
      console.log(error);
      showErrorFlag('Appointment saving failed', `Unable to save this appointment, please try again`, flags);
      setFormSubmitting(false);
    }
  };

  const appointmentTypeChanged = (value: SelectOption | null) => {
    setAssignee(null);
    setAppointmentType(value);
  };

  const checkRoute = () => {
    const assigneeDetail = users.find((user) => user.uid === assignee?.value);

    if (!assigneeDetail || !assigneeDetail.address) {
      showErrorFlag(
        'Incomplete assignee',
        'The assignee does not have a home address assigned, please contact your administrator',
        flags
      );
      return;
    }

    dialog?.openDialog(
      <TimelinePreviewDialog
        assigneeAddress={assigneeDetail.address}
        appointments={[
          ...appointments
            .filter(
              (app) =>
                app.assignee.uid === assignee?.value &&
                app.customer.address &&
                app.status !== AppointmentStatus.CANCELLED &&
                app.type !== AppointmentType.ADMIN
            )
            .map((app) => ({
              uid: app.uid,
              customerName: app.customer.fullName,
              address: app.customer.address!,
              type: app.type,
              start: app.start,
              end: app.end,
              clinic: app.clinic,
            })),
          {
            uid: 'new',
            customerName: customer!.fullName,
            address: customer!.address!,
            type: 'new',
            start: start!.value,
            end: end!.value,
          },
        ]}
      />
    );
  };

  const performFutureCheck = (data: { details?: string }) => {
    if (dayjs().add(5, 'days') < dayjs(date)) {
      dialog?.openDialog(
        <SharedDialogBase
          title='Have you checked all appointment slots?'
          textContent='The appointment date you have selected is greater than 5 days in the future, please ensure that you have checked all appointment slots.'
          customButtons={[
            {
              key: 'close',
              type: ButtonType.Button,
              buttonProps: {
                onClick: () => dialog?.closeDialog(),
                appearance: 'link',
                label: 'Cancel',
                type: 'button',
              },
            },
            {
              key: 'confirm',
              type: ButtonType.Button,
              buttonProps: {
                onClick: () => {
                  dialog?.closeDialog();
                  saveAppointment(data);
                },
                appearance: 'primary',
                label: 'Continue',
                type: 'button',
              },
            },
          ]}
        />
      );
    } else {
      saveAppointment(data);
    }
  };

  return (
    <>
      <PageHeader title={editing ? 'Edit appointment' : 'Create new appointment'} showBack backLabel='Cancel' />
      <div className='flex flex-col lg:flex-row py-6'>
        <div className='flex-grow-0 flex-shrink-0 w-full lg:basis-[360px] bg-white shadow-md rounded-md mr-6'>
          <div className='border-b p-4'>
            <p className='headline-06'>Appointment details</p>
          </div>
          <div className='p-4'>
            <Field<ValueType<SelectOption>>
              aria-required={true}
              name='type'
              defaultValue={null}
              label='Appointment type'
              isRequired={true}
              isDisabled={formSubmitting}
            >
              {({ fieldProps }) => (
                <Select<SelectOption>
                  {...fieldProps}
                  options={AppointmentTypes}
                  value={appointmentType}
                  onChange={appointmentTypeChanged}
                  isSearchable={false}
                />
              )}
            </Field>
            {appointmentType && (
              <>
                {isShopAppointment(appointmentType.value) && (
                  <Field<ValueType<SelectOption>>
                    aria-required={true}
                    name='type'
                    defaultValue={null}
                    label='Clinic'
                    isRequired={true}
                    isDisabled={formSubmitting}
                  >
                    {({ fieldProps }) => (
                      <Select<SelectOption>
                        {...fieldProps}
                        options={Clinics}
                        value={clinic}
                        onChange={setClinic}
                        isSearchable={false}
                      />
                    )}
                  </Field>
                )}
                <Field<ValueType<SelectOption>>
                  aria-required={true}
                  name='assignee'
                  defaultValue={null}
                  label='Assignee'
                  isRequired={true}
                  isDisabled={formSubmitting}
                >
                  {({ fieldProps }) => (
                    <Select<SelectOption>
                      {...fieldProps}
                      options={resources.map((resource) => ({
                        value: resource.uid,
                        label: resource.fullName,
                      }))}
                      value={assignee}
                      onChange={setAssignee}
                      isSearchable={false}
                    />
                  )}
                </Field>
                {recommendedResources.length > 0 && (
                  <div className='mt-2 mb-4'>
                    <SectionMessage>
                      <p className='body-03 font-bold -mt-px mb-2'>Recommended assignee</p>
                      {recommendedResources.map((res) => (
                        <p key={res.uid} className='body-02'>
                          • {res.fullName}
                        </p>
                      ))}
                    </SectionMessage>
                  </div>
                )}
                <Field name='date' label='Date' defaultValue={date} isRequired={true}>
                  {({ fieldProps: { id, ...rest } }) => (
                    <>
                      <DatePicker
                        selectProps={{ inputId: id, isSearchable: false }}
                        {...rest}
                        locale={'en-UK'}
                        value={date}
                        onChange={setDate}
                        minDate={today}
                      />
                    </>
                  )}
                </Field>
                <Field<ValueType<SelectOption>>
                  aria-required={true}
                  name='start'
                  defaultValue={null}
                  label='Start time'
                  isRequired={true}
                  isDisabled={formSubmitting}
                >
                  {({ fieldProps }) => (
                    <Select<SelectOption>
                      {...fieldProps}
                      options={startTimeOptions}
                      value={start}
                      onChange={setStart}
                      isSearchable={false}
                    />
                  )}
                </Field>
                <Field<ValueType<SelectOption>>
                  aria-required={true}
                  name='end'
                  defaultValue={null}
                  label='End time'
                  isRequired={true}
                  isDisabled={formSubmitting}
                >
                  {({ fieldProps }) => (
                    <Select<SelectOption>
                      {...fieldProps}
                      options={endTimeOptions}
                      value={end}
                      onChange={setEnd}
                      isSearchable={false}
                    />
                  )}
                </Field>
                <div className='mt-4'>
                  <Checkbox
                    isChecked={sendSMSReminder}
                    onChange={(e) => setSendSMSReminder(e.target.checked)}
                    label='Send SMS reminder on the day before this appointment'
                  />
                </div>

                <SharedForm
                  onSubmit={performFutureCheck}
                  fields={formFields}
                  buttonLabel='Save'
                  loading={formSubmitting}
                  formErrorMessage={formError}
                />
              </>
            )}
          </div>
          {formValid && (
            <div className='p-4 border-t'>
              <SharedButton
                onClick={checkRoute}
                type='button'
                appearance='link'
                label='Preview route'
                spacing='none'
                fitContainer
              />
            </div>
          )}
        </div>
        <div className='flex-grow relative mt-4 lg:mt-0'>
          <div className='absolute w-full'>
            <div className='bg-white shadow-md rounded-md overflow-x-hidden mb-6'>
              {!appointmentType || !date ? (
                <div className='p-6'>Select an appointment type and date to view the calendar</div>
              ) : (
                <SharedCalendar
                  date={date}
                  timeSlots={oneHourAppointmentTimeSlots}
                  resources={resources}
                  appointments={appointments}
                  changeDate={setDate}
                  highlightedResource={assignee?.value}
                  newAppointment={start && end ? { start: start.value, end: end.value } : undefined}
                  minDate={today}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditAppointmentForm;
