import { FlagsProvider } from '@atlaskit/flag';
import { AuthProvider } from 'core/providers/AuthProvider';
import { DialogProvider } from 'core/providers/DialogProvider';
import { ResponsiveProvider } from 'core/providers/ResponsiveProvider';
import React from 'react';
import AppRouting from './core/routing/app-routing';

function App() {
  return (
    <AuthProvider>
      <FlagsProvider>
        <DialogProvider>
          <ResponsiveProvider>
            <AppRouting />
          </ResponsiveProvider>
        </DialogProvider>
      </FlagsProvider>
    </AuthProvider>
  );
}

export default App;
