export const CustomerTab = {
  DETAILS: 'details',
  SURVEY: 'survey',
  APPOINTMENTS: 'appointments',
  ORDERS: 'orders',
  DOCUMENTS: 'documents',
  NOTES: 'notes',
  AUDIOGRAM: 'audiogram',
  AUDIT_LOG: 'audit-log',
};

export const customerTabs: ICustomerTab[] = [
  {
    label: 'Survey',
    key: CustomerTab.SURVEY,
  },
  {
    label: 'Audiogram',
    key: CustomerTab.AUDIOGRAM,
  },
  {
    label: 'Appointments',
    key: CustomerTab.APPOINTMENTS,
  },
  {
    label: 'Orders',
    key: CustomerTab.ORDERS,
  },
  {
    label: 'Documents',
    key: CustomerTab.DOCUMENTS,
  },
  {
    label: 'Notes',
    key: CustomerTab.NOTES,
  },
];

export interface ICustomerTab {
  label: string;
  key: string;
}
