import { QueryConstraint, QuerySnapshot, collection, doc, limit, orderBy, query, where } from 'firebase/firestore';
import ApiService from '../api.service';
import { IAddAuditLogRequestDto, IAuditLog, IAuditLogQueryOptions } from './audit-log-api-interface';
import { firestore } from 'core/config/firebase';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { v4 as uuid } from 'uuid';
import { StringIndexable } from 'core/utilities/interface-helpers';

const set = async (dto: IAddAuditLogRequestDto) => {
  if (dto.changes && dto.changes.length === 0) {
    return;
  }
  const completeDoc = {
    uid: uuid(),
    ...dto,
    createdAt: new Date(),
  };
  const documentRef = doc(firestore, FirestoreCollectionIDs.AUDIT_LOG, completeDoc.uid);
  return ApiService.set(documentRef, completeDoc);
};

const auditLogsQueryList: StringIndexable = {
  customer: { property: 'customerUid', operator: '==' },
  user: { property: 'author.uid', operator: '==' },
};

const listAll = (
  queryOptions?: IAuditLogQueryOptions,
  ordering?: string[],
  limitResults?: number
): Promise<QuerySnapshot<IAuditLog>> => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.AUDIT_LOG);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = auditLogsQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }

  if (limitResults) {
    queryList.push(limit(limitResults));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.listAll<IAuditLog>(q);
};

const AuditLogApiService = {
  set,
  listAll,
};

export default AuditLogApiService;
