import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import 'chart.js/auto';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { getRandomColor } from 'core/utilities/color-helpers';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';
import ReportBreakdownCustomCell, {
  IReportBreakdownCustomCell,
} from '../report-breakdown-custom-cell/report-breakdown-custom-cell';
import { CustomerStatuses } from 'core/constants/customer-status';

const CustomerLeadTypeStatus = () => {
  const processRows = useCallback(async (querySnapshot: QuerySnapshot<ICustomer>) => {
    const rows: IReportBaseTableRows<IReportBreakdownCustomCell>[] = [];
    querySnapshot.forEach((doc) => {
      const customer = doc.data();
      const { leadType } = customer;
      const customerListRow = {
        customer: doc.data(),
      };
      const rowIndex = rows.findIndex((row) => row.key === leadType);
      if (rowIndex !== -1) {
        rows[rowIndex].data.count += 1;
        const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === customer.status);
        if (typeIndex !== -1) {
          rows[rowIndex].data.breakdowns[typeIndex].count += 1;
          rows[rowIndex].data.breakdowns[typeIndex].customerListRows?.push(customerListRow);
        }
      } else {
        rows.push({
          key: leadType,
          color: getRandomColor(),
          data: {
            label: getLeadTypeNameFromKey(leadType),
            count: 1,
            breakdowns: CustomerStatuses.map((status) => ({
              key: status.key,
              count: customer.status === status.key ? 1 : 0,
              label: status.label,
              customerListRows: customer.status === status.key ? [customerListRow] : [],
            })),
          },
        });
      }
    });
    return rows;
  }, []);

  const tableColumns = [{ label: 'Lead type', key: 'breakdown', templateId: 'breakdown', width: 50 }];

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'breakdown',
    },
  ];

  return (
    <>
      <PageHeader title='Customer lead type status report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
        })}
        subscriber={CustomersApiService.subscribeToCustomers}
        totalLabel={'Total number of customers created'}
        emptyText='No customers created during the selected time frame'
        customTemplates={customTemplates}
      />
    </>
  );
};

export default CustomerLeadTypeStatus;
