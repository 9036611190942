import { configureStore } from '@reduxjs/toolkit';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { IUser } from 'core/api/users/users-api-interface';
import userReducer from 'modules/users/users-slice';
import customerReducer from 'modules/customers/customers-slice';

export interface StoreState {
  users: IUser[];
  customers: IStoredCustomer[];
}

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    users: userReducer,
    customers: customerReducer,
  },
});
