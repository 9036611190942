import { useFlags } from '@atlaskit/flag';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { IUser } from 'core/api/users/users-api-interface';
import { getAppointment60MinuteTimeSlots } from 'core/utilities/appointment-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useCallback, useEffect, useState } from 'react';
import SharedCalendar from 'shared/components/calendar/calendar';

interface IAppointmentListDayView {
  date: string;
  resources: IUser[];
  paramChanged: Function;
}

const AppointmentListDayView = ({ date, resources, paramChanged }: IAppointmentListDayView) => {
  const [fetchingAppointments, setFetchingAppointments] = useState(true);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const flags = useFlags();

  const getAppointments = useCallback(
    async (availableResources: IUser[], date: string) => {
      setFetchingAppointments(true);
      try {
        const snap = await AppointmentsApiService.listAll({
          assigneeUids: availableResources.map((r) => r.uid),
          date,
        });
        const appointments = snap.docs.map((doc) => doc.data());
        setAppointments(appointments);
        setFetchingAppointments(false);
      } catch (error) {
        setFetchingAppointments(false);
        showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
      }
    },
    [flags]
  );

  useEffect(() => {
    setAppointments([]);
    if (resources.length > 0) {
      getAppointments(resources, date);
    } else {
      setFetchingAppointments(false);
    }
  }, [date, getAppointments, resources]);

  return (
    <SharedCalendar
      date={date}
      timeSlots={getAppointment60MinuteTimeSlots()}
      resources={resources}
      appointments={appointments}
      changeDate={(newValue: string) => paramChanged('date', newValue)}
      showDatePicker
      showAppointmentMenu
      isLoading={fetchingAppointments}
    />
  );
};

export default AppointmentListDayView;
