import { ICustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { CustomerStatus } from 'core/constants/customer-status';
import { getLeadTypeNameFromKey, LeadTypes } from 'core/constants/lead-type';
import { getRandomColor } from 'core/utilities/color-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import ReportBreakdownCustomCell, {
  IReportBreakdownCustomCell,
} from '../report-breakdown-custom-cell/report-breakdown-custom-cell';

const NotInterestedReport = () => {
  const processRows = useCallback(async (querySnapshot: QuerySnapshot<ICustomer>) => {
    const rows: IReportBaseTableRows<IReportBreakdownCustomCell>[] = [];
    querySnapshot.forEach((doc) => {
      const { statusUpdatedBy, leadType } = doc.data();
      const booker = statusUpdatedBy ?? {
        uid: 'unknown',
        fullName: 'Unknown',
      };
      const rowIndex = rows.findIndex((row) => row.key === booker.uid);
      const customerListRow = {
        customer: doc.data(),
      };
      if (rowIndex !== -1) {
        rows[rowIndex].data.count += 1;
        const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === leadType);
        if (typeIndex !== -1) {
          rows[rowIndex].data.breakdowns[typeIndex].count += 1;
          rows[rowIndex].data.breakdowns[typeIndex].customerListRows?.push(customerListRow);
        }
      } else {
        rows.push({
          key: booker.uid,
          color: getRandomColor(),
          data: {
            label: booker.fullName,
            count: 1,
            breakdowns: LeadTypes.map((type) => ({
              key: type.value,
              label: `${getLeadTypeNameFromKey(type.value)} bookings`,
              count: leadType === type.value ? 1 : 0,
              customerListRows: leadType === type.value ? [customerListRow] : [],
            })),
          },
        });
      }
    });
    return rows;
  }, []);

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'booker',
    },
  ];

  const tableColumns = [{ label: 'Booker', key: 'booker', templateId: 'booker', width: 100 }];

  return (
    <>
      <PageHeader title='Customers not interested report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        getQueryOptions={(before, after) => ({
          statusUpdatedBefore: before,
          statusUpdatedAfter: after,
          status: CustomerStatus.NOT_INTERESTED,
        })}
        processRows={processRows}
        subscriber={CustomersApiService.subscribeToCustomers}
        totalLabel={"Total number of customers that were moved into 'Not Interested' during the specified dates:"}
        customTemplates={customTemplates}
        emptyText={"No customers that were created during the selected time frame have been marked as 'Not Interested'"}
      />
    </>
  );
};

export default NotInterestedReport;
