import { ICustomer, IStoredCustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { ISurvey } from 'core/api/surveys/surveys-api-interface';
import SurveysApiService from 'core/api/surveys/surveys-api.service';
import { CustomerStatuses } from 'core/constants/customer-status';
import { getRandomColor } from 'core/utilities/color-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { CustomersSlice } from 'modules/customers/customers-slice';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import ReportBreakdownCustomCell, {
  IReportBreakdownCustomCell,
} from '../report-breakdown-custom-cell/report-breakdown-custom-cell';

interface ISurveyCustomerData {
  customer: ICustomer | IStoredCustomer;
  surveyor: {
    uid: string;
    fullName: string;
  };
}

const SurveyOutcomeReport = () => {
  const storedCustomers = useSelector(CustomersSlice.selectAll);

  const processRows = useCallback(
    async (querySnapshot: QuerySnapshot<ISurvey>) => {
      const rows: IReportBaseTableRows<IReportBreakdownCustomCell>[] = [];

      const promises = querySnapshot.docs.map((doc) => {
        const survey = doc.data();
        return new Promise<ISurveyCustomerData | undefined>(async (resolve, reject) => {
          let customer: IStoredCustomer | ICustomer | undefined = storedCustomers.find(
            (customer) => customer.uid === survey.customerUid
          );
          if (!customer) {
            try {
              const response = await CustomersApiService.get(survey.customerUid);
              customer = response.data;
              resolve({
                customer,
                surveyor: survey.surveyor,
              });
            } catch (error) {
              resolve(undefined);
            }
          } else {
            resolve({
              customer,
              surveyor: survey.surveyor,
            });
          }
        });
      });
      const surveyCustomerData = await Promise.all(promises);

      surveyCustomerData.forEach((scData) => {
        if (!scData) {
          return;
        }
        const { customer, surveyor } = scData;
        const rowIndex = rows.findIndex((row) => row.key === surveyor.uid);
        const customerListRow = {
          customer,
        };
        if (rowIndex !== -1) {
          rows[rowIndex].data.count += 1;
          const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === customer.status);
          if (typeIndex !== -1) {
            rows[rowIndex].data.breakdowns[typeIndex].count += 1;
            rows[rowIndex].data.breakdowns[typeIndex].customerListRows?.push(customerListRow);
          }
        } else {
          rows.push({
            key: surveyor.uid,
            color: getRandomColor(),
            data: {
              label: surveyor.fullName,
              count: 1,
              breakdowns: CustomerStatuses.map((status) => ({
                key: status.key,
                count: customer.status === status.key ? 1 : 0,
                label: status.label,
                customerListRows: customer.status === status.key ? [customerListRow] : [],
              })),
            },
          });
        }
      });
      return rows;
    },
    [storedCustomers]
  );

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'surveyor',
    },
  ];

  const tableColumns = [{ label: 'Surveyor', key: 'surveyor', templateId: 'surveyor', width: 100 }];

  return (
    <>
      <PageHeader title='Survey outcome report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
        })}
        subscriber={SurveysApiService.subscribeToSurveys}
        totalLabel={'Total number of surveys created:'}
        emptyText={'No surveys were created during the selected time frame'}
        customTemplates={customTemplates}
      />
    </>
  );
};

export default SurveyOutcomeReport;
