import { useFlags } from '@atlaskit/flag';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { AddCustomerFormFields, SelectCustomerDialogFormFields } from 'core/config/form-fields';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';

const SelectCustomerDialog = () => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const navigate = useNavigate();
  const flags = useFlags();

  const cancelButton: IButton = {
    onClick: () => {
      navigate(-1);
      dialog?.closeDialog();
    },
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const selectCustomer = async (data: any) => {
    try {
      setLoading(true);
      const response = await CustomersApiService.get(data.customer.customerId);
      const customer = response.data;
      if (AddCustomerFormFields.filter((field) => field.required).some((field) => !customer[field.key])) {
        return showErrorFlag(
          'Customer incomplete',
          'You must edit this customer and provide all of the required detail before creating an appointment',
          flags
        );
      }
      navigate(`/appointments/new?customer=${data.customer.customerId}`, { replace: true });
      dialog?.closeDialog();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return showErrorFlag(
        'Customer detail not found',
        'This customers details could not be found, please try again.',
        flags
      );
    }
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4'
        onSubmit={selectCustomer}
        fields={SelectCustomerDialogFormFields}
        buttonLabel='Select customer'
        cancelButton={cancelButton}
        loading={loading}
      />
    );
  };

  return <SharedDialogBase title='Customer search' customContentTemplate={customContent()} showButtons={false} />;
};

export default SelectCustomerDialog;
