import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { PageElement } from 'core/utilities/interface-helpers';
import SharedButton from 'shared/components/buttons/button';
import PageHeader from 'shared/components/page-header/page-header';
import AwaitingBooking from './awaiting-booking/awaiting-booking';
import BookedTodayDialog from './booked-today-dialog/booked-today-dialog';

const BookingDashboard = () => {
  const { userRoles } = useAuthState();
  const dialog = useDialog();

  const headerActions: PageElement[] = [
    {
      key: 'addUser',
      element: (
        <SharedButton
          onClick={() => dialog?.openDialog(<BookedTodayDialog />)}
          type='button'
          appearance='primary'
          label='View booked today'
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Booking Dashboard' actions={headerActions} />
      <AwaitingBooking userRoles={userRoles!} />
    </>
  );
};

export default BookingDashboard;
