import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import DocumentApiService from 'core/api/documents/documents-api.service';

interface IUpdateCustomerDocumentsDialog {
  customer: ICustomer;
}

const UpdateCustomerDocumentsDialog = ({ customer }: IUpdateCustomerDocumentsDialog) => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const flags = useFlags();

  const updateDocuments = async () => {
    setLoading(true);
    try {
      await DocumentApiService.regenerateCustomerDocuments(customer.uid);
      showSuccessFlag('Documents updated', `${customer.fullName}'s documents was successfully updated.`, flags);
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      showErrorFlag(
        'Documents update failed',
        `Unable to update the documents for ${customer.fullName}'s account, please try again.`,
        flags
      );
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'primary',
        label: 'No',
        type: 'button',
      },
    },
    {
      key: 'updateCustomerDocuments',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => updateDocuments(),
        appearance: 'primary',
        label: 'Yes',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={`Update documents for ${customer.fullName}?`}
      textContent='Would you like to update this customers documents using the new details provided?'
      customButtons={customButtons}
    />
  );
};

export default UpdateCustomerDocumentsDialog;
