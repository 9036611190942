import { useFlags } from '@atlaskit/flag';
import { useEffect, useState } from 'react';
import { ICustomerOverviewDocuments } from './customer-overview-documents-interface';
import { IDocument } from 'core/api/documents/documents-api-interface';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { showErrorFlag } from 'core/utilities/flags-helper';
import DocumentApiService from 'core/api/documents/documents-api.service';
import dayjs from 'dayjs';
import { getDocumentTypeNameFromKey } from 'core/constants/document-types';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import SharedButton from 'shared/components/buttons/button';
import { useDialog } from 'core/providers/DialogProvider';
import UploadDocumentDialog from './upload-document-dialog/upload-document-dialog';

const CustomerOverviewDocuments = ({ customer }: ICustomerOverviewDocuments) => {
  const { uid } = customer;
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [loading, setLoading] = useState(true);
  const [documentsLoading, setDocumentsLoading] = useState<string[]>([]);

  const flags = useFlags();
  const dialog = useDialog();

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IDocument>) => {
      const documents: IDocument[] = [];
      querySnapshot.forEach((noteSnapShot) => {
        documents.push(noteSnapShot.data());
      });
      setDocuments(documents);
      setLoading(false);
    };
    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Customer activity could not be retrieved, please try again.', flags);
    };
    let unsubscribe: Unsubscribe;
    unsubscribe = DocumentApiService.subscribeToDocuments(
      handleSnapshot,
      handleSubscriptionError,
      {
        customerUid: uid,
      },
      ['createdAt']
    );
    return () => {
      unsubscribe();
    };
  }, [uid, flags]);

  const openDocument = async (selectedDoc: IDocument) => {
    setDocumentsLoading((prevState) => [...prevState, selectedDoc.uid]);
    try {
      const url = await DocumentApiService.getSignedFileUrl(Date.now() + 1000 * 60 * 60, selectedDoc.path);
      window.open(url);
      removeFromDocumentLoading(selectedDoc.uid);
    } catch (error) {
      removeFromDocumentLoading(selectedDoc.uid);
      showErrorFlag('An error occurred', 'The document cannot be viewed due to an error, please try again.', flags);
    }
  };

  const removeFromDocumentLoading = (selectedDocUid: string) => {
    setDocumentsLoading(documentsLoading.filter((uid) => uid === selectedDocUid));
  };

  const isDocumentLoading = (uid: string) => documentsLoading.includes(uid);

  const openFileUploadDialog = () => {
    dialog?.openDialog(<UploadDocumentDialog customer={customer} />);
  };

  return (
    <div className='bg-white rounded-md shadow-md'>
      <div className='p-4 border-b flex justify-between'>
        <p className='headline-06'>Customer documents</p>
        <SharedButton
          onClick={openFileUploadDialog}
          type='button'
          appearance='link'
          spacing='none'
          label='Upload document'
        />
      </div>

      {loading && (
        <div className='p-4'>
          <div className='h-[24px] w-[75%] bg-gray-100 rounded-md animate-pulse' />
          <div className='mt-1 h-[18px] w-[30%] bg-gray-100 rounded-md animate-pulse' />
        </div>
      )}

      {documents.map((doc) => (
        <div key={doc.uid} className='p-4 border-b last:border-0 flex justify-between items-center'>
          <div>
            <p className='body-01 whitespace-pre-wrap'>{getDocumentTypeNameFromKey(doc.type)}</p>
            <p className='body-03 mt-1 text-gray-500'>
              {`Created: ${dayjs(doc.createdAt.toDate()).format('DD/MM/YYYY, HH:mm')}`}
              <span> by {doc.author ? doc.author : 'ILAH Bot'}</span>
            </p>
          </div>
          <SharedLoadingButton
            isLoading={isDocumentLoading(doc.uid)}
            onClick={() => openDocument(doc)}
            type='button'
            appearance='subtle'
            label='View'
          />
        </div>
      ))}

      {!loading && documents.length === 0 && (
        <p className='body-02 text-gray-500 p-4'>No documents added for this customer</p>
      )}
    </div>
  );
};

export default CustomerOverviewDocuments;
