export enum ControlType {
  TextField,
  TextArea,
  Select,
  DatePicker,
  SelectMulti,
  CustomerSearch,
  AddressSearch,
  File,
  SegmentedSelect
}
