import { IOrderProductDetail } from 'core/api/orders/orders-api-interface';
import { getAidLengthNameFromKey } from 'core/constants/hearing-aid-lengths';
import { getAidReceiverGainNameFromKey } from 'core/constants/hearing-aid-receiver-gains';
import { getAidStyleNameFromKey } from 'core/constants/hearing-aid-styles';
import { getAidTypeNameFromKey } from 'core/constants/hearing-aid-types';

interface IOrderProductsBreakdown {
  left?: IOrderProductDetail;
  right?: IOrderProductDetail;
}

const OrderProductsBreakdown = ({ left, right }: IOrderProductsBreakdown) => {
  const productTableColumns = [
    { label: 'Type', key: 'type', dataTransform: getAidTypeNameFromKey },
    { label: 'Style', key: 'style', dataTransform: getAidStyleNameFromKey },
    { label: 'Gain', key: 'gain', dataTransform: getAidReceiverGainNameFromKey },
    { label: 'Receiver length', key: 'length', dataTransform: getAidLengthNameFromKey },
  ];

  const ears = [
    { label: 'Left', key: 'left' },
    { label: 'Right', key: 'right' },
  ];

  const getProductRows = (left?: IOrderProductDetail, right?: IOrderProductDetail) => {
    return ears.map((side) => {
      const productDetails = side.key === 'left' ? left : right;
      return (
        <div className='grid grid-cols-5 border-t' key={side.key}>
          <p className='border-r p-2 bg-gray-50 font-semibold'>{side.label}</p>
          {!productDetails ? (
            <p className='p-2 col-span-4'>Not prescribed</p>
          ) : (
            productTableColumns.map((col) => (
              <p className='p-2' key={col.key}>
                {col.dataTransform(productDetails[col.key])}
              </p>
            ))
          )}
        </div>
      );
    });
  };
  return (
    <div className='border body-02'>
      <div className='grid grid-cols-5 bg-gray-50 font-semibold'>
        <p className='border-r p-2'></p>
        {productTableColumns.map((col) => (
          <p className='p-2 flex items-center' key={col.key}>
            {col.label}
          </p>
        ))}
      </div>
      <>{getProductRows(left, right)}</>
    </div>
  );
};

export default OrderProductsBreakdown;
