import { firestore } from 'core/config/firebase';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { collection, doc, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import ApiService from '../api.service';
import { IAddSurveyRequestDto, ISurvey, ISurveyQueryOptions } from './surveys-api-interface';
import AuditLogApiService from '../audit-log/audit-log-api.service';
import { IAuditLogChange } from '../audit-log/audit-log-api-interface';

const surveysQueryList: StringIndexable = {
  customerUid: { property: 'customerUid', operator: '==' },
  surveyorUid: { property: 'surveyor.uid', operator: '==' },
  afterDate: { property: 'createdAt', operator: '>' },
  beforeDate: { property: 'createdAt', operator: '<' },
};

const set = async (dto: IAddSurveyRequestDto, changes: IAuditLogChange[]) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.SURVEYS, dto.uid);
  await ApiService.set(docRef, dto);
  return AuditLogApiService.set({
    resourceUid: dto.uid,
    resourceType: 'survey',
    actionType: 'create',
    author: dto.surveyor,
    customerUid: dto.customerUid,
    changes,
  });
};

const getSurveysForCustomer = async (uid: string) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.SURVEYS);
  const q = query(collectionRef, where('customerUid', '==', uid));
  return ApiService.listAllData<ISurvey>(q);
};

const subscribeToSurveys = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: ISurveyQueryOptions,
  ordering?: string[]
) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.SURVEYS);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = surveysQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const SurveysApiService = {
  set,
  getSurveysForCustomer,
  subscribeToSurveys,
};

export default SurveysApiService;
