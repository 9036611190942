import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { getBookingStatusNameFromKey } from 'core/constants/booking-status';
import { getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';

interface IBookingBreakdownDialog {
  customers: IStoredCustomer[];
  title: string;
}

interface IBreakdown {
  count: number;
  key: string;
}

const BookingBreakdownDialog = ({ customers, title }: IBookingBreakdownDialog) => {
  const customContent = () => {
    const statusBreakdown: IBreakdown[] = [];
    const bookingStatusBreakdown: IBreakdown[] = [];

    customers.forEach((customer) => {
      const bookingStatusKey = customer.bookingStatus ?? 'afc';
      const statusIndex = statusBreakdown.findIndex((status) => status.key === customer.status);
      const bookingStatusIndex = bookingStatusBreakdown.findIndex((status) => status.key === bookingStatusKey);

      if (statusIndex === -1) {
        statusBreakdown.push({
          key: customer.status,
          count: 1,
        });
      } else {
        statusBreakdown[statusIndex].count += 1;
      }

      if (bookingStatusIndex === -1) {
        bookingStatusBreakdown.push({
          key: bookingStatusKey,
          count: 1,
        });
      } else {
        bookingStatusBreakdown[bookingStatusIndex].count += 1;
      }
    });

    const sections = [
      {
        key: 'cs',
        title: 'Customer status breakdown',
        breakdowns: statusBreakdown,
        labelFunc: (key: string) => getCustomerStatusNameFromKey(key),
      },
      {
        key: 'bs',
        title: 'Booking statuses breakdown',
        breakdowns: bookingStatusBreakdown,
        labelFunc: (key: string) => (key === 'afc' ? 'Awaiting First Call' : getBookingStatusNameFromKey(key)),
      },
    ];
    return (
      <div className='p-4 overflow-y-auto'>
        {sections.map((section) => (
          <div key={section.key} className='mt-4 first:mt-0 border-t border-dashed first:border-0 pt-4 first:pt-0'>
            <p className='headline-06'>{section.title}</p>
            {section.breakdowns
              .sort((a, b) => b.count - a.count)
              .map((bd) => (
                <div
                  key={bd.key}
                  className='body-02 break-all whitespace-pre-wrap mt-1 flex justify-between hover:bg-gray-50'
                >
                  <p>{section.labelFunc(bd.key)}:</p>
                  <p>{bd.count}</p>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  };
  return (
    <SharedDialogBase
      title={`${title} ${customers.length}`}
      customContentTemplate={
        customers.length > 0 ? customContent() : <div className='p-4'>No customers in this category</div>
      }
    />
  );
};

export default BookingBreakdownDialog;
