import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import DocumentApiService from 'core/api/documents/documents-api.service';
import { useNavigate } from 'react-router-dom';

interface IAudiogramFormSuccessDialog {
  path: string;
}

const AudiogramFormSuccessDialog = ({ path }: IAudiogramFormSuccessDialog) => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const navigate = useNavigate();

  const view = async () => {
    setLoading(true);
    try {
      const url = await DocumentApiService.getSignedFileUrl(Date.now() + 1000 * 60 * 60, path);
      window.open(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorFlag('An error occurred', 'The document cannot be viewed due to an error, please try again.', flags);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'close',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => {
          dialog?.closeDialog();
          navigate(-1);
        },
        appearance: 'subtle',
        label: 'Close',
        type: 'button',
      },
    },
    {
      key: 'view',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => view(),
        appearance: 'primary',
        label: 'View',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={'Audiogram created'}
      textContent='The audiogram was successfully created. Would you like to view the generated audiogram PDF?'
      customButtons={customButtons}
    />
  );
};

export default AudiogramFormSuccessDialog;
