import { PageElement } from 'core/utilities/interface-helpers';

interface IPageHeaderSkeleton {
  actions: PageElement[];
  subtitle?: boolean;
}

const PageHeaderSkeleton = ({ actions, subtitle }: IPageHeaderSkeleton) => {
  return (
    <div className='min-h-[88px] flex items-center justify-between border-b'>
      <div className='py-6'>
        <div className='h-[36px] w-[350px] bg-gray-100 rounded-md animate-pulse mb-2' />
        {subtitle && <div className='h-[24px] w-[180px] bg-gray-100 rounded-md animate-pulse' />}
      </div>
      <div>
        {actions.map((action) => (
          <div key={action.key} className='h-[36px] w-[120px] bg-gray-100 rounded-md' />
        ))}
      </div>
    </div>
  );
};

export default PageHeaderSkeleton;
