export const AidLength = {
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  FIVE: '5',
  NA: 'N/A',
};

export const AidLengths = [
  {
    label: '2',
    value: AidLength.TWO,
  },
  {
    label: '3',
    value: AidLength.THREE,
  },
  {
    label: '4',
    value: AidLength.FOUR,
  },
  {
    label: '5',
    value: AidLength.FIVE,
  },
  {
    label: 'N/A',
    value: AidLength.NA,
  },
];

export const getAidLengthFromKey = (key: string) => {
  return AidLengths.find((length) => length.value === key);
};

export const getAidLengthNameFromKey = (key: string) => {
  const length = AidLengths.find((length) => length.value === key);
  return length?.label ?? 'Unknown';
};
