import { IUser } from 'core/api/users/users-api-interface';
import { Roles } from 'core/config/roles';
import { Permissions } from 'core/constants/permissions';
import { useAuthState } from 'core/providers/AuthProvider';
import { PageElement } from 'core/utilities/interface-helpers';
import { hasPermission } from 'core/utilities/permission-helpers';
import dayjs from 'dayjs';
import { UsersSlice } from 'modules/users/users-slice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ParamKeyValuePair, useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import PageHeader from 'shared/components/page-header/page-header';
import timezone from 'dayjs/plugin/timezone';
import AppointmentListDayView from './appointment-list-day-view/appointment-list-day-view';
import AppointmentListWeeklyView from './appointment-list-weekly-view/appointment-list-weekly-view';
import SegmentedSelect from 'shared/components/form/segmented-select/segmented-select';
dayjs.extend(timezone);

const AppointmentsList = () => {
  const users = useSelector(UsersSlice.selectAll);
  const [resources, setResources] = useState<IUser[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { userRoles, userData, userPermissions } = useAuthState();
  const today = dayjs().format('YYYY-MM-DD');

  // Hooks
  const mode = searchParams.get('mode');
  const date = searchParams.get('date');
  const navigate = useNavigate();

  useEffect(() => {
    if (!date || !mode) {
      setSearchParams(`mode=day&date=${today}`);
    } else {
      const viewableRoles: string[] = [
        'teleHearTechnician',
        'audiologist',
        'hearingAidDispenser',
        'hearingSpecialist',
        'waxSpecialist',
      ];
      let calendarResources: IUser[];
      if (Roles.filter((role) => role.key !== 'hearingSpecialist').some((role) => userRoles?.includes(role.key))) {
        calendarResources = users
          .filter((user) => viewableRoles.some((role) => user.roles.includes(role)))
          .sort((a, b) => (a.priority ?? 10) - (b.priority ?? 10));
      } else {
        calendarResources = userData ? [userData] : [];
      }
      setResources(calendarResources);
    }
  }, [date, mode, setSearchParams, today, userData, userRoles, users]);

  const headerActions: PageElement[] = [
    {
      key: 'toggleView',
      element: (
        <div className='w-[300px]'>
          <SegmentedSelect
            value={mode ?? 'day'}
            options={[
              { value: 'day', label: 'Daily' },
              { value: 'weekly', label: 'Weekly' },
            ]}
            onChange={(value) => paramChanged('mode', value.toString())}
          />
        </div>
      ),
    },
    {
      key: 'addAppointment',
      element: (
        <>
          {hasPermission(Permissions.APPOINTMENTS_CREATE, userPermissions) && (
            <SharedButton
              onClick={() => navigate('new')}
              type='button'
              appearance='primary'
              label='Create appointment'
            />
          )}
        </>
      ),
    },
  ];

  const paramChanged = (key: string, value: string) => {
    const newValues: ParamKeyValuePair[] = [];
    searchParams.forEach((currentVal, currentKey) => {
      if (currentKey !== key) {
        newValues.push([currentKey, currentVal]);
      }
    });
    newValues.push([key, value]);
    setSearchParams(newValues);
  };

  const getCalendar = () => {
    if (!date || !mode || resources.length === 0) {
      return <div className='p-4 text-center body-02'>Loading calendar...</div>;
    }

    if (mode === 'day') {
      return (
        <div className='bg-white shadow-md rounded-md overflow-x-hidden mb-6 mt-4'>
          <AppointmentListDayView date={date} resources={resources} paramChanged={paramChanged} />
        </div>
      );
    }

    if (mode === 'weekly') {
      return (
        <div className='bg-white shadow-md rounded-md overflow-auto mt-4 relative h-fit'>
          <AppointmentListWeeklyView
            date={date}
            resources={resources}
            changeDate={(newValue: string) => paramChanged('date', newValue)}
          />
        </div>
      );
    }

    paramChanged('mode', 'day');
  };

  return (
    <>
      <PageHeader title='Appointments' actions={headerActions} />
      {getCalendar()}
    </>
  );
};

export default AppointmentsList;
