import { firestore } from 'core/config/firebase';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { doc } from 'firebase/firestore';
import { IGetData } from '../api-interface';
import ApiService from '../api.service';

const get = async <TMeta>(uid: string): Promise<IGetData<TMeta>> => {
  const docRef = doc(firestore, FirestoreCollectionIDs.META, uid);
  return ApiService.getData(docRef);
};

const MetaApiService = {
  get,
};

export default MetaApiService;
