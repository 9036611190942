import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UsersSlice } from '../users-slice';
import { IUser } from 'core/api/users/users-api-interface';
import { StoreState } from 'store';
import { DrawingManager, GoogleMap, Polygon } from '@react-google-maps/api';
import PageHeader from 'shared/components/page-header/page-header';
import { useEffect, useState } from 'react';
import { PageElement } from 'core/utilities/interface-helpers';
import SharedButton from 'shared/components/buttons/button';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useFlags } from '@atlaskit/flag';
import UsersApiService from 'core/api/users/users-api.service';
import { useAuthState } from 'core/providers/AuthProvider';
import { getObjectChanges } from 'core/utilities/object-helpers';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import TextArea from '@atlaskit/textarea';

interface IGeoJSON {
  type: string;
  features: {
    type: string;
    id: string;
    geometry: {
      type: string;
      coordinates: number[][][][];
    };
    properties: {
      name: string;
      color: string;
      fillColor: string;
      kind: string;
      isHidden: string;
      weight: string;
      opacity: string;
      dashArray: string;
      fillOpacity: string;
    };
  }[];
}

const ManageWorkArea = () => {
  const [editing, setEditing] = useState(false);
  const [userGeofences, setUserGeofences] = useState<google.maps.LatLngLiteral[][]>([]);
  const [newGeofences, setNewGeofences] = useState<google.maps.LatLngLiteral[][]>([]);
  const { userId } = useParams();
  const user = useSelector((state: StoreState) => UsersSlice.selectByUid(state, userId ?? ''));
  const navigate = useNavigate();
  const flags = useFlags();
  const { userData } = useAuthState();
  const [loading, setLoading] = useState(false);
  const geofenceStringSeparator = '##';
  const [geoJsonString, setGeoJsonString] = useState<string>('');
  const [showGeoJsonInput, setShowGeoJsonInput] = useState(false);

  useEffect(() => {
    const areas = user?.areas
      ? user.areas.map((areaString) =>
          areaString.split(geofenceStringSeparator).map((point) => JSON.parse(point) as google.maps.LatLngLiteral)
        )
      : [];
    setUserGeofences(areas ?? []);
  }, [navigate, user]);

  useEffect(() => {
    if (editing === false && newGeofences.length) {
      setNewGeofences([]);
      setShowGeoJsonInput(false);
    }
  }, [editing, newGeofences]);

  useEffect(() => {
    if (!showGeoJsonInput) {
      setGeoJsonString('');
    }
  }, [showGeoJsonInput]);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const handlePolygonCompletion = (polygon: google.maps.Polygon) => {
    const points: google.maps.LatLngLiteral[] = [];
    const path = polygon.getPath();
    path.forEach((point) => {
      points.push({
        lat: point.lat(),
        lng: point.lng(),
      });
    });
    setNewGeofences((prevState) => [...prevState, points]);
    polygon.setMap(null);
  };

  const saveChanges = async (user: IUser, currentUser: IUser) => {
    setLoading(true);
    try {
      const payload = {
        areas: newGeofences.map((points) => points.map((p) => JSON.stringify(p)).join(geofenceStringSeparator)),
      };
      await UsersApiService.update(
        user.uid,
        user.fullName,
        payload,
        {
          fullName: currentUser.fullName,
          uid: currentUser.uid,
        },
        getObjectChanges(payload, user)
      );
      setEditing(false);
      setLoading(false);
      showSuccessFlag('Update successful', 'The new work areas were successfully saved', flags);
    } catch (error) {
      showErrorFlag('An error occurred', 'The new work areas could not be saved, please try again.', flags);
    }
  };

  const saveGeoJson = () => {
    try {
      const geoJSON: IGeoJSON = JSON.parse(geoJsonString);
      const multiPoly = geoJSON.features[0].geometry.coordinates;
      const points = multiPoly
        .flatMap((p) => p)
        .reduce((accumulator: number[][], current) => {
          return accumulator.length >= current.length ? accumulator : current;
        }, [])
        .map((c) => ({
          lat: c[1],
          lng: c[0],
        }));
      setNewGeofences((prevState) => [...prevState, points]);
      setShowGeoJsonInput(false);
    } catch (error) {}
  };

  const previewActions: PageElement[] = [
    {
      key: 'edit',
      element: <SharedButton onClick={() => setEditing(true)} type='button' appearance='primary' label='Edit' />,
    },
  ];

  const editActions: PageElement[] = [
    {
      key: 'cancel',
      element: (
        <SharedLoadingButton
          onClick={() => setEditing(false)}
          type='button'
          appearance='default'
          label='Cancel'
          isLoading={loading}
        />
      ),
    },
    {
      key: 'save',
      element: (
        <SharedLoadingButton
          onClick={() => saveChanges(user!, userData!)}
          type='button'
          appearance='primary'
          label='Save'
          isLoading={loading}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Manage work area' actions={editing ? editActions : previewActions} showBack />
      {editing && (
        <div className='my-4'>
          {showGeoJsonInput ? (
            <>
              <TextArea
                maxHeight='400px'
                minimumRows={4}
                onChange={(e) => setGeoJsonString(e.currentTarget.value)}
                value={geoJsonString}
                isDisabled={loading}
              />
              <div className='mt-2 space-x-2'>
                <SharedButton
                  onClick={() => {
                    setShowGeoJsonInput(false);
                  }}
                  type='button'
                  appearance='default'
                  label='Cancel'
                />
                <SharedButton onClick={saveGeoJson} type='button' appearance='primary' label='Add' />
              </div>
            </>
          ) : (
            <SharedButton
              onClick={() => {
                setShowGeoJsonInput(true);
              }}
              type='button'
              appearance='default'
              label='Add GeoJSON'
            />
          )}
        </div>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={8}
        center={{
          lat: 53.41291,
          lng: -8.24389,
        }}
      >
        {editing && (
          <DrawingManager
            onPolygonComplete={handlePolygonCompletion}
            drawingMode={google.maps.drawing.OverlayType.POLYGON}
            options={{ drawingControl: false }}
          />
        )}
        {(!editing ? userGeofences : newGeofences).map((fence, index) => (
          <Polygon key={`poly-${index}`} draggable={false} editable={false} path={fence} />
        ))}
      </GoogleMap>
    </>
  );
};

export default ManageWorkArea;
