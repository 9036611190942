import clsx from 'clsx';

interface ISegmentedSelect {
  value: string | number;
  options?: { value: string | number; label: string }[];
  onChange: (value: string | number) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
}

const SegmentedSelect = ({ options, onChange, value, isInvalid, isDisabled }: ISegmentedSelect) => {
  return (
    <div
      className={clsx(
        isDisabled && 'opacity-50 cursor-not-allowed',
        isInvalid && 'border-red-500',
        'h-[40px] rounded-md border-2 bg-gray-50 flex items-center justify-center overflow-hidden'
      )}
    >
      {options?.map((option) => (
        <div
          key={option.value}
          className={clsx(
            value === option.value && 'bg-blue-200',
            value !== option.value && !isDisabled && 'hover:bg-gray-100',
            isDisabled ? 'cursor-not-allow' : 'cursor-pointer',
            'w-full border-r-2 last:border-0 flex items-center justify-center h-full body-02'
          )}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
      {(!options || options.length === 0) && <p className='body-02'>No options provided</p>}
    </div>
  );
};

export default SegmentedSelect;
