import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { AppointmentStatus } from 'core/constants/appointment-status';
import { AppointmentType } from 'core/constants/appointment-type';
import { CustomerStatus } from 'core/constants/customer-status';
import { LeadTypes } from 'core/constants/lead-type';
import { useDialog } from 'core/providers/DialogProvider';
import { getRandomColor } from 'core/utilities/color-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import React from 'react';
import { useCallback } from 'react';
import SharedButton from 'shared/components/buttons/button';
import SharedCustomerListDialog, {
  ISharedCustomerListDialogRow,
} from 'shared/components/dialogs/customer-list-dialog/customer-list-dialog';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';

interface ILeadTypeOutcomeReportCell {
  label: string;
  count: number;
  prescribed: number;
  tnp: number;
  totalCustomers: ISharedCustomerListDialogRow[];
  prescribedCustomers: ISharedCustomerListDialogRow[];
  tnpCustomers: ISharedCustomerListDialogRow[];
  breakdowns: {
    key: string;
    total: number;
    prescribed: number;
    tnp: number;
    label: string;
    totalCustomers: ISharedCustomerListDialogRow[];
    prescribedCustomers: ISharedCustomerListDialogRow[];
    tnpCustomers: ISharedCustomerListDialogRow[];
  }[];
}

interface IDialogButton {
  rows: ISharedCustomerListDialogRow[];
  label: string;
}

const OpenDialogButton = ({ rows, label }: IDialogButton) => {
  const dialog = useDialog();
  return (
    <div>
      <SharedButton
        onClick={() => dialog?.openDialog(<SharedCustomerListDialog rows={rows} />)}
        type='button'
        spacing='none'
        appearance='link'
        label={label}
      />
    </div>
  );
};

const ReportBreakdownCustomCell = (data: ILeadTypeOutcomeReportCell) => {
  const { label, count, prescribed, tnp, breakdowns, totalCustomers, tnpCustomers, prescribedCustomers } = data;
  return (
    <div className='grid grid-cols-6 md:grid-cols-2 md:gap-x-4 gap-y-1'>
      <p className='col-span-2 md:col-span-1 body-01 mb-4'>{label}</p>
      <div className='col-span-4 md:col-span-1 grid grid-cols-3 body-02 mb-4 text-right gap-x-1'>
        <p>Total</p>
        <p>Sold</p>
        <p>TNP</p>
      </div>
      {breakdowns
        .filter((breakdown) => breakdown.total !== 0)
        .map((breakdown) => (
          <React.Fragment key={breakdown.key}>
            <p className='col-span-2 md:col-span-1'>{breakdown.label}</p>
            <div className='col-span-4 md:col-span-1 grid grid-cols-3 body-02 gap-x-1 text-right'>
              <OpenDialogButton rows={breakdown.totalCustomers} label={breakdown.total.toString()} />
              <OpenDialogButton
                rows={breakdown.prescribedCustomers}
                label={`${breakdown.prescribed} (${((breakdown.prescribed / breakdown.total) * 100).toFixed(1)}%)`}
              />
              <OpenDialogButton
                rows={breakdown.tnpCustomers}
                label={`${breakdown.tnp} (${((breakdown.tnp / breakdown.total) * 100).toFixed(1)}%)`}
              />
            </div>
          </React.Fragment>
        ))}
      <p className='col-span-2 md:col-span-1'>All</p>
      <div className='col-span-4 md:col-span-1 grid grid-cols-3 body-02 gap-x-1 text-right'>
        <OpenDialogButton rows={totalCustomers} label={count.toString()} />
        <OpenDialogButton
          rows={prescribedCustomers}
          label={`${prescribed} (${((prescribed / count) * 100).toFixed(1)}%)`}
        />
        <OpenDialogButton rows={tnpCustomers} label={`${tnp} (${((tnp / count) * 100).toFixed(1)}%)`} />
      </div>
    </div>
  );
};

const LeadTypeOutcomeReport = () => {
  const processRows = useCallback(async (querySnapshot: QuerySnapshot<IAppointment>) => {
    const rows: IReportBaseTableRows<ILeadTypeOutcomeReportCell>[] = [];
    querySnapshot.forEach((appointment) => {
      const { customer, assignee, prescribed, status } = appointment.data();
      const customerListRow = { customer };
      if (status === AppointmentStatus.CANCELLED) {
        return;
      }
      const rowIndex = rows.findIndex((row) => row.key === assignee.uid);
      if (rowIndex !== -1) {
        rows[rowIndex].data.count += 1;
        rows[rowIndex].data.totalCustomers.push(customerListRow);
        if (prescribed) {
          rows[rowIndex].data.prescribed += 1;
          rows[rowIndex].data.prescribedCustomers.push(customerListRow);
        } else if (customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED) {
          rows[rowIndex].data.tnp += 1;
          rows[rowIndex].data.tnpCustomers.push(customerListRow);
        }

        // Breakdowns
        const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === customer.leadType);
        if (typeIndex !== -1) {
          rows[rowIndex].data.breakdowns[typeIndex].total += 1;
          rows[rowIndex].data.breakdowns[typeIndex].totalCustomers.push(customerListRow);
          if (prescribed) {
            rows[rowIndex].data.breakdowns[typeIndex].prescribed += 1;
            rows[rowIndex].data.breakdowns[typeIndex].prescribedCustomers.push(customerListRow);
          } else if (customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED) {
            rows[rowIndex].data.breakdowns[typeIndex].tnp += 1;
            rows[rowIndex].data.breakdowns[typeIndex].tnpCustomers.push(customerListRow);
          }
        }
      } else {
        rows.push({
          key: assignee.uid,
          color: getRandomColor(),
          data: {
            label: assignee.fullName,
            prescribed: prescribed ? 1 : 0,
            tnp: customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED ? 1 : 0,
            count: 1,
            totalCustomers: [customerListRow],
            prescribedCustomers: prescribed ? [customerListRow] : [],
            tnpCustomers: customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED ? [customerListRow] : [],
            breakdowns: LeadTypes.map((type) => ({
              key: type.value,
              prescribed: customer.leadType === type.value && prescribed ? 1 : 0,
              prescribedCustomers: customer.leadType === type.value && prescribed ? [customerListRow] : [],
              tnp: customer.leadType === type.value && customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED ? 1 : 0,
              tnpCustomers:
                customer.leadType === type.value && customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED
                  ? [customerListRow]
                  : [],
              total: customer.leadType === type.value ? 1 : 0,
              totalCustomers: customer.leadType === type.value ? [customerListRow] : [],
              label: type.label,
            })),
          },
        });
      }
    });
    return rows;
  }, []);

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'dispenser',
    },
  ];

  const tableColumns = [{ label: 'Dispenser', key: 'dispenser', templateId: 'dispenser', width: 100 }];

  return (
    <>
      <PageHeader title='Dispenser/lead type outcome report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeAppointmentDate: before,
          afterAppointmentDate: after,
          types: [AppointmentType.HOME_TEST, AppointmentType.SHOP_TEST],
        })}
        subscriber={AppointmentsApiService.subscribeToAppointments}
        totalLabel={'Total number of bookings:'}
        emptyText={'No appointments created during the selected time frame'}
        customTemplates={customTemplates}
        queryOrdering={['fullDate']}
      />
    </>
  );
};

export default LeadTypeOutcomeReport;
