export const Pinna = {
    NORMAL: 'normal',
    ABNORMAL: 'abnormal',
  };
  
  export const PinnaOptions = [
    {
      label: 'Normal',
      value: Pinna.NORMAL,
    },
    {
      label: 'Abnormal',
      value: Pinna.ABNORMAL,
    },
  ];
  
  export const getPinnaFromKey = (key: string) => {
    return PinnaOptions.find((p) => p.value === key);
  };
  
  export const getPinnaNameFromKey = (key: string) => {
    const p = PinnaOptions.find((p) => p.value === key);
    return p?.label ?? 'Unknown';
  };
  