import { firestore } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { collection, doc, orderBy, query, QueryConstraint, QuerySnapshot, where } from 'firebase/firestore';
import ApiService from '../api.service';
import {
  IAddAppointmentRequestDto,
  IAppointment,
  IAppointmentQueryOptions,
  IEditAppointmentRequestDto,
  ISendAppointmentConfirmationRequestDto,
} from './appointments-api-interface';
import AuditLogApiService from '../audit-log/audit-log-api.service';
import { IAuditLogChange, IAuditLogPerson } from '../audit-log/audit-log-api-interface';

const appointmentsQueryList: StringIndexable = {
  customerId: { property: 'customer.uid', operator: '==' },
  eirCode: { property: 'customer.eirCode', operator: '==' },
  date: { property: 'date', operator: '==' },
  assigneeUid: { property: 'assignee.uid', operator: '==' },
  types: { property: 'type', operator: 'in' },
  organiserUid: { property: 'organiser.uid', operator: '==' },
  assigneeUids: { property: 'assignee.uid', operator: 'in' },
  afterDate: { property: 'createdAt', operator: '>' },
  beforeDate: { property: 'createdAt', operator: '<' },
  afterAppointmentDate: { property: 'fullDate', operator: '>=' },
  beforeAppointmentDate: { property: 'fullDate', operator: '<=' },
  dates: { property: 'date', operator: 'in' },
  status: { property: 'status', operator: '==' },
};

const subscribeToAppointments = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: IAppointmentQueryOptions,
  ordering?: string[]
) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.APPOINTMENTS);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = appointmentsQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const listAll = (
  queryOptions?: IAppointmentQueryOptions,
  ordering?: string[]
): Promise<QuerySnapshot<IAppointment>> => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.APPOINTMENTS);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = appointmentsQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.listAll<IAppointment>(q);
};

const subscribeToAppointment = (uid: string, subscriptionHandler: Function, errorHandler: Function) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.APPOINTMENTS, uid);
  return ApiService.subscribeToDoc<IAppointment>(docRef, subscriptionHandler, errorHandler);
};

const set = async (dto: IAddAppointmentRequestDto, changes: IAuditLogChange[]) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.APPOINTMENTS, dto.uid);
  await ApiService.set(documentRef, dto);
  return AuditLogApiService.set({
    resourceUid: dto.uid,
    resourceType: 'appointment',
    actionType: 'create',
    author: dto.organiser,
    customerUid: dto.customer.uid,
    changes,
  });
};

const get = async (uid: string) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.APPOINTMENTS, uid);
  return ApiService.getData<IAppointment>(documentRef);
};

const update = async (
  uid: string,
  customerUid: string,
  updatedBy: IAuditLogPerson,
  dto: IEditAppointmentRequestDto,
  changes: IAuditLogChange[]
) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.APPOINTMENTS, uid);
  await ApiService.update(documentRef, dto);
  return AuditLogApiService.set({
    resourceUid: uid,
    resourceType: 'appointment',
    actionType: 'update',
    author: updatedBy,
    customerUid: customerUid,
    changes,
  });
};

const remove = async (uid: string, customerUid: string, removedBy: IAuditLogPerson) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.APPOINTMENTS, uid);
  await ApiService.remove(documentRef);
  return AuditLogApiService.set({
    resourceUid: uid,
    resourceType: 'appointment',
    actionType: 'delete',
    author: removedBy,
    customerUid: customerUid,
  });
};

const sendConfirmationEmail = async (dto: ISendAppointmentConfirmationRequestDto) => {
  const response = await ApiService.callFunction(dto, FirebaseFunctionNames.SEND_APPOINTMENT_EMAIL);
  return response;
};

const sendConfirmationSMS = async (dto: ISendAppointmentConfirmationRequestDto) => {
  const response = await ApiService.callFunction(dto, FirebaseFunctionNames.SEND_APPOINTMENT_SMS);
  return response;
};

const AppointmentsApiService = {
  subscribeToAppointments,
  subscribeToAppointment,
  set,
  get,
  update,
  remove,
  sendConfirmationEmail,
  sendConfirmationSMS,
  listAll,
};

export default AppointmentsApiService;
