import { Navigate, Route, Routes } from 'react-router-dom';
import UsersList from './users-list/users-list';
import ManageWorkArea from './manage-work-area/manage-work-area';

const UsersRouting = () => {
  return (
    <Routes>
      <Route path='' element={<UsersList />} />
      <Route path='/manage-work-area/:userId' element={<ManageWorkArea />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default UsersRouting;
