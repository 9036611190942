import { SidebarOptions } from 'core/config/sidebar-options';
import { useAuthState } from 'core/providers/AuthProvider';
import { Dispatch, SetStateAction } from 'react';
import SharedSidebarItem from './sidebar-item';

interface ISharedSidebar {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const SharedSidebar = ({ setSidebarOpen }: ISharedSidebar) => {
  const { userPermissions } = useAuthState();
  return (
    <div className='border-r flex-grow-0 flex-shrink-0 basis-[200px] xl:basis-[288px] h-full'>
      {SidebarOptions.filter((item) =>
        item.requiredPermissions.every((permission) => userPermissions?.includes(permission))
      ).map((item) => (
        <SharedSidebarItem
          key={item.route}
          icon={item.icon}
          label={item.label}
          route={item.route}
          setSidebarOpen={setSidebarOpen}
        />
      ))}
    </div>
  );
};

export default SharedSidebar;
