import { ISharedTableHead } from './table-head-interface';

const SharedTableHead = ({ columns }: ISharedTableHead) => {
  return (
    <thead>
      <tr>
        {columns.map(({ key, width, label, isCompact = false }) => (
          <th
            key={key}
            className={`py-3 px-3 text-left body-02 font-semibold ${!isCompact && 'min-w-[130px]'}`}
            style={{ width: `${width}%` }}
          >
            <p>{label}</p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default SharedTableHead;
