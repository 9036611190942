import { DatePicker } from '@atlaskit/datetime-picker';
import { IOrder } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { CertificateStatus } from 'core/constants/certificate-status';
import { StringIndexable } from 'core/utilities/interface-helpers';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Hash } from 'react-feather';
import SharedButton from 'shared/components/buttons/button';
import SharedLoadingButton from 'shared/components/buttons/loading-button';

const UploadCounter = () => {
  const [show, setShow] = useState(false);
  const [dates, setDates] = useState<StringIndexable>({});
  const [orders, setOrders] = useState<IOrder[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const dateChanged = (key: string, value: string) => {
    setOrders(undefined);
    setError(undefined);
    setDates((prevState) => ({ ...prevState, [key]: value }));
  };

  const datePickers = [
    {
      key: 'uploadDateFrom',
      label: 'Upload date from',
    },
    {
      key: 'uploadDateTo',
      label: 'Upload date to',
    },
  ];

  const getUploads = async () => {
    if (!dates.uploadDateFrom || !dates.uploadDateTo) {
      setError('Please complete date filters');
    } else {
      setOrders(undefined);
      setLoading(true);
      try {
        const snap = await OrdersApiService.listAll(
          {
            uploadedAfterDate: dayjs(dates.uploadDateFrom).toDate(),
            uploadedBeforeDate: dayjs(dates.uploadDateTo).set('hour', 23).set('minute', 59).toDate(),
            certificateStatuses: [CertificateStatus.UPLOADED],
          },
          ['certificate.uploadedAt']
        );
        const orders = snap.docs.map((doc) => doc.data());
        setOrders(orders);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  return (
    <div className='bg-white rounded-md shadow-md mt-4'>
      <div className='p-3 flex justify-between items-center cursor-pointer' onClick={() => setShow(!show)}>
        <div className='flex items-center'>
          <Hash />
          <p className='ml-2 body-02'>Upload counter</p>
        </div>
        <SharedButton
          onClick={() => setShow(!show)}
          type='button'
          appearance='default'
          label={show ? 'Hide' : 'Show'}
        />
      </div>
      {show && (
        <div>
          <div className='border-t grid p-3 grid-cols-3 gap-6'>
            {datePickers.map((datePicker) => (
              <div key={datePicker.key}>
                <p className='label-02 font-semibold mb-1 text-gray-500'>{datePicker.label}</p>
                <DatePicker
                  isDisabled={loading}
                  selectProps={{ isSearchable: false }}
                  locale={'en-UK'}
                  defaultValue={dates[datePicker.key] ?? ''}
                  onChange={(value) => dateChanged(datePicker.key, value)}
                />
              </div>
            ))}
            <div className='flex items-end justify-between'>
              <p className='body-03 text-red-500'>{error}</p>
              <SharedLoadingButton
                isLoading={loading}
                onClick={getUploads}
                type='button'
                appearance='primary'
                label='Submit'
              />
            </div>
          </div>
          <div>
            {orders && (
              <div className='border-t p-4'>
                <p className='body-02'>{orders.length} orders found</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadCounter;
