export const FirebaseFunctionNames = {
  ADD_USER: 'addUser',
  UPDATE_USER_PASSWORD: 'updateUserPassword',
  GET_FILE_SIGNED_URL: 'getFileSignedUrl',
  GET_FILE_BASE_64: 'getFileBase64',
  REGENERATE_CUSTOMER_DOCUMENTS: 'regenerateCustomerDocuments',
  SEND_APPOINTMENT_EMAIL: 'sendAppointmentEmail',
  SEND_APPOINTMENT_REMINDER_SMS: 'sendAppointmentReminderSMS',
  SEND_CUSTOMER_MISSED_CALL_EMAIL: 'sendCustomerMissedCallEmail',
  SEND_CUSTOMER_MISSED_CALL_SMS: 'sendCustomerMissedCallSMS',
  SEND_CUSTOMER_SURVEY_EMAIL: 'sendCustomerSurveyEmail',
  SEND_APPOINTMENT_SMS: 'sendAppointmentSMS',
  GENERATE_SALES_ORDER: 'generateSalesOrder',
  SEND_ORDER_CONFIRMATION_EMAIL: 'sendOrderConfirmationEmail',
  GENERATE_AUDIOGRAM: 'generateAudiogram',
  EXPORT_CUSTOMER_DATA: 'exportCustomerData',
};
