import { useFlags } from '@atlaskit/flag';
import { IUser } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { AddUserFormFields } from 'core/config/form-fields';
import { getRoleNameFromKey } from 'core/config/roles';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useEffect, useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import AddUserInfoDialog from '../add-user-info-dialog/add-user-info-dialog';
import { IAddEditUserDialog, IAddEditUserFormOutput } from './add-edit-user-dialog-interface';
import { useAuthState } from 'core/providers/AuthProvider';
import { getObjectChanges } from 'core/utilities/object-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';

const AddEditUserDialog = ({ userUid }: IAddEditUserDialog) => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const editing = userUid !== undefined;
  const [formFields, setFormFields] = useState(AddUserFormFields);
  const [fetchingData, setFetchingData] = useState(false);
  const [exitingData, setExistingData] = useState<IUser>();

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();
  const { userData } = useAuthState();

  const getDefaultValue = (user: IUser, key: string) => {
    switch (key) {
      case 'roles':
        return user.roles.map((roleKey) => ({
          value: roleKey,
          label: getRoleNameFromKey(roleKey),
        }));
      case 'fullName':
        return user.fullName;
      case 'emailAddress':
        return user.emailAddress;
      case 'lng':
        return user.address?.lng ?? '';
      case 'lat':
        return user.address?.lat ?? '';
    }
  };

  useEffect(() => {
    if (editing) {
      const prepare = async () => {
        setFetchingData(true);
        const snap = await UsersApiService.get(userUid);
        setExistingData(snap.data);
        const populatedFormFields = AddUserFormFields.map((field) => {
          return { ...field, defaultValue: getDefaultValue(snap.data, field.key) };
        });
        setFormFields(populatedFormFields);
        setFetchingData(false);
      };
      prepare();
    }
  }, [editing, userUid]);

  const isAddressPopulated = (data: IAddEditUserFormOutput) => isNotNullOrEmpty(data.lat) && isNotNullOrEmpty(data.lng);

  // Page specifics
  const addUser = async (data: IAddEditUserFormOutput) => {
    setFormSubmitting(true);
    const user = {
      fullName: userData!.fullName,
      uid: userData!.uid,
    };
    const payloadValues = {
      emailAddress: data.emailAddress,
      roles: data.roles.map((role) => role.value),
      fullName: data.fullName,
      ...(isAddressPopulated(data) && {
        address: {
          lng: parseFloat(data.lng),
          lat: parseFloat(data.lat),
        },
      }),
    };

    try {
      if (editing) {
        await UsersApiService.update(
          userUid,
          payloadValues.fullName,
          payloadValues,
          user,
          getObjectChanges(payloadValues, exitingData)
        );
        dialog?.closeDialog();
      } else {
        const response = await UsersApiService.add(
          { ...payloadValues, createdBy: user },
          getObjectChanges(payloadValues, {})
        );
        dialog?.replaceDialog(
          <AddUserInfoDialog fullName={data.fullName} emailAddress={data.emailAddress} temporaryPassword={response} />
        );
      }
    } catch (error) {
      showErrorFlag(
        editing ? 'User updated failed' : 'User creation failed',
        editing
          ? `Unable to update the account for ${data.fullName}, please try again.`
          : `Unable to create an account for ${data.fullName}, please try again.`,
        flags
      );
      setFormSubmitting(false);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4'
        onSubmit={addUser}
        fields={formFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        cancelButton={cancelButton}
        initialising={fetchingData}
      />
    );
  };

  return (
    <SharedDialogBase
      title={editing ? 'Edit user' : 'Add user'}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditUserDialog;
