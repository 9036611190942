import { ICustomer } from 'core/api/customers/customers-api-interface';
import { Dispensers } from 'core/constants/dispensers';
import { ControlType } from 'core/enums/control-type';
import { ISharedFormField } from 'shared/components/form/form-interface';

interface IHearingTestDetails {
  customer: ICustomer;
  getFormInput: (field: ISharedFormField, formKey: string) => JSX.Element;
  showFormError: (fieldKey: string, formKey?: string) => boolean;
}

const HearingTestDetails = ({ customer, getFormInput, showFormError }: IHearingTestDetails) => {
  const orderDetails = [
    {
      key: 'dispenser',
      label: 'Dispenser:',
      type: 'input',
      input: {
        key: 'dispenser',
        control: ControlType.Select,
        options: Dispensers,
      },
    },
    {
      key: 'testDate',
      label: 'Hearing test date:',
      type: 'input',
      input: {
        key: 'testDate',
        control: ControlType.DatePicker,
      },
    },
    {
      key: 'fittingDate',
      label: 'Fitting date:',
      type: 'input',
      input: {
        key: 'fittingDate',
        control: ControlType.DatePicker,
      },
    },
    {
      key: 'name',
      label: "Customer's name:",
      value: customer.fullName,
      type: 'text',
    },
    {
      key: 'address',
      label: 'Address:',
      value: customer.address?.formatted_address,
      type: 'text',
    },
    {
      key: 'phoneNumber',
      label: 'Phone number:',
      value: customer.phoneNumber,
      type: 'text',
    },
    {
      key: 'dob',
      label: 'Date of birth:',
      type: customer.dob ? 'text' : 'input',
      value: customer.dob,
      input: {
        key: 'dob',
        control: ControlType.TextField,
      },
    },
    {
      key: 'significantOther',
      label: 'Significant other:',
      type: 'input',
      input: {
        key: 'significantOther',
        control: ControlType.TextField,
      },
    },
    {
      key: 'significantOtherRelation',
      label: 'Significant other relation:',
      type: 'input',
      input: {
        key: 'significantOtherRelation',
        control: ControlType.TextField,
      },
    },
    {
      key: 'durationOfLoss',
      label: 'Duration of loss:',
      type: 'input',
      input: {
        key: 'durationOfLoss',
        control: ControlType.TextField,
      },
    },
    {
      key: 'occupation',
      label: 'Occupation:',
      type: 'input',
      input: {
        key: 'occupation',
        control: ControlType.TextField,
      },
    },
    {
      key: 'familyDeafness',
      label: 'Family deafness:',
      type: 'input',
      input: {
        key: 'familyDeafness',
        control: ControlType.TextField,
      },
    },
    {
      key: 'doctor',
      label: 'Doctor/Surgery:',
      type: 'input',
      input: {
        key: 'doctor',
        control: ControlType.TextField,
      },
    },
  ];

  return (
    <div className='bg-white w-full rounded-md shadow-md lg:sticky lg:top-4 h-fit mt-4'>
      <div className='flex justify-between items-center p-4 border-b headline-06'>Hearing test details</div>
      <div className='p-4'>
        {orderDetails.map((details) => (
          <div key={details.key} className='mt-4 first:mt-0'>
            <p className='body-02 text-gray-500'>{details.label}</p>
            {details.type === 'text' ? (
              <p className='body-01 break-all'>{details.value}</p>
            ) : (
              <>
                {getFormInput({ ...details.input!, required: true }, 'details')}
                {showFormError(details.input!.key, 'details') && (
                  <p className='mt-1 label-02 text-red-600'>This field is required</p>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HearingTestDetails;
