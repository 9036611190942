import Button from '@atlaskit/button';
import { auth } from 'core/config/firebase';
import { Permissions } from 'core/constants/permissions';
import { useAuthState } from 'core/providers/AuthProvider';
import { useResponsiveState } from 'core/providers/ResponsiveProvider';
import { hasPermission } from 'core/utilities/permission-helpers';
import { signOut } from 'firebase/auth';
import { Dispatch, SetStateAction } from 'react';
import { Menu } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import CustomerSearch from '../form/customer-search/customer-search';
import { ICustomerSearchOutput } from '../form/customer-search/customer-search-interface';

interface IHeader {
  title: string;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const SharedHeader = ({ title, setSidebarOpen }: IHeader) => {
  const { userData, userPermissions } = useAuthState();
  const { isTablet } = useResponsiveState();
  const navigate = useNavigate();

  const logOut = () => {
    signOut(auth);
  };

  const customerSearch = hasPermission(Permissions.CUSTOMERS_LIST, userPermissions) ? (
    <CustomerSearch
      clearAfterSelect
      fieldProps={{
        placeholder: 'Search for a customer',
        onChange: (value: ICustomerSearchOutput) => {
          if (value.customerId) {
            navigate(`customers/${value.customerId}`);
          }
        },
      }}
    />
  ) : (
    <></>
  );

  return (
    <div className='fixed bg-primary h-[136px] md:h-[64px] w-full z-10'>
      <div className='flex items-center h-[64px]'>
        <div className='w-full flex items-center justify-between px-4 lg:px-6'>
          <div className='flex items-center'>
            {isTablet && (
              <Button
                onClick={() => setSidebarOpen(true)}
                iconBefore={<Menu className='text-white mr-4' />}
                appearance='subtle'
                spacing='none'
              />
            )}
            <p className='headline-app text-white'>{title}</p>
          </div>
          <div className='flex items-center'>
            <div className='hidden md:block w-[350px] lg:w-[600px] mr-6'>{customerSearch}</div>
            <div className='text-right text-white'>
              <p className='body-02'>{userData?.fullName}</p>
              <p className='body-03 opacity-80 cursor-pointer' onClick={() => logOut()}>
                Log Out
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='p-4 md:hidden block'>{customerSearch}</div>
    </div>
  );
};

export default SharedHeader;
