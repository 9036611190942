import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { useNavigate } from 'react-router-dom';

interface IDeleteCustomersDialog {
  customers: string[];
  goBack?: boolean;
}

const DeleteCustomersDialog = ({ customers, goBack = false }: IDeleteCustomersDialog) => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const multiple = customers.length > 1;
  const navigate = useNavigate();

  const deleteCustomers = async () => {
    setLoading(true);
    try {
      await CustomersApiService.removeCustomers(customers);
      showSuccessFlag(
        'Deletion successful',
        swapString('All customers were successfully deleted', 'The customer was successfully deleted'),
        flags
      );
      dialog?.closeDialog();
      if (goBack) {
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      showErrorFlag(
        'Deletion failed',
        swapString(
          'Unable to delete these customers, please try again',
          'Unable to delete this customer, please try again'
        ),
        flags
      );
    }
  };

  const swapString = (multiString: string, singleString: string) => (multiple ? multiString : singleString);

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Cancel',
        type: 'button',
      },
    },
    {
      key: 'deleteUser',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => deleteCustomers(),
        appearance: 'danger',
        label: swapString('Delete customers', 'Delete customer'),
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={swapString(`Delete ${customers.length} customers`, 'Delete this customer?')}
      textContent={swapString(
        'Are you sure you want to delete these customers? This action cannot be undone.',
        'Are you sure you want to delete this customer? This action cannot be undone.'
      )}
      customButtons={customButtons}
    />
  );
};

export default DeleteCustomersDialog;
