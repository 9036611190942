import Form, { Field, FormFooter } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { ControlType } from 'core/enums/control-type';
import { AlertTriangle } from 'react-feather';
import SharedLoadingButton from '../buttons/loading-button';
import type { ValueType } from '@atlaskit/select/types';
import { SelectOption } from 'core/utilities/interface-helpers';
import Select from '@atlaskit/select';
import { ButtonGroup } from '@atlaskit/button';
import SharedButton from '../buttons/button';
import { IFormAddress, ISharedForm, ISharedFormField } from './form-interface';
import { DatePicker } from '@atlaskit/datetime-picker';
import TextArea from '@atlaskit/textarea';
import CustomerSearch from './customer-search/customer-search';
import SkeletonElement from '../layout/skeleton-element';
import AddressSearch from './address-search/address-search';
import FileCapture from './file-capture/file-capture';

const SharedForm = ({
  onSubmit,
  fields,
  buttonLabel,
  loading,
  formErrorMessage,
  cancelButton,
  className = '',
  initialising = false,
}: ISharedForm) => {
  const validateField = (field: ISharedFormField, value?: any) => {
    if (!value && field.required) {
      return 'REQUIRED';
    }

    if (field.control === ControlType.AddressSearch && value.valid === false) {
      return 'You must enter a valid address';
    }
  };

  const getFormField = (field: ISharedFormField) => {
    switch (field.control) {
      case ControlType.Select:
        return (
          <Field<ValueType<SelectOption>>
            key={field.key}
            aria-required={field.required}
            name={field.key}
            defaultValue={(field.defaultValue as SelectOption) ?? null}
            label={field.label}
            isRequired={field.required}
            isDisabled={loading}
            validate={(value) => validateField(field, value)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select<SelectOption>
                  {...fieldProps}
                  options={field.options}
                  validationState={error ? 'error' : 'default'}
                  isSearchable={false}
                />
                {error === 'REQUIRED' && <p className='mt-2 label-01 text-red-600'>This field is required</p>}
              </>
            )}
          </Field>
        );
      case ControlType.SelectMulti:
        return (
          <Field<ValueType<SelectOption, true>>
            key={field.key}
            aria-required={field.required}
            name={field.key}
            defaultValue={(field.defaultValue as SelectOption[]) ?? []}
            label={field.label}
            isRequired={field.required}
            isDisabled={loading}
            validate={(value) => validateField(field, value)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select<SelectOption, true>
                  {...fieldProps}
                  options={field.options}
                  isMulti
                  validationState={error ? 'error' : 'default'}
                  isSearchable={false}
                />
                {error === 'REQUIRED' && <p className='mt-2 label-01 text-red-600'>This field is required</p>}
              </>
            )}
          </Field>
        );
      case ControlType.DatePicker:
        return (
          <Field
            key={field.key}
            name={field.key}
            label={field.label}
            defaultValue={(field.defaultValue as string) ?? ''}
            isRequired={field.required}
            validate={(value) => validateField(field, value)}
          >
            {({ fieldProps: { id, ...rest }, error }) => (
              <>
                <DatePicker selectProps={{ inputId: id, isSearchable: false }} {...rest} locale={'en-UK'} />
                {error === 'REQUIRED' && <p className='mt-2 label-01 text-red-600'>This field is required</p>}
              </>
            )}
          </Field>
        );
      case ControlType.TextArea:
        return (
          <Field
            key={field.key}
            name={field.key}
            label={field.label}
            defaultValue={field.defaultValue ?? ''}
            aria-required={field.required}
            isRequired={field.required}
            isDisabled={loading}
          >
            {({ fieldProps }: any) => <TextArea {...fieldProps} />}
          </Field>
        );
      case ControlType.AddressSearch:
        return (
          <Field
            key={field.key}
            aria-required={field.required}
            name={field.key}
            isRequired={field.required}
            validate={(value) => validateField(field, value)}
            isDisabled={loading}
            defaultValue={(field.defaultValue as IFormAddress) ?? ''}
          >
            {({ fieldProps, error }) => (
              <>
                <AddressSearch fieldProps={fieldProps} formError={error} />
              </>
            )}
          </Field>
        );
      case ControlType.CustomerSearch:
        return (
          <Field
            key={field.key}
            aria-required={field.required}
            name={field.key}
            label={field.label}
            isRequired={field.required}
            validate={(value) => validateField(field, value)}
            isDisabled={loading}
            defaultValue=''
          >
            {({ fieldProps, error }) => (
              <>
                <CustomerSearch fieldProps={fieldProps} />
                {error === 'INVALID' && (
                  <p className='mt-2 label-01 text-red-600'>Please select an customer from the list of suggestions</p>
                )}
              </>
            )}
          </Field>
        );
      case ControlType.File:
        return (
          <Field
            key={field.key}
            aria-required={field.required}
            name={field.key}
            label={field.label}
            isRequired={field.required}
            validate={(value) => validateField(field, value)}
            isDisabled={loading}
          >
            {({ fieldProps, error }) => (
              <>
                <FileCapture fieldProps={fieldProps} />
                {error === 'INVALID' && <p className='mt-2 label-01 text-red-600'>Invalid file uploaded</p>}
              </>
            )}
          </Field>
        );
      default:
        return (
          <Field
            key={field.key}
            aria-required={field.required}
            name={field.key}
            defaultValue={(field.defaultValue as string) ?? ''}
            label={field.label}
            isRequired={field.required}
            isDisabled={loading}
          >
            {({ fieldProps }) => <TextField placeholder={field.placeholder} {...fieldProps} type={field.type} />}
          </Field>
        );
    }
  };

  return (
    <Form onSubmit={(data) => onSubmit(data)}>
      {({ formProps }) => (
        <form {...formProps} className={className}>
          {fields
            .filter((field) => !field.hidden)
            .map((field, index) =>
              initialising ? (
                <div key={index} className='mt-[16px]'>
                  <SkeletonElement height='18px' width='100px' className='mb-[4px]' />
                  <SkeletonElement height='36px' width='100%' />
                  <div />
                </div>
              ) : (
                getFormField(field)
              )
            )}
          {formErrorMessage && (
            <div className='mt-6 label-01 text-red-600 flex items-center'>
              <AlertTriangle className='mr-3' />
              {formErrorMessage}
            </div>
          )}
          <FormFooter>
            <ButtonGroup>
              {cancelButton && <SharedButton {...cancelButton} />}
              <SharedLoadingButton type='submit' appearance='primary' isLoading={loading} label={buttonLabel} />
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default SharedForm;
