import { ISurvey } from 'core/api/surveys/surveys-api-interface';
import SurveysApiService from 'core/api/surveys/surveys-api.service';
import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import 'chart.js/auto';
import { getRandomColor } from 'core/utilities/color-helpers';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import SharedButton from 'shared/components/buttons/button';
import SharedCustomerListDialog, {
  ISharedCustomerListDialogRow,
} from 'shared/components/dialogs/customer-list-dialog/customer-list-dialog';
import { useDialog } from 'core/providers/DialogProvider';
import { ICustomer, IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { useSelector } from 'react-redux';
import { CustomersSlice } from 'modules/customers/customers-slice';
import CustomersApiService from 'core/api/customers/customers-api.service';

interface ISurveyCreationReportRowData {
  label: string;
  count: number;
  customerListRows: ISharedCustomerListDialogRow[];
}

interface ISurveyCustomerData {
  customer: ICustomer | IStoredCustomer;
  surveyor: {
    uid: string;
    fullName: string;
  };
}

const Cell = ({ count, customerListRows }: ISurveyCreationReportRowData) => {
  const dialog = useDialog();
  return (
    <div>
      <SharedButton
        onClick={() => dialog?.openDialog(<SharedCustomerListDialog rows={customerListRows} />)}
        type='button'
        spacing='none'
        appearance='link'
        label={count.toString()}
      />
    </div>
  );
};

const SurveyCreationReport = () => {
  const storedCustomers = useSelector(CustomersSlice.selectAll);
  const processRows = useCallback(
    async (querySnapshot: QuerySnapshot<ISurvey>) => {
      const rows: IReportBaseTableRows<ISurveyCreationReportRowData>[] = [];

      const promises = querySnapshot.docs.map((doc) => {
        const survey = doc.data();
        return new Promise<ISurveyCustomerData | undefined>(async (resolve, reject) => {
          let customer: IStoredCustomer | ICustomer | undefined = storedCustomers.find(
            (customer) => customer.uid === survey.customerUid
          );
          if (!customer) {
            try {
              const response = await CustomersApiService.get(survey.customerUid);
              customer = response.data;
              resolve({
                customer,
                surveyor: survey.surveyor,
              });
            } catch (error) {
              resolve(undefined);
            }
          } else {
            resolve({
              customer,
              surveyor: survey.surveyor,
            });
          }
        });
      });
      const surveyCustomerData = await Promise.all(promises);

      surveyCustomerData.forEach((scData) => {
        if (!scData) {
          return;
        }
        const { customer, surveyor } = scData;
        const rowIndex = rows.findIndex((row) => row.key === surveyor.uid);
        const customerListRow = {
          customer,
        };
        if (rowIndex !== -1) {
          rows[rowIndex].data.count += 1;
          rows[rowIndex].data.customerListRows?.push(customerListRow);
        } else {
          rows.push({
            key: surveyor.uid,
            color: getRandomColor(),
            data: {
              label: surveyor.fullName,
              count: 1,
              customerListRows: [customerListRow],
            },
          });
        }
      });
      return rows;
    },
    [storedCustomers]
  );

  const tableColumns = [
    { label: 'Surveyor', key: 'label', templateId: 'text', width: 50 },
    { label: 'Count', key: 'count', templateId: 'count', width: 50 },
  ];

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: Cell,
      id: 'count',
    },
  ];

  return (
    <>
      <PageHeader title='Survey creation report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
        })}
        subscriber={SurveysApiService.subscribeToSurveys}
        totalLabel={'Total number of surveys created'}
        emptyText='No surveys created during the selected time frame'
        customTemplates={customTemplates}
      />
    </>
  );
};

export default SurveyCreationReport;
