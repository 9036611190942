import Toggle from '@atlaskit/toggle';
import { OrderProductDetailFormFields, OrderProductDetailGeneralFormFields } from 'core/config/form-fields';
import { SelectOption } from 'core/utilities/interface-helpers';
import SharedButton from 'shared/components/buttons/button';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { IOrderFormValues } from '../order-form-interface';

interface IOrderFormProductDetails {
  valueChanged: (formKey: string, fieldKey: string, value: string | boolean | SelectOption | null) => void;
  formValues: IOrderFormValues;
  getFormFields: (fields: ISharedFormField[], formKey: string) => JSX.Element[];
  copyProductDetail: (key: string) => void;
  showFormError: (fieldKey: string, formKey?: string) => boolean;
}

const OrderFormProductDetails = ({
  valueChanged,
  formValues,
  getFormFields,
  copyProductDetail,
  showFormError,
}: IOrderFormProductDetails) => {
  const aidProductDetailSections = [
    {
      key: 'left',
      title: 'Left ear',
    },
    {
      key: 'right',
      title: 'Right ear',
    },
  ];

  return (
    <div className='bg-white w-full rounded-md shadow-md h-fit mt-4'>
      <div className='flex justify-between items-center p-4 border-b'>
        <p className='headline-06'>Product details</p>
        {showFormError('noProducts') && (
          <p className='label-01 text-red-600 text-right'>At least one product must be specified</p>
        )}
      </div>
      <div className='p-4 border-b'>
      {getFormFields(OrderProductDetailGeneralFormFields, 'productDetail')}
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2'>
        {aidProductDetailSections.map((section) => (
          <div key={section.key} className='border-b md:border-r md:border-b-0 last:border-0'>
            <div className='flex justify-between items-center p-4'>
              <p className='headline-06'>{section.title}</p>
              <Toggle
                size='large'
                onChange={() => valueChanged(section.key, 'required', !formValues[section.key].required)}
                isChecked={formValues[section.key].required}
              />
            </div>
            {formValues[section.key].required && (
              <div className='px-4 py-4 pt-0'>
                {getFormFields(OrderProductDetailFormFields, section.key)}
                <div className='mt-6'>
                  <SharedButton
                    onClick={() => copyProductDetail(section.key)}
                    type='button'
                    appearance='link'
                    spacing='none'
                    label={section.key === 'left' ? 'Copy to right ear' : 'Copy to left ear'}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderFormProductDetails;
