import { IAddress } from 'core/api/address/address-api-interface';
import {
  getAppointmentTypeColor,
  getAppointmentTypeNameFromKey,
  getAppointmentTypePin,
} from 'core/constants/appointment-type';
import { getClinicFromKey } from 'core/constants/clinic';
import { isShopAppointment } from 'core/utilities/appointment-helpers';
import { useCallback, useEffect, useState } from 'react';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import RouteMap from 'shared/components/route-map/route-map';

interface ITimelinePreviewDialogAppointment {
  address: IAddress;
  customerName: string;
  uid: string;
  type: string;
  start: string;
  end: string;
  clinic?: string;
}

interface ITimelinePreviewDialog {
  appointments: ITimelinePreviewDialogAppointment[];
  assigneeAddress: google.maps.LatLngLiteral;
}

interface ITimelineWaypoint {
  isHome: boolean;
  appointmentDetail?: ITimelinePreviewDialogAppointment;
  nextJourney?: google.maps.DirectionsLeg;
}

const TimelinePreviewDialog = ({ appointments, assigneeAddress }: ITimelinePreviewDialog) => {
  const [loading, setLoading] = useState(true);
  const [timeline, setTimeline] = useState<ITimelineWaypoint[]>();
  const [directionResult, setDirectionResult] = useState<google.maps.DirectionsResult>();

  const getAppointmentAddressLocation = (app: ITimelinePreviewDialogAppointment) => {
    if (isShopAppointment(app.type)) {
      const clinic = getClinicFromKey(app.clinic ?? '');
      return {
        location: {
          lat: clinic?.address.lat ?? 53.38065,
          lng: clinic?.address.lng ?? -6.18745,
        },
      };
    } else {
      return {
        location: {
          lat: app.address.latitude,
          lng: app.address.longitude,
        },
      };
    }
  };

  const generateGoogleDirections = useCallback(async () => {
    const directionsService = new google.maps.DirectionsService();
    const result = await directionsService.route({
      origin: assigneeAddress,
      destination: assigneeAddress,
      waypoints: appointments
        .sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          if (a.end < b.end) return -1;
          if (a.end > b.end) return 1;
          return 0;
        })
        .map((app) => getAppointmentAddressLocation(app)),
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionResult(result);
    const legs = result.routes[0].legs;

    setTimeline([
      { isHome: true, nextJourney: legs[0] },
      ...appointments.map((app, index) => ({
        isHome: false,
        appointmentDetail: app,
        nextJourney: legs[index + 1],
      })),
      { isHome: true },
    ]);
    setLoading(false);
  }, [appointments, assigneeAddress]);

  useEffect(() => {
    generateGoogleDirections();
  }, [appointments, assigneeAddress, generateGoogleDirections]);

  const getBackgroundColor = (stop: ITimelineWaypoint) => {
    if (stop.isHome || !stop.appointmentDetail) {
      return '#374151';
    }

    return getAppointmentTypeColor(stop.appointmentDetail.type);
  };

  const getAppointmentAddressName = (stop: ITimelineWaypoint) => {
    if (isShopAppointment(stop.appointmentDetail?.type!)) {
      const clinic = getClinicFromKey(stop.appointmentDetail?.clinic ?? '');
      return clinic?.address.fullAddress ?? 'Unknown';
    } else {
      return stop.appointmentDetail?.address.formatted_address;
    }
  };

  const customContent = () => {
    return loading ? (
      <p className='p-4'>Loading timeline...</p>
    ) : (
      <div className='overflow-y-auto'>
        <RouteMap
          directions={{
            result: directionResult,
            pins: appointments.map((appointment, index) => ({
              position: getAppointmentAddressLocation(appointment).location,
              key: appointment.uid,
              iconUrl: getAppointmentTypePin(appointment.type),
              labelText: (index + 1).toString(),
            })),
          }}
        />
        <div className='p-4'>
          <div className='relative'>
            <div className='absolute w-[6px] ml-[12px] h-full rounded-full bg-blue-50' />
            {timeline?.map((stop, index) => (
              <div key={index}>
                <div className='flex items-center'>
                  <div
                    className='h-[30px] flex-grow-0 flex-shrink-0 basis-[30px] rounded-full z-20 flex items-center justify-center text-white font-semibold'
                    style={{ backgroundColor: getBackgroundColor(stop) }}
                  >
                    {!stop.isHome && <p>{index}</p>}
                  </div>
                  <div
                    className='ml-4 w-full rounded-md p-2 text-white'
                    style={{ backgroundColor: getBackgroundColor(stop) }}
                  >
                    {stop.isHome && <p>Dispenser home</p>}
                    {stop.appointmentDetail && (
                      <div className='body-02'>
                        <div className='flex justify-between'>
                          <p className='font-semibold'>{stop.appointmentDetail.customerName}</p>
                          <p className='opacity-80'>
                            {stop.appointmentDetail.type === 'new'
                              ? 'New'
                              : getAppointmentTypeNameFromKey(stop.appointmentDetail.type)}
                          </p>
                        </div>
                        <p className='body-03'>
                          {stop.appointmentDetail.start} - {stop.appointmentDetail.end}
                        </p>
                        <p>{getAppointmentAddressName(stop)}</p>
                      </div>
                    )}
                  </div>
                </div>
                {stop.nextJourney && (
                  <div className='ml-12 py-4 body-02 opacity-80'>
                    {`Travel is ${stop.nextJourney.distance?.text} and takes ${stop.nextJourney.duration?.text}`}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return <SharedDialogBase title='Timeline' customContentTemplate={customContent()} />;
};

export default TimelinePreviewDialog;
