import { useFlags } from '@atlaskit/flag';
import { IOrder } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { AidGrantOption, getAidGrantOptionsNameFromKey } from 'core/constants/hearing-aid-grant-options';
import { getAidImpressionsRequiredOptionNameFromKey } from 'core/constants/hearing-aid-impressions-required-options';
import { getAidPowerOptionNameFromKey } from 'core/constants/hearing-aid-power-options';
import { getOrderStatusNameFromKey } from 'core/constants/order-status';
import { getPaymentMethodNameFromKey } from 'core/constants/payment-methods';
import { formatToEuro } from 'core/utilities/currency-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import OrderProductsBreakdown from '../order-products-breakdown/order-products-breakdown';
import { getBalancePaymentMethodNameFromKey } from 'core/constants/balance-payment-methods';

interface IOrderDetailsDialog {
  uid: string;
}

const OrderDetailsDialog = ({ uid }: IOrderDetailsDialog) => {
  // Hooks
  const [order, setOrder] = useState<IOrder>();
  const flags = useFlags();

  const getOrder = useCallback(async () => {
    try {
      const { data } = await OrdersApiService.get(uid);
      setOrder(data);
    } catch (error) {
      showErrorFlag('An error occurred', 'We were unable to fetch the order details, please try again', flags);
    }
  }, [flags, uid]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  const getOrderContent = (order: IOrder) => {
    const {
      dispenser,
      customer,
      power,
      impressionsRequired,
      left,
      right,
      grossPrice,
      discount,
      deposit,
      paymentMethod,
      totalPaid,
      grant,
      ppsn,
      additionalInformation,
      status,
      createdAt,
      createdBy,
      surveyor,
      updatedAt,
      updatedBy,
      booker,
      balancePaymentMethod,
    } = order;
    const { fullName, address, emailAddress, phoneNumber, dob } = customer;

    const sections = [
      {
        key: 'order',
        title: 'Order details',
        fields: [
          {
            label: 'Hearing advisor:',
            value: dispenser.fullName,
            key: 'advisorName',
          },
          {
            label: 'Status:',
            value: getOrderStatusNameFromKey(status),
            key: 'status',
          },
          {
            label: 'Created:',
            value: `${dayjs(createdAt.toDate()).format('DD/MM/YYYY, HH:mm')} by ${createdBy.fullName}`,
            key: 'created',
          },
          {
            label: 'Updated:',
            value: `${dayjs(updatedAt.toDate()).format('DD/MM/YYYY, HH:mm')} by ${updatedBy.fullName}`,
            key: 'updated',
          },
          {
            label: 'Surveyor:',
            value: surveyor ? surveyor.fullName : 'No surveyor',
            key: 'surveyor',
          },
          {
            label: 'Booker:',
            value: booker ? booker.fullName : 'No booker',
            key: 'booker',
          },
        ],
      },
      {
        key: 'customer',
        title: 'Customer details',
        fields: [
          {
            label: "Customer's name:",
            value: fullName,
            key: 'customerName',
          },
          {
            label: "Customer's email address:",
            value: isNotNullOrEmpty(emailAddress) ? emailAddress! : 'Not provided',
            key: 'emailAddress',
          },
          {
            label: "Customer's address:",
            value: [address?.line_1, address?.line_2, address?.town_or_city, address?.postcode].join('\n'),
            key: 'address',
          },
          {
            label: "Customer's telephone number",
            value: phoneNumber,
            key: 'telephone',
          },
        ],
      },
      {
        key: 'product',
        title: 'Product details',
        fields: [
          {
            label: 'Power:',
            value: getAidPowerOptionNameFromKey(power),
            key: 'power',
          },
          {
            label: 'Impressions required:',
            value: getAidImpressionsRequiredOptionNameFromKey(impressionsRequired),
            key: 'impressionsRequired',
          },
        ],
        custom: (
          <div className='mt-4'>
            <OrderProductsBreakdown left={left} right={right} />
          </div>
        ),
      },
      {
        key: 'financial',
        title: 'Financial details',
        fields: [
          {
            label: 'Grant:',
            value: getAidGrantOptionsNameFromKey(grant),
            key: 'grant',
          },
          {
            label: 'Goods/Service:',
            value: formatToEuro(grossPrice),
            key: 'goods',
          },
          {
            label: 'Grant/Discount:',
            value: formatToEuro(discount),
            key: 'discount',
          },
          {
            label: 'Total to pay:',
            value: formatToEuro(grossPrice - discount),
            key: 'total',
          },
          {
            label: 'Deposit paid:',
            value: formatToEuro(deposit),
            key: 'deposit',
          },
          {
            label: 'Deposit payment method:',
            value: getPaymentMethodNameFromKey(paymentMethod),
            key: 'paymentMethod',
          },
          {
            label: 'Total paid:',
            value: formatToEuro(totalPaid),
            key: 'totalPaid',
          },
          {
            label: 'Balance to pay:',
            value: formatToEuro(grossPrice - discount - totalPaid),
            key: 'balance',
          },
          {
            label: 'Balance payment method:',
            value: balancePaymentMethod ? getBalancePaymentMethodNameFromKey(balancePaymentMethod) : 'Unspecified',
            key: 'balancePaymentMethod',
          },
        ],
      },
      {
        key: 'declaration',
        title: 'Customer declaration',
        fields: [
          {
            label: 'Date of birth:',
            value: isNotNullOrEmpty(dob) ? dob! : 'Not provided',
            key: 'dob',
          },
        ],
      },
      {
        key: 'additional',
        title: 'Additional information',
        custom: (
          <p className='body-02'>
            {isNotNullOrEmpty(additionalInformation) ? additionalInformation : 'Not additional details entered'}
          </p>
        ),
      },
    ];

    if (grant !== AidGrantOption.NO_GRANT) {
      sections[4].fields?.push({
        label: 'PPSN number:',
        value: isNotNullOrEmpty(ppsn) ? ppsn! : 'Not provided',
        key: 'ppsn',
      });
    }

    return (
      <>
        {sections.map((section) => (
          <div key={section.key} className='border-b pb-5 mb-5 last:pb-0 last:mb-0 last:border-0'>
            <p className='headline-05 mb-4'>{section.title}</p>
            {section.fields?.map((field) => (
              <div className='grid grid-cols-2 body-02 mb-2 last:mb-0' key={field.key}>
                <p className='font-semibold'>{field.label}</p>
                <p className='text-right whitespace-pre-wrap break-words'>{field.value}</p>
              </div>
            ))}
            {section.custom}
          </div>
        ))}
      </>
    );
  };

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        {!order ? (
          <>
            <SkeletonElement className='mt-0' height='16px' width='75%' />
            <SkeletonElement className='mt-2' height='16px' width='40%' />
          </>
        ) : (
          getOrderContent(order)
        )}
      </div>
    );
  };

  return <SharedDialogBase title='Hearing aid order' customContentTemplate={customContent()} showButtons />;
};

export default OrderDetailsDialog;
