export const FirestoreCollectionIDs = {
  USERS: 'users',
  CUSTOMERS: 'customers',
  NOTES: 'notes',
  APPOINTMENTS: 'appointments',
  SURVEYS: 'surveys',
  DOCUMENTS: 'documents',
  ORDERS: 'orders',
  META: 'meta',
  AUDIOGRAM: 'audiogram',
  AUDIT_LOG: 'audit-log',
};
