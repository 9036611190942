export const AidImpressionsRequiredOption = {
  YES: 'yes',
  NO: 'no',
  TAKEN: 'taken',
};

export const AidImpressionsRequiredOptions = [
  {
    label: 'Yes',
    value: AidImpressionsRequiredOption.YES,
  },
  {
    label: 'No',
    value: AidImpressionsRequiredOption.NO,
  },
  {
    label: 'Taken',
    value: AidImpressionsRequiredOption.TAKEN,
  },
];

export const getAidImpressionsRequiredOptionFromKey = (key: string) => {
  return AidImpressionsRequiredOptions.find((option) => option.value === key);
};

export const getAidImpressionsRequiredOptionNameFromKey = (key: string) => {
  const option = AidImpressionsRequiredOptions.find((option) => option.value === key);
  return option?.label ?? 'Unknown';
};
