import { useFlags } from '@atlaskit/flag';
import { IAddCustomerRequestDto } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { CreateSurveyFormFields } from 'core/config/form-fields';
import { useDialog } from 'core/providers/DialogProvider';
import { useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import { ICreateSurveyFormOutput } from './create-survey-dialog-interface';
import { v4 as uuidv4 } from 'uuid';
import { CustomerStatus } from 'core/constants/customer-status';
import SurveysApiService from 'core/api/surveys/surveys-api.service';
import { IAddSurveyRequestDto } from 'core/api/surveys/surveys-api-interface';
import { useAuthState } from 'core/providers/AuthProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useLoadScript } from '@react-google-maps/api';
import { GoogleMapsLibraries } from 'core/constants/google-maps-libraries';
import { LeadType } from 'core/constants/lead-type';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import { IUser } from 'core/api/users/users-api-interface';
import { getObjectChanges } from 'core/utilities/object-helpers';

const CreateSurveyDialog = () => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: GoogleMapsLibraries,
  });

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();
  const { userData } = useAuthState();

  // Page specifics
  const submitForm = async (data: ICreateSurveyFormOutput) => {
    const { address, hearingProblems, length, affects, lastTest, additionalNotes, ...rest } = data;
    const { fullName, uid } = userData as IUser;
    setFormSubmitting(true);
    try {
      const date = new Date();
      const author = {
        fullName,
        uid,
      };
      const customerPayload: IAddCustomerRequestDto = {
        uid: uuidv4(),
        ...rest,
        address: address.fullAddress,
        title: data.title.value,
        status: CustomerStatus.CUSTOMER_CREATED,
        lastUpdated: date,
        createdAt: date,
        leadType: LeadType.SURVEY,
        statusUpdatedAt: date,
        statusUpdatedBy: author,
        createdBy: author,
      };
      const surveyPayload: IAddSurveyRequestDto = {
        uid: uuidv4(),
        customerUid: customerPayload.uid,
        surveyor: {
          uid: userData!.uid,
          fullName: userData!.fullName,
        },
        createdAt: new Date(),
        hearingProblems,
        length,
        affects,
        lastTest,
        additionalNotes,
      };
      const promises = [
        CustomersApiService.set(customerPayload, getObjectChanges(customerPayload, {})),
        SurveysApiService.set(surveyPayload, getObjectChanges(surveyPayload, {})),
      ];
      await Promise.all(promises);
      dialog?.closeDialog();
      showSuccessFlag('Success', `Survey successfully created for ${data.fullName}`, flags);
    } catch (error) {
      setFormSubmitting(false);
      showErrorFlag('An error occurred', `Survey could not be created for ${data.fullName}`, flags);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <>
        {isLoaded ? (
          <SharedForm
            className='p-4 overflow-y-auto'
            onSubmit={submitForm}
            fields={CreateSurveyFormFields}
            buttonLabel='Submit'
            loading={formSubmitting}
            cancelButton={cancelButton}
          />
        ) : (
          <div className='p-4'>
            {CreateSurveyFormFields.map((field) => (
              <SkeletonElement key={field.key} className='mt-6 first:mt-0' height='36px' width='100%' />
            ))}
          </div>
        )}
      </>
    );
  };

  return <SharedDialogBase title='New survey' customContentTemplate={customContent()} showButtons={false} />;
};

export default CreateSurveyDialog;
