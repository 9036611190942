import { useFlags } from '@atlaskit/flag';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { AppointmentType } from 'core/constants/appointment-type';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { USStringToUKString } from 'core/utilities/date-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedTable from 'shared/components/table/table';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import { IBookedTodayDialogTableRow } from './booked-today-dialog-interface';

const BookedTodayDialogTableColumns = [
  { label: 'Customer', key: 'customerName', templateId: 'text', width: 40 },
  { label: 'Assignee', key: 'assigneeName', templateId: 'text', width: 40 },
  { label: 'Date', key: 'date', templateId: 'text', width: 20 },
  { label: '', key: 'action', templateId: 'action', width: 20 },
];

const BookedTodayDialog = () => {
  const [appointments, setAppointments] = useState<IBookedTodayDialogTableRow[]>([]);
  const [loading, setLoading] = useState(false);

  // Hooks
  const flags = useFlags();
  const { userData } = useAuthState();
  const navigate = useNavigate();
  const dialog = useDialog();

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createAppointmentsSubscription = useCallback(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IAppointment>) => {
      const list: IBookedTodayDialogTableRow[] = [];
      querySnapshot.forEach((snap) => {
        const { uid, customer, assignee, date } = snap.data();
        list.push({
          uid,
          customerName: customer.fullName,
          assigneeName: assignee.fullName,
          date: USStringToUKString(date),
          customerUid: customer.uid,
        });
      });
      setAppointments(list);
      setLoading(false);
    };
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return AppointmentsApiService.subscribeToAppointments(handleSnapshot, handleSubscriptionError, {
      organiserUid: userData?.uid,
      afterDate: date,
      types: [AppointmentType.HOME_TEST, AppointmentType.SHOP_TEST],
    });
  }, [handleSubscriptionError, userData?.uid]);

  useEffect(() => {
    setLoading(true);
    let unsubscribe: Unsubscribe;
    unsubscribe = createAppointmentsSubscription();
    return () => {
      unsubscribe();
    };
  }, [createAppointmentsSubscription]);

  const actionCellTemplate = (row: IBookedTodayDialogTableRow) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton
          onClick={() => {
            navigate(`/customers/${row.customerUid}`);
            dialog?.closeDialog();
          }}
          type='button'
          appearance='link'
          label='View'
        />
      </div>
    );
  };

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: actionCellTemplate,
      id: 'action',
    },
  ];

  return (
    <SharedDialogBase
      title={`Appointments booked today: ${appointments.length}`}
      showButtons
      customContentTemplate={
        <SharedTable
          columns={BookedTodayDialogTableColumns}
          rows={appointments.map((app) => ({ key: app.uid, data: app }))}
          emptyText={'No appointments booked today'}
          isLoading={loading}
          customTemplates={customTemplates}
          cardWrapped={false}
        />
      }
    />
  );
};

export default BookedTodayDialog;
