import { IOrder } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { AidPowerOption } from 'core/constants/hearing-aid-power-options';
import { AidType, AidTypes } from 'core/constants/hearing-aid-types';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';
import { getRandomColor } from 'core/utilities/color-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import OrderReportBreakdownCustomerCell, {
  IOrderReportBreakdownCustomerCell,
} from '../order-report-breakdown-custom-cell/order-report-breakdown-custom-cell';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';

const OrderLeadTypeReport = () => {
  const processRows = useCallback(async (querySnapshot: QuerySnapshot<IOrder>) => {
    const rows: IReportBaseTableRows<IOrderReportBreakdownCustomerCell>[] = [];
    querySnapshot.forEach((doc) => {
      const { customer, left, right, grossPrice, power } = doc.data();
      const { leadType } = customer;
      const aids = [left, right].filter((ear) => ear);
      const aidType = left ? left.type : right!.type;
      const rowIndex = rows.findIndex((row) => row.key === leadType);
      const upgraded = aids.some((aid) => power === AidPowerOption.RECHARGEABLE || aid?.type !== AidType.ESSENTIA)
        ? 1
        : 0;
      const customerListRow = { customer };
      if (rowIndex !== -1) {
        rows[rowIndex].data.valueTotal += grossPrice;
        rows[rowIndex].data.count += 1;
        rows[rowIndex].data.upgrades += upgraded;
        const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === aidType);
        if (typeIndex !== -1) {
          rows[rowIndex].data.breakdowns[typeIndex].count += 1;
          rows[rowIndex].data.breakdowns[typeIndex].customerListRows.push(customerListRow);
        }
      } else {
        rows.push({
          key: leadType,
          color: getRandomColor(),
          data: {
            upgrades: upgraded,
            total: querySnapshot.docs.length,
            valueTotal: grossPrice,
            label: getLeadTypeNameFromKey(leadType),
            count: 1,
            breakdowns: AidTypes.map((type) => ({
              key: type.value,
              count: aidType === type.value ? 1 : 0,
              label: type.label,
              customerListRows: aidType === type.value ? [customerListRow] : [],
            })),
          },
        });
      }
    });
    return rows;
  }, []);

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: OrderReportBreakdownCustomerCell,
      id: 'leadType',
    },
  ];

  const tableColumns = [{ label: 'Lead Type', key: 'leadType', templateId: 'leadType', width: 100 }];

  return (
    <>
      <PageHeader title='Order lead type breakdown report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
        })}
        subscriber={OrdersApiService.subscribeToOrders}
        totalLabel={'Total number of orders created:'}
        emptyText={'No orders were created during the selected time frame'}
        customTemplates={customTemplates}
      />
    </>
  );
};

export default OrderLeadTypeReport;
