export const Dispenser = {
  J_COSTA: 'J.Costa',
};

export const Dispensers = [
  {
    label: 'J.Costa',
    value: Dispenser.J_COSTA,
  },
];

export const getDispenserFromKey = (key: string) => {
  return Dispensers.find((d) => d.value === key);
};

export const getDispenserNameFromKey = (key: string) => {
  const d = Dispensers.find((d) => d.value === key);
  return d?.label ?? 'Unknown';
};
