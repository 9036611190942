import { useDialog } from 'core/providers/DialogProvider';
import { formatToEuro } from 'core/utilities/currency-helpers';
import { decimalToPercentage } from 'core/utilities/number-helpers';
import React from 'react';
import SharedButton from 'shared/components/buttons/button';
import SharedCustomerListDialog from 'shared/components/dialogs/customer-list-dialog/customer-list-dialog';
import { IReportBreakdownCustomCell } from '../report-breakdown-custom-cell/report-breakdown-custom-cell';

export interface IOrderReportBreakdownCustomerCell extends IReportBreakdownCustomCell {
  valueTotal: number;
  total: number;
  upgrades: number;
}

const OrderReportBreakdownCustomerCell = (data: IOrderReportBreakdownCustomerCell) => {
  const { label, count, breakdowns, valueTotal, total, upgrades } = data;
  const breakdownsTotal = breakdowns.reduce((sum, b) => b.count + sum, 0);
  const dialog = useDialog();

  return (
    <div className='grid grid-cols-2 gap-x-4 gap-y-1'>
      <p className='body-01 mb-4'>{label}</p>
      <p className='body-01 mb-4'>{`${count} orders (${decimalToPercentage(count / total)})`}</p>
      {breakdowns
        .filter((breakdown) => breakdown.count !== 0)
        .map((breakdown) => (
          <React.Fragment key={breakdown.key}>
            <p>{breakdown.label}</p>
            <div>
              <SharedButton
                onClick={() => dialog?.openDialog(<SharedCustomerListDialog rows={breakdown.customerListRows!} />)}
                type='button'
                spacing='none'
                appearance='link'
                label={`${breakdown.count} (${decimalToPercentage(breakdown.count / breakdownsTotal)})`}
              />
            </div>
          </React.Fragment>
        ))}
      <p className='mt-4'>Total value</p>
      <p className='mt-4'>{formatToEuro(valueTotal)}</p>
      <p>Upgraded</p>
      <p>{decimalToPercentage(upgrades / breakdownsTotal)}</p>
    </div>
  );
};

export default OrderReportBreakdownCustomerCell;
