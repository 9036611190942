import { useFlags } from '@atlaskit/flag';
import ProgressBar from '@atlaskit/progress-bar';
import { IAppointment, IAppointmentQueryOptions } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { IOrder, IOrdersQueryOptions } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { AppointmentStatus } from 'core/constants/appointment-status';
import { AppointmentType } from 'core/constants/appointment-type';
import { CertificateStatuses, getCertificateStatusNameFromKey } from 'core/constants/certificate-status';
import { getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import { AidGrantOption, getAidGrantOptionsNameFromKey } from 'core/constants/hearing-aid-grant-options';
import { OrderStatus } from 'core/constants/order-status';
import { FilterType } from 'core/enums/filter-type';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { UsersSlice } from 'modules/users/users-slice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ParamKeyValuePair, useNavigate, useSearchParams } from 'react-router-dom';
import SharedFilters, { ISharedFilterField } from 'shared/components/filters/filters';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import PageHeader from 'shared/components/page-header/page-header';
import UploadCounter from './upload-counter/upload-counter';

interface IAppointmentWithOrder {
  appointment: IAppointment;
  order?: IOrder;
}

interface IAppointmentsWithOrderByStatus {
  [key: string]: IAppointmentWithOrder[];
}

const WelfareDashboard = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<StringIndexable>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [allOrders, setAllOrders] = useState<IAppointmentWithOrder[]>([]);
  const users = useSelector(UsersSlice.selectAll);
  const dispensers = users.filter((user) => user.roles.includes('hearingSpecialist'));
  const [ordersByStatus, setOrdersByStatus] = useState<IAppointmentsWithOrderByStatus>();
  const hasDateRelatedFilter = [
    ['fittingDateFrom', 'fittingDateTo'],
    ['uploadedDateFrom', 'uploadedDateTo'],
  ].some((fieldKeys) => fieldKeys.every((key) => searchParams.has(key)));
  const [loading, setLoading] = useState(false);

  const dispenserSelection = dispensers.map((d) => ({
    value: d.uid,
    label: d.fullName,
  }));

  const filters: ISharedFilterField[] = [
    {
      label: 'Dispenser',
      key: 'selectedDispenser',
      filterType: FilterType.Select,
      options: dispenserSelection,
    },
    {
      label: 'Fitting date from',
      key: 'fittingDateFrom',
      filterType: FilterType.DatePicker,
    },
    {
      label: 'Fitting date to',
      key: 'fittingDateTo',
      filterType: FilterType.DatePicker,
    },
  ];

  const statuses = useMemo(() => CertificateStatuses.map((status) => status.value), []);

  const handleSubscriptionError = useCallback(
    (error: any) => {
      console.log(error);
      showErrorFlag('An error occurred', 'Orders could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createAppointmentSubscription = useCallback(() => {
    setLoading(true);
    const handleSnapshot = async (querySnapshot: QuerySnapshot<IAppointment>) => {
      const queries: IOrdersQueryOptions = {
        certificateStatuses: statuses,
      };

      if (filterValues.selectedDispenser) {
        queries.dispenser = filterValues.selectedDispenser;
      }

      const promises: Promise<IAppointmentWithOrder>[] = querySnapshot.docs.map((snap) => {
        const data = snap.data();
        queries.customerId = data.customer.uid;
        return new Promise(async (resolve) => {
          const orders = await OrdersApiService.listAll(queries, ['createdAt']);
          if (orders.empty) {
            resolve({ appointment: data, order: undefined });
          } else {
            resolve({ appointment: data, order: orders.docs[0].data() });
          }
        });
      });

      const results = await Promise.all(promises);
      setAllOrders(results);
    };

    const queries: IAppointmentQueryOptions = {
      status: AppointmentStatus.SCHEDULED,
      types: [AppointmentType.FITTING, AppointmentType.SHOP_FITTING, AppointmentType.WARRANTY_FITTING],
    };

    let orderKey = 'fullDate';

    Object.keys(filterValues)
      .filter((key) => isNotNullOrEmpty(filterValues[key]))
      .forEach((key) => {
        const value = filterValues[key];
        if (!value) {
          return true;
        }
        switch (key) {
          case 'fittingDateFrom':
            queries.afterAppointmentDate = dayjs(value).toDate();
            break;
          case 'fittingDateTo':
            queries.beforeAppointmentDate = dayjs(value).set('hour', 23).set('minute', 59).toDate();
            break;
          case 'selectedDispenser':
            queries.assigneeUid = value;
        }
      });
    return AppointmentsApiService.subscribeToAppointments(handleSnapshot, handleSubscriptionError, queries, [orderKey]);
  }, [filterValues, handleSubscriptionError, statuses]);

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value };
      });
    });
  }, [searchParams]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (hasDateRelatedFilter) {
      unsubscribe = createAppointmentSubscription();
    } else {
      setAllOrders([]);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [createAppointmentSubscription, hasDateRelatedFilter]);

  useEffect(() => {
    if (!hasDateRelatedFilter) {
      const today = new Date();
      const thirtyDaysAgo = dayjs(today).add(-30, 'days').format('YYYY-MM-DD');
      setSearchParams({
        fittingDateFrom: thirtyDaysAgo,
        fittingDateTo: dayjs(today).format('YYYY-MM-DD'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ordersByStatus: IAppointmentsWithOrderByStatus = {};
    allOrders
      .filter(({ order }) => order && order.certificate && order.status !== OrderStatus.CANCELLED)
      .forEach((appointmentWithOrder) => {
        const currentStatusData = ordersByStatus[appointmentWithOrder.order!.certificate!.status];
        if (currentStatusData) {
          currentStatusData.push(appointmentWithOrder);
        } else {
          ordersByStatus[appointmentWithOrder.order!.certificate!.status] = [appointmentWithOrder];
        }
      });
    setOrdersByStatus(ordersByStatus);
    setLoading(false);
  }, [allOrders, filterValues]);

  const paramChanged = (key: string, value: string) => {
    if (!value) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, value]);
      setSearchParams(newValues);
    }
  };

  const getColumnContent = (status: string) => {
    if (!ordersByStatus) {
      return <SkeletonElement width='100%' height='105px' />;
    }

    const columnOrders = ordersByStatus[status];

    if (!columnOrders) {
      return <></>;
    }

    return columnOrders.map(({ appointment, order }) => {
      const cardDetails = [
        {
          key: 'fittingDate',
          label: 'Fitting date',
          value: dayjs(appointment.date).format('DD/MM/YYYY'),
        },
        {
          key: 'orderDate',
          label: 'Order date',
          value: dayjs(order?.createdAt.toDate()).format('DD/MM/YYYY'),
        },
        {
          key: 'customerStatus',
          label: 'Customer status',
          value: getCustomerStatusNameFromKey(order?.customer.status ?? ''),
        },
        {
          key: 'dispenser',
          label: 'Dispenser',
          value: order?.dispenser.fullName,
        },
      ];

      if (order?.grant !== AidGrantOption.NO_GRANT) {
        cardDetails.splice(2, 0, {
          key: 'grantType',
          label: 'Grant type',
          value: getAidGrantOptionsNameFromKey(order?.grant ?? ''),
        });
      }

      if (order?.certificate?.uploadedAt) {
        cardDetails.push({
          key: 'uploadedAt',
          label: 'Uploaded',
          value: dayjs(order.certificate.uploadedAt.toDate()).format('DD/MM/YYYY'),
        });
      }

      return (
        <>
          {!order ? (
            <></>
          ) : (
            <div
              key={order.uid}
              className='p-2 shadow-sm rounded-md bg-blue-50 mb-2 last:mb-0 body-02 cursor-pointer hover:bg-blue-100 transition ease-in-out'
              onClick={() => navigate(`/customers/${order.customer.uid}?tab=orders`)}
            >
              <p className='font-semibold'>{order.customer.fullName}</p>
              <p className='body-03 mb-2'>
                {order.customer.address ? order.customer.address.formatted_address : 'Unknown'}
              </p>
              <div className='grid grid-cols-2 gap-y-3 border-t border-blue-700 border-opacity-10 pt-2'>
                {cardDetails.map((detail) => (
                  <div key={detail.key}>
                    <p className='font-semibold text-xs opacity-90'>{detail.label}</p>
                    <p className='body-03'>{detail.value}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      );
    });
  };

  return (
    <>
      <PageHeader title='Welfare Dashboard' />
      <UploadCounter />
      <div className='z-20 border-t border-gray-300 mt-4'>
        <SharedFilters
          filterInputs={filters}
          filterValues={filterValues}
          changeHandler={paramChanged}
          gridStyles='grid-cols-3 gap-6'
        />
      </div>
      {hasDateRelatedFilter ? (
        <div className='bg-white rounded-md shadow-md h-full overflow-y-hidden'>
          {loading && <ProgressBar ariaLabel='Loading appointments' isIndeterminate />}
          <div className='flex overflow-x-auto h-full'>
            {statuses.map((status) => (
              <div
                className='flex-grow min-w-[260px] basis-[260px] border-r last:border-r-0 overflow-y-auto hide-scrollbar'
                key={status}
              >
                <div className='h-[60px] p-2 flex flex-col justify-between border-b sticky top-0 bg-white z-10 overflow-x-auto break-keep whitespace-nowrap hide-scrollbar'>
                  <p className='body-02 font-semibold capitalize'>{getCertificateStatusNameFromKey(status)}</p>
                  <p className='body-03'>
                    {ordersByStatus ? `${ordersByStatus[status] ? ordersByStatus[status].length : 0}` : ''}
                  </p>
                </div>
                <div className='p-2'>{getColumnContent(status)}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='bg-white rounded-md shadow-md p-4 flex flex-col items-center justify-center text-sm'>
          <p>You must specify fitting date filter</p>
        </div>
      )}
    </>
  );
};

export default WelfareDashboard;
