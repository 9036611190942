import { firestore } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { collection, doc, orderBy, query, QueryConstraint, QuerySnapshot, where } from 'firebase/firestore';
import ApiService from '../api.service';
import {
  IAddOrderRequestDto,
  ICreateSalesOrderRequestDto,
  IOrder,
  IOrdersQueryOptions,
  ISendOrderConfirmationEmailRequestDto,
  IUpdateOrderRequestDto,
} from './orders-api-interface';
import AuditLogApiService from '../audit-log/audit-log-api.service';
import { IAuditLogChange } from '../audit-log/audit-log-api-interface';

const ordersQueryList: StringIndexable = {
  customerId: { property: 'customer.uid', operator: '==' },
  afterDate: { property: 'createdAt', operator: '>' },
  beforeDate: { property: 'createdAt', operator: '<' },
  uploadedAfterDate: { property: 'certificate.uploadedAt', operator: '>' },
  uploadedBeforeDate: { property: 'certificate.uploadedAt', operator: '<' },
  linkedAppointmentUid: { property: 'linkedAppointmentUid', operator: '==' },
  customerStatuses: { property: 'customer.status', operator: 'in' },
  dispenser: { property: 'dispenser.uid', operator: '==' },
  certificateStatuses: { property: 'certificate.status', operator: 'in' },
};

const collectionRef = collection(firestore, FirestoreCollectionIDs.ORDERS);

const subscribeToOrders = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: IOrdersQueryOptions,
  ordering?: string[]
) => {
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = ordersQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }
  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const listAll = (queryOptions?: IOrdersQueryOptions, ordering?: string[]): Promise<QuerySnapshot<IOrder>> => {
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = ordersQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.listAll<IOrder>(q);
};

const set = async (dto: IAddOrderRequestDto, changes: IAuditLogChange[]) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.ORDERS, dto.uid);
  await ApiService.set(documentRef, dto);
  return AuditLogApiService.set({
    resourceUid: dto.uid,
    resourceType: 'order',
    actionType: 'create',
    author: dto.createdBy,
    customerUid: dto.customer.uid,
    changes,
  });
};

const generateSalesOrder = (dto: ICreateSalesOrderRequestDto) =>
  ApiService.callFunction<string>(dto, FirebaseFunctionNames.GENERATE_SALES_ORDER);

const sendOrderConfirmationEmail = async (dto: ISendOrderConfirmationEmailRequestDto) =>
  ApiService.callFunction(dto, FirebaseFunctionNames.SEND_ORDER_CONFIRMATION_EMAIL);

const get = async (uid: string) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.ORDERS, uid);
  return ApiService.getData<IOrder>(documentRef);
};

const update = async (uid: string, customerUid: string, dto: IUpdateOrderRequestDto, changes: IAuditLogChange[]) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.ORDERS, uid);
  await ApiService.update(documentRef, dto);
  return AuditLogApiService.set({
    resourceUid: uid,
    resourceType: 'order',
    actionType: 'update',
    author: dto.updatedBy,
    customerUid: customerUid,
    changes,
  });
};

const OrdersApiService = {
  subscribeToOrders,
  set,
  generateSalesOrder,
  sendOrderConfirmationEmail,
  get,
  update,
  listAll,
};

export default OrdersApiService;
