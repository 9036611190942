export const AidReceiverGain = {
  FORTY: '40',
  FIFTY: '50',
  SIXTY: '60',
  SEVENTY: '70',
  L: 'L',
  M: 'M',
  P: 'P',
  UP: 'UP',
  NA: 'N/A',
};

export const AidReceiverGains = [
  {
    label: '40',
    value: AidReceiverGain.FORTY,
  },
  {
    label: '50',
    value: AidReceiverGain.FIFTY,
  },
  {
    label: '60',
    value: AidReceiverGain.SIXTY,
  },
  {
    label: '70',
    value: AidReceiverGain.SEVENTY,
  },
  {
    label: 'L',
    value: AidReceiverGain.L,
  },
  {
    label: 'M',
    value: AidReceiverGain.M,
  },
  {
    label: 'P',
    value: AidReceiverGain.P,
  },
  {
    label: 'UP',
    value: AidReceiverGain.UP,
  },
  {
    label: 'N/A',
    value: AidReceiverGain.NA,
  },
];

export const getAidReceiverGainFromKey = (key: string) => {
  return AidReceiverGains.find((gain) => gain.value === key);
};

export const getAidReceiverGainNameFromKey = (key: string) => {
  const gain = AidReceiverGains.find((gain) => gain.value === key);
  return gain?.label ?? 'Unknown';
};
