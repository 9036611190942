export const Meatus = {
  CLEAR: 'clear',
  SOME_WAX: 'someWax',
  BLOCKED: 'blocked',
};

export const MeatusOptions = [
  {
    label: 'Clear',
    value: Meatus.CLEAR,
  },
  {
    label: 'Some wax',
    value: Meatus.SOME_WAX,
  },
  {
    label: 'Blocked',
    value: Meatus.BLOCKED,
  },
];

export const getMeatusFromKey = (key: string) => {
  return MeatusOptions.find((p) => p.value === key);
};

export const getMeatusNameFromKey = (key: string) => {
  const p = MeatusOptions.find((p) => p.value === key);
  return p?.label ?? 'Unknown';
};
