import { ICustomer, IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';

interface ISharedCustomerListDialog {
  rows: ISharedCustomerListDialogRow[];
}

export interface ISharedCustomerListDialogRow {
  metaElement?: ReactElement;
  customer: IStoredCustomer | ICustomer;
}

const SharedCustomerListDialog = ({ rows }: ISharedCustomerListDialog) => {
  const navigate = useNavigate();
  const dialog = useDialog();

  const customContent = () => {
    return (
      <div className='overflow-y-auto'>
        {rows.map((row) => (
          <div className='p-4 border-b last:border-0'>
            {row.metaElement}
            <div className='flex body-02 space-x-1 mt-1'>
              {row.metaElement && <p>Customer:</p>}
              <SharedButton
                onClick={() => {
                  dialog?.closeDialog();
                  navigate(`/customers/${row.customer.uid}`);
                }}
                type='button'
                appearance='link'
                label={row.customer.fullName}
                spacing='none'
              />
            </div>
          </div>
        ))}
        {rows.length === 0 && <p className='p-4 body-02'>No customers</p>}
      </div>
    );
  };

  return <SharedDialogBase title='Details' customContentTemplate={customContent()} />;
};

export default SharedCustomerListDialog;
