import { useFlags } from '@atlaskit/flag';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import { ICustomerMeta } from 'core/api/meta/meta-api-interface';
import MetaApiService from 'core/api/meta/meta-api.service';
import { CustomerStatuses, getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import { FilterType } from 'core/enums/filter-type';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { PageElement, StringIndexable } from 'core/utilities/interface-helpers';
import { hasRole } from 'core/utilities/permission-helpers';
import { useCallback, useEffect, useState } from 'react';
import { ParamKeyValuePair, useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedFilters from 'shared/components/filters/filters';
import PageHeader from 'shared/components/page-header/page-header';
import SharedTable from 'shared/components/table/table';
import { ISharedTableColumn, ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import AddCustomerDialog from '../add-customer/add-edit-customer-dialog';
import CustomerStatusBreakdownDialog from '../customer-status-breakdown-dialog/customer-status-breakdown-dialog';
import DeleteCustomersDialog from '../delete-customers-dialog/delete-customers-dialog';
import ExportCustomersDialog from './export-customers-dialog/export-customers-dialog';
import { createAddressString } from 'core/utilities/address-string-builder';
import { QuerySnapshot } from 'firebase/firestore';
import CustomersApiService from 'core/api/customers/customers-api.service';
import dayjs from 'dayjs';
import { secondsToDateString } from 'core/utilities/date-helpers';

const CustomersTableColumns: ISharedTableColumn[] = [
  { label: 'Name', key: 'fullName', templateId: 'text', width: 20 },
  { label: 'Address', key: 'address', templateId: 'address', width: 50 },
  { label: 'Status', key: 'status', templateId: 'text', width: 15 },
  { label: 'Last Updated', key: 'lastUpdated', templateId: 'lastUpdated', width: 15 },
  { label: '', key: 'action', templateId: 'action', width: 10 },
];

const filters = [
  {
    label: 'Created date from',
    key: 'createdDateFrom',
    filterType: FilterType.DatePicker,
  },
  {
    label: 'Created date to',
    key: 'createdDateTo',
    filterType: FilterType.DatePicker,
  },
  {
    label: 'Customer status',
    key: 'status',
    options: CustomerStatuses.map((status) => ({ value: status.key, label: status.label })),
    filterType: FilterType.Select,
  },
];

const CustomersList = () => {
  // Hooks
  const dialog = useDialog();
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<StringIndexable>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerMeta, setCustomerMeta] = useState<ICustomerMeta>();
  const flags = useFlags();
  const { userRoles } = useAuthState();
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const canViewMeta = hasRole(['superAdmin', 'admin'], userRoles);

  const getCustomerMeta = useCallback(async () => {
    try {
    } catch (error) {
      showErrorFlag('An error occurred', 'Could not retrieve application meta data, please try again.', flags);
    }
    const { data } = await MetaApiService.get<ICustomerMeta>('customer');
    setCustomerMeta(data);
  }, [flags]);

  useEffect(() => {
    if (canViewMeta) {
      getCustomerMeta();
    }
  }, [canViewMeta, getCustomerMeta]);

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value };
      });
    });
  }, [searchParams]);

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Customers could not be retrieved, please try again.', flags);
      console.log(error);
    },
    [flags]
  );

  const createCustomerSubscription = useCallback(
    (filters: StringIndexable) => {
      const handleSnapshot = (querySnapshot: QuerySnapshot<ICustomer>) => {
        const customers: ICustomer[] = [];
        querySnapshot.forEach((snap) => {
          const data = snap.data();
          customers.push(data);
        });
        customers.sort((a, b) => (b.lastUpdated?.seconds ?? 1) - (a.lastUpdated?.seconds ?? 1));
        setCustomers(customers);
      };

      return CustomersApiService.subscribeToCustomers(handleSnapshot, handleSubscriptionError, filters);
    },
    [handleSubscriptionError]
  );

  useEffect(() => {
    if (!filterValues.status) {
      setCustomers([]);
      return;
    }

    const queries: StringIndexable = {};
    Object.entries(filterValues).forEach(([key, value]) => {
      switch (key) {
        case 'createdDateFrom':
          queries.afterDate = dayjs(value).toDate();
          break;
        case 'createdDateTo':
          queries.beforeDate = dayjs(value).set('hour', 23).set('minute', 59).toDate();
          break;
        default:
          queries[key] = value;
      }
    });
    createCustomerSubscription(queries);
  }, [createCustomerSubscription, filterValues]);

  // Page specifics
  const addressCellTemplate = (customerData: ICustomer) => {
    if (customerData.address) {
      const { line_1, town_or_city, postcode } = customerData.address;
      return createAddressString([line_1, town_or_city, postcode]);
    }

    return customerData.mvfCounty ?? 'Check notes';
  };
  const lastUpdatedCellTemplate = (customerData: ICustomer) => {
    return secondsToDateString(customerData.lastUpdated?.seconds ?? 0);
  };
  const actionCellTemplate = (customer: ICustomer) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton onClick={() => navigate(customer.uid)} type='button' appearance='link' label='View' />
        <SharedButton
          onClick={() => dialog?.openDialog(<DeleteCustomersDialog customers={[customer.uid]} />)}
          type='button'
          appearance='link'
          label='Delete'
        />
      </div>
    );
  };
  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: addressCellTemplate,
      id: 'address',
    },
    {
      template: lastUpdatedCellTemplate,
      id: 'lastUpdated',
    },
    {
      template: actionCellTemplate,
      id: 'action',
    },
  ];
  const headerActions: PageElement[] = [
    ...(hasRole(['superAdmin'], userRoles)
      ? [
          {
            key: 'exportCustomerData',
            element: (
              <SharedButton
                onClick={() => dialog?.openDialog(<ExportCustomersDialog />)}
                type='button'
                appearance='default'
                label='Export customers'
              />
            ),
          },
        ]
      : []),
    {
      key: 'addCustomer',
      element: (
        <SharedButton
          onClick={() => dialog?.openDialog(<AddCustomerDialog />)}
          type='button'
          appearance='primary'
          label='Add Customer'
        />
      ),
    },
  ];

  const getCustomerTotal = (customerMeta: ICustomerMeta) => Object.values(customerMeta).reduce((sum, b) => b + sum, 0);

  const paramChanged = (key: string, value: string) => {
    if (!value) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, value]);
      setSearchParams(newValues);
    }
  };

  return (
    <div>
      <PageHeader title='Customers' actions={headerActions} />
      {canViewMeta && customerMeta && (
        <div className='grid grid-cols-1 md:flex border-b body-02 mb-4'>
          <button
            onClick={() => dialog?.openDialog(<CustomerStatusBreakdownDialog meta={customerMeta} />)}
            className='text-left py-4 md:mr-4 last:mr-0 pr-4 last:pr-0 border-0 border-b md:border-b-0 last:border-0 md:border-r'
          >
            Total customers: <span className='font-semibold'>{getCustomerTotal(customerMeta)}</span>
          </button>
        </div>
      )}
      <SharedFilters
        gridStyles='grid-cols-1 md:grids-col-2 lg:grid-cols-4 gap-6'
        filterInputs={filters}
        filterValues={filterValues}
        changeHandler={paramChanged}
      />
      {!filterValues.status ? (
        <div className='rounded-md bg-white shadow-md p-4 body-02 text-center'>
          Add a customer status filter to search for customers
        </div>
      ) : (
        <SharedTable
          columns={CustomersTableColumns}
          rows={customers.map((customer) => ({
            key: customer.uid,
            data: { ...customer, status: getCustomerStatusNameFromKey(customer.status) },
          }))}
          customTemplates={customTemplates}
        />
      )}
    </div>
  );
};

export default CustomersList;
