import { useFlags } from '@atlaskit/flag';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { IUser } from 'core/api/users/users-api-interface';
import { CancelAppointmentDialogFormFields } from 'core/config/form-fields';
import { AppointmentStatus } from 'core/constants/appointment-status';
import { AppointmentType } from 'core/constants/appointment-type';
import { CustomerStatus, CustomerStatuses } from 'core/constants/customer-status';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { SelectOption } from 'core/utilities/interface-helpers';
import { getObjectChanges } from 'core/utilities/object-helpers';
import { useEffect, useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import { ISharedFormField } from 'shared/components/form/form-interface';

interface ICancelAppointmentDialog {
  appointment: IAppointment;
}

interface ICancelAppointmentDialogFormOutput {
  status: SelectOption;
}

const CancelAppointmentDialog = ({ appointment }: ICancelAppointmentDialog) => {
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState<ISharedFormField[]>(CancelAppointmentDialogFormFields);

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const { userData } = useAuthState();

  const mapStatusesToSelectOption = (keys: string[]) =>
    CustomerStatuses.filter((status) => keys.some((key) => key === status.key)).map((status) => ({
      value: status.key,
      label: status.label,
    }));

  useEffect(() => {
    let options: SelectOption[] = [];
    switch (appointment.type) {
      case AppointmentType.FITTING:
      case AppointmentType.SHOP_FITTING:
      case AppointmentType.WARRANTY_FITTING:
        options = mapStatusesToSelectOption([
          CustomerStatus.FITTING_CANCELLED,
          CustomerStatus.FITTING_CANCELLED_REARRANGE,
        ]);
        break;
      case AppointmentType.SERVICE:
      case AppointmentType.SHOP_SERVICE:
      case AppointmentType.TELE_HEAR:
        options = mapStatusesToSelectOption([
          CustomerStatus.SERVICE_CANCELLED,
          CustomerStatus.SERVICE_CANCELLED_REARRANGE,
        ]);
        break;
      case AppointmentType.IMPRESSIONS:
        options = mapStatusesToSelectOption([
          CustomerStatus.IMPRESSIONS_CANCELLED,
          CustomerStatus.IMPRESSIONS_CANCELLED_REARRANGE,
        ]);
        break;
      default:
        options = mapStatusesToSelectOption([CustomerStatus.TEST_CANCELLED, CustomerStatus.TEST_CANCELLED_REARRANGE]);
    }
    setFormFields(CancelAppointmentDialogFormFields.map((field) => ({ ...field, options })));
  }, [appointment.type]);

  const cancelButton: IButton = {
    onClick: () => {
      dialog?.closeDialog();
    },
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const cancelAppointment = async (data: ICancelAppointmentDialogFormOutput) => {
    setLoading(true);
    try {
      const { fullName, uid } = userData as IUser;
      const author = { fullName, uid };
      const payload = {
        status: AppointmentStatus.CANCELLED,
      };
      const p1 = AppointmentsApiService.update(
        appointment.uid,
        appointment.customer.uid,
        {
          fullName: userData!.fullName,
          uid: userData!.uid,
        },
        payload,
        getObjectChanges(payload, appointment)
      );
      const customerPayload = {
        status: data.status.value,
        statusUpdatedBy: author,
      };
      const p2 = CustomersApiService.update(
        appointment.customer.uid,
        author,
        customerPayload,
        getObjectChanges(customerPayload, appointment.customer)
      );
      await Promise.all([p1, p2]);
      setLoading(false);
      dialog?.closeDialog();
      showSuccessFlag('Appointment cancelled', 'The appointment has been successfully cancelled', flags);
    } catch (error) {
      setLoading(false);
      showErrorFlag('Cancel failed', 'The appointment could not be cancelled, please try again', flags);
    }
  };

  const customContent = () => {
    return (
      <div className='p-4'>
        <p className='body-02 mb-4'>Please select the status to be used to update the customer</p>
        <SharedForm
          onSubmit={cancelAppointment}
          fields={formFields}
          buttonLabel='Confirm'
          cancelButton={cancelButton}
          loading={loading}
        />
      </div>
    );
  };

  return <SharedDialogBase title='Cancel appointment' customContentTemplate={customContent()} showButtons={false} />;
};

export default CancelAppointmentDialog;
