import { useFlags } from '@atlaskit/flag';
import { FilterType } from 'core/enums/filter-type';
import { todayAsUSString } from 'core/utilities/date-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { StringIndexable } from 'core/utilities/interface-helpers';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { AlertCircle } from 'react-feather';
import SharedFilters, { ISharedFilterField } from 'shared/components/filters/filters';
import SharedTable from 'shared/components/table/table';
import { ISharedTableColumn, ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';

export interface IReportBaseFilterValues extends StringIndexable {
  dateFrom?: string;
  dateTo?: string;
}

export interface IReportBaseTableRows<T> {
  key: string;
  color: string;
  data: T;
}

interface IReportBase<T, U, V> {
  tableColumns: ISharedTableColumn[];
  processRows: (querySnapshot: QuerySnapshot<T>) => Promise<IReportBaseTableRows<V>[]>;
  getQueryOptions: (before: Date, after: Date) => U;
  queryOrdering?: string[];
  subscriber: (
    subscriptionHandler: Function,
    errorHandler: Function,
    queryOptions?: U | undefined,
    ordering?: string[] | undefined
  ) => Unsubscribe;
  totalLabel: string;
  customTemplates?: ISharedTableCustomCellTemplate[];
  emptyText: string;
}

const ReportBase = <T, U, V extends StringIndexable>({
  tableColumns,
  processRows,
  getQueryOptions,
  subscriber,
  totalLabel,
  customTemplates,
  emptyText,
  queryOrdering,
}: IReportBase<T, U, V>) => {
  const [filterValues, setFilterValues] = useState<IReportBaseFilterValues>({});
  const [tableRows, setTableRows] = useState<IReportBaseTableRows<V>[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const flags = useFlags();

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Could not generate the report due to an error, please try again.', flags);
    },
    [flags]
  );

  const handleSnapshot = useCallback(
    async (querySnapshot: QuerySnapshot<T>) => {
      setTotal(querySnapshot.docs.length);
      try {
        const rows = await processRows(querySnapshot);
        setTableRows(rows);
      } catch (error) {
        showErrorFlag('An error occurred', 'Could not generate the report due to an error, please try again.', flags);
      }
      setLoading(false);
    },
    [flags, processRows]
  );

  useEffect(() => {
    const { dateTo, dateFrom } = filterValues;
    if (!dateTo || !dateFrom) {
      return;
    }
    const beforeDate = dayjs(dateTo).set('hour', 23).set('minute', 59).toDate();
    const afterDate = dayjs(dateFrom).toDate();
    const queryOptions = getQueryOptions(beforeDate, afterDate);
    let unsubscribe: Unsubscribe;
    unsubscribe = subscriber(handleSnapshot, handleSubscriptionError, queryOptions, queryOrdering);
    return () => {
      unsubscribe();
    };
  }, [filterValues, getQueryOptions, handleSnapshot, handleSubscriptionError, queryOrdering, subscriber]);

  const handleFilterChange = (key: string, value: string) => {
    setFilterValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const filterFields: ISharedFilterField[] = [
    {
      label: 'Date From',
      key: 'dateFrom',
      filterType: FilterType.DatePicker,
      defaultValue: todayAsUSString(),
      maxDate: filterValues.dateTo ?? '',
    },
    {
      label: 'Date To',
      key: 'dateTo',
      filterType: FilterType.DatePicker,
      defaultValue: todayAsUSString(),
      minDate: filterValues.dateFrom ?? '',
    },
  ];

  const doughnutData = {
    labels: tableRows.map((row) => row.data.label),
    datasets: [
      {
        id: 1,
        data: tableRows.map((row) => row.data.count),
        backgroundColor: tableRows.map((row) => row.color),
      },
    ],
  };

  return (
    <>
      <SharedFilters
        startOpen
        filterInputs={filterFields}
        filterValues={filterValues}
        changeHandler={handleFilterChange}
        gridStyles='grid-cols-1 md:grids-col-2 lg:grid-cols-4 gap-6'
      />
      {!filterValues.dateFrom || !filterValues.dateTo ? (
        <div className='bg-white w-full rounded-md shadow-md overflow-x-auto'>
          {(!filterValues.dateFrom || !filterValues.dateTo) && (
            <div className='p-4 flex flex-col items-center'>
              <AlertCircle />
              <p className='mt-2'>Please select a valid date range to view this report</p>
            </div>
          )}
        </div>
      ) : (
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
          <div className='flex pt-3 body-02 lg:col-span-3 w-full'>
            <p>
              {totalLabel} <span className='font-semibold'>{total}</span>
            </p>
          </div>
          <div className='lg:col-span-2'>
            <SharedTable
              columns={tableColumns}
              rows={tableRows}
              emptyText={emptyText}
              isLoading={loading}
              customTemplates={customTemplates}
            />
          </div>
          <div className='bg-white w-full rounded-md shadow-md p-6 h-fit'>
            <Doughnut data={doughnutData} />
          </div>
        </div>
      )}
    </>
  );
};

export default ReportBase;
