export const Audiometer = {
  KLD_23_MHA: 'kld23mha',
  KLD_23c: 'kld23c',
  '308197': '308197',
  '883464': '883464',
  '883482': '883482',
  AU1P123245757: 'AU1P123245757',
  AU1P123245755: 'AU1P123245755',
  AU1P123246135: 'AU1P123246135',
  AU1P123245756: 'AU1P123245756',
  AU1P123245758: 'AU1P123245758',
};

export const AudiometerOptions = [
  {
    label: 'Kamplex Diagnostic Audiometer (KLD 23 MHA)',
    value: Audiometer.KLD_23_MHA,
  },
  {
    label: 'Kamplex Diagnostic Audiometer (KLD 23c)',
    value: Audiometer.KLD_23c,
  },
  {
    label: 'Madsen Xeta (308197)',
    value: Audiometer['308197'],
  },
  {
    label: 'Inter-acoustics AD229c (883464)',
    value: Audiometer['883464'],
  },
  {
    label: 'Inter-acoustics AD229e (883482)',
    value: Audiometer['883482'],
  },
  {
    label: 'Iventis Piccolo (AU1P123245757)',
    value: Audiometer.AU1P123245757,
  },
  {
    label: 'Iventis Piccolo (AU1P123245755)',
    value: Audiometer.AU1P123245755,
  },
  {
    label: 'Iventis Piccolo (AU1P123246135)',
    value: Audiometer.AU1P123246135,
  },
  {
    label: 'Iventis Piccolo (AU1P123245756)',
    value: Audiometer.AU1P123245756,
  },
  {
    label: 'Iventis Piccolo (AU1P123245758)',
    value: Audiometer.AU1P123245758,
  },
];

export const getAudiometerFromKey = (key: string) => {
  return AudiometerOptions.find((p) => p.value === key);
};

export const getAudiometerNameFromKey = (key: string) => {
  const p = AudiometerOptions.find((p) => p.value === key);
  return p?.label ?? 'Unknown';
};
