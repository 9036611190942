import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import 'chart.js/auto';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import SharedButton from 'shared/components/buttons/button';
import SharedCustomerListDialog, {
  ISharedCustomerListDialogRow,
} from 'shared/components/dialogs/customer-list-dialog/customer-list-dialog';
import { useDialog } from 'core/providers/DialogProvider';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { getRandomColor } from 'core/utilities/color-helpers';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';

interface ICustomerLeadTypeReportRowData {
  label: string;
  count: number;
  customerListRows: ISharedCustomerListDialogRow[];
}

const Cell = ({ count, customerListRows }: ICustomerLeadTypeReportRowData) => {
  const dialog = useDialog();
  return (
    <div>
      <SharedButton
        onClick={() => dialog?.openDialog(<SharedCustomerListDialog rows={customerListRows} />)}
        type='button'
        spacing='none'
        appearance='link'
        label={count.toString()}
      />
    </div>
  );
};

const CustomerLeadTypeReport = () => {
  const processRows = useCallback(async (querySnapshot: QuerySnapshot<ICustomer>) => {
    const rows: IReportBaseTableRows<ICustomerLeadTypeReportRowData>[] = [];
    querySnapshot.forEach((doc) => {
      const { leadType } = doc.data();
      const customerListRow = {
        customer: doc.data(),
      };
      const rowIndex = rows.findIndex((row) => row.key === leadType);
      if (rowIndex !== -1) {
        rows[rowIndex].data.count += 1;
        rows[rowIndex].data.customerListRows.push(customerListRow);
      } else {
        rows.push({
          key: leadType,
          color: getRandomColor(),
          data: {
            label: getLeadTypeNameFromKey(leadType),
            count: 1,
            customerListRows: [customerListRow],
          },
        });
      }
    });
    return rows;
  }, []);

  const tableColumns = [
    { label: 'Lead type', key: 'label', templateId: 'text', width: 50 },
    { label: 'Count', key: 'count', templateId: 'count', width: 50 },
  ];

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: Cell,
      id: 'count',
    },
  ];

  return (
    <>
      <PageHeader title='Customer lead type report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
        })}
        subscriber={CustomersApiService.subscribeToCustomers}
        totalLabel={'Total number of customers created'}
        emptyText='No customers created during the selected time frame'
        customTemplates={customTemplates}
      />
    </>
  );
};

export default CustomerLeadTypeReport;
