import { ICustomerMeta } from 'core/api/meta/meta-api-interface';
import { getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';

interface ICustomerStatusBreakdownDialog {
  meta: ICustomerMeta;
}

const CustomerStatusBreakdownDialog = ({ meta }: ICustomerStatusBreakdownDialog) => {
  const customContent = () => {
    return (
      <div className='overflow-y-auto p-2'>
        {Object.keys(meta)
          .sort((a, b) => meta[b] - meta[a])
          .map((key) => (
            <div key={key} className='body-02 p-2 flex justify-between hover:bg-gray-50'>
              <p>{getCustomerStatusNameFromKey(key)}</p>
              <p>{meta[key]}</p>
            </div>
          ))}
      </div>
    );
  };
  return <SharedDialogBase title='Customer status breakdown' customContentTemplate={customContent()} />;
};

export default CustomerStatusBreakdownDialog;
