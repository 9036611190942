export const AidGrantOption = {
  GRANT: 'grant',
  NO_GRANT: 'noGrant',
  ME2: 'me2',
};

export const AidGrantOptions = [
  {
    label: 'Grant',
    value: AidGrantOption.GRANT,
  },
  {
    label: 'No Grant',
    value: AidGrantOption.NO_GRANT,
  },
  {
    label: 'ME2',
    value: AidGrantOption.ME2,
  },
];

export const getAidGrantOptionsFromKey = (key: string) => {
  return AidGrantOptions.find((option) => option.value === key);
};

export const getAidGrantOptionsNameFromKey = (key: string) => {
  const option = AidGrantOptions.find((option) => option.value === key);
  return option?.label ?? 'Unknown';
};
