import { IUser } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { auth } from 'core/config/firebase';
import { PermissionSet, RolesPermissionSets } from 'core/constants/permissions';
import { onAuthStateChanged, User } from 'firebase/auth';
import { Unsubscribe } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';

interface IAuthContext {
  user: User | null;
  error?: Error;
  authChecked: boolean;
  userRoles: string[];
  userData?: IUser;
  userPermissions: string[];
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const useAuthState = () => {
  const state = useContext(AuthContext);
  return { ...state, isAuthenticated: state?.user !== null };
};

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<IUser>();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [error, setError] = useState<Error>();
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  useEffect(() => {
    const handleCurrentUserDocChanges = (doc: IUser) => {
      setUserData(doc);
    };

    const handleSubscriptionError = (error: any) => {
      console.log(error);
    };

    let unsubscribeFromUserDoc: Unsubscribe;

    const unsubscribeFromAuth = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          try {
            unsubscribeFromUserDoc = UsersApiService.subscribeToUser(
              user.uid,
              handleCurrentUserDocChanges,
              handleSubscriptionError
            );
            const idTokenResult = await user.getIdTokenResult();
            const roles = idTokenResult.claims.roles as string[];
            setUserRoles(roles);
            const userPermissionSets: PermissionSet[] = Array.from(
              new Set(roles.flatMap((role) => RolesPermissionSets[role]))
            );
            const permissions = Array.from(new Set(userPermissionSets.flatMap((set) => set.permissions)));
            setUserPermissions(permissions);
          } catch (error) {
            setError(new Error('Failed to process user data.'));
          }
        }
        setUser(user);
        setTimeout(() => {
          setAuthChecked(true);
        }, 1000);
      },
      setError
    );

    return () => {
      unsubscribeFromAuth();

      if (unsubscribeFromUserDoc) {
        unsubscribeFromUserDoc();
      }
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, error, authChecked, userRoles, userData, userPermissions }}>
      {children}
    </AuthContext.Provider>
  );
};
