import { IRouteRoles } from 'core/config/roles';
import { SidebarOptions } from 'core/config/sidebar-options';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import SetPasswordDialog from 'modules/auth/set-password-dialog/set-password-dialog';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import SharedAdminLayout from 'shared/components/layout/admin-layout';

const RoleHandler = ({ requiredPermissions, includeLayout = true }: IRouteRoles) => {
  const location = useLocation();
  const { userData, userPermissions } = useAuthState();

  const dialog = useDialog();

  useEffect(() => {
    if (userData?.resetPassword) {
      dialog?.openDialog(<SetPasswordDialog />);
    }
  }, [dialog, userData?.resetPassword]);

  if (
    requiredPermissions &&
    (!userPermissions || !requiredPermissions.every((permission) => userPermissions.includes(permission)))
  ) {
    const allowedRoute = SidebarOptions.find((item) =>
      item.requiredPermissions.every((permission) => userPermissions?.includes(permission))
    )?.route;
    return <Navigate to={allowedRoute ? `/${allowedRoute}` : ''} state={{ from: location }} replace />;
  }

  return includeLayout ? (
    <SharedAdminLayout>
      <Outlet />
    </SharedAdminLayout>
  ) : (
    <Outlet />
  );
};

export default RoleHandler;
