export const OrderStatus = {
  AWAITING_FITTING: 'awaitingFitting',
  AWAITING_FITTING_SEND_PAPERWORK: 'awaitingFittingSendPaperwork',
  FITTED: 'fitted',
  FITTED_PAYMENT_PLAN: 'fittedPaymentPlan',
  CANCELLED: 'Cancelled',
};

export const OrderStatuses = [
  {
    label: 'Awaiting fitting',
    key: OrderStatus.AWAITING_FITTING,
  },
  {
    label: 'Awaiting fitting - Send paperwork',
    key: OrderStatus.AWAITING_FITTING_SEND_PAPERWORK,
  },
  {
    label: 'Fitted',
    key: OrderStatus.FITTED,
  },
  {
    label: 'Fitted - Payment plan',
    key: OrderStatus.FITTED_PAYMENT_PLAN,
  },
  {
    label: 'Cancelled',
    key: OrderStatus.CANCELLED,
  },
];

export const getOrderStatusFromKey = (key: string) => {
  const status = OrderStatuses.find((status) => status.key === key);
  return status;
};

export const getOrderStatusNameFromKey = (key: string) => {
  const status = OrderStatuses.find((status) => status.key === key);
  return status?.label ?? 'Unknown';
};
