import { Appearance } from '@atlaskit/button';
import { ButtonType } from 'core/enums/button-type';
import { useDialog } from 'core/providers/DialogProvider';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';

interface ISharedConfirmationDialog {
  title: string;
  textContent: string;
  confirmButton: {
    appearance: Appearance;
    label: string;
    onClick: Function;
  };
  loading?: boolean;
}

const SharedConfirmationDialog = ({
  title,
  textContent,
  confirmButton,
  loading = false,
}: ISharedConfirmationDialog) => {
  // Hooks
  const dialog = useDialog();
  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Cancel',
        type: 'button',
      },
    },
    {
      key: 'confirm',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => confirmButton.onClick(),
        appearance: confirmButton.appearance,
        label: confirmButton.label,
        type: 'button',
      },
    },
  ];

  return <SharedDialogBase title={title} textContent={textContent} customButtons={customButtons} />;
};

export default SharedConfirmationDialog;
