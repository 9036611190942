import { firestore } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { doc, DocumentSnapshot } from 'firebase/firestore';
import ApiService from '../api.service';
import {
  IAddAudiogramRequestDto,
  IAudiogram,
  ICreateAudiogramRequestDto,
  IUpdateAudiogramRequestDto,
} from './audiogram-api-interface';
import AuditLogApiService from '../audit-log/audit-log-api.service';
import { IAuditLogChange } from '../audit-log/audit-log-api-interface';

const getSnapshot = async (uid: string): Promise<DocumentSnapshot<IAudiogram>> => {
  const docRef = doc(firestore, FirestoreCollectionIDs.AUDIOGRAM, uid);
  return ApiService.getSnapshot(docRef);
};

const set = async (dto: IAddAudiogramRequestDto, changes: IAuditLogChange[]) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.AUDIOGRAM, dto.uid);
  await ApiService.set(documentRef, dto);
  return AuditLogApiService.set({
    resourceUid: dto.uid,
    resourceType: 'audiogram',
    actionType: 'create',
    author: dto.createdBy,
    customerUid: dto.customer.uid,
    changes,
  });
};

const update = async (dto: IUpdateAudiogramRequestDto, changes: IAuditLogChange[]) => {
  const documentRef = doc(firestore, FirestoreCollectionIDs.AUDIOGRAM, dto.uid);
  await ApiService.update(documentRef, dto);
  return AuditLogApiService.set({
    resourceUid: dto.uid,
    resourceType: 'audiogram',
    actionType: 'update',
    author: dto.updatedBy,
    customerUid: dto.customer.uid,
    changes,
  });
};

const generateAudiogram = (dto: ICreateAudiogramRequestDto) =>
  ApiService.callFunction<string>(dto, FirebaseFunctionNames.GENERATE_AUDIOGRAM);

const AudiogramApiService = {
  getSnapshot,
  set,
  update,
  generateAudiogram,
};

export default AudiogramApiService;
