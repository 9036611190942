import { Permissions } from 'core/constants/permissions';
import { useAuthState } from 'core/providers/AuthProvider';
import { ReactElement } from 'react';
import { Folder, Headphones, PhoneOutgoing, ShoppingBag, Smile } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import PageHeader from 'shared/components/page-header/page-header';

interface IReportsOverviewSection {
  key: string;
  title: string;
  icon: ReactElement;
  reports: IReportsOverviewSectionReport[];
}

interface IReportsOverviewSectionReport {
  key: string;
  title: string;
  requiredPermission: string;
  onClick: Function;
}

const ReportsOverview = () => {
  const navigate = useNavigate();
  const { userPermissions } = useAuthState();

  const sections: IReportsOverviewSection[] = [
    {
      key: 'surveying',
      title: 'Surveying reports',
      icon: <Headphones />,
      reports: [
        {
          key: 'surveyCreation',
          title: 'Survey creation',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('survey-creation'),
        },
        {
          key: 'surveyOutcome',
          title: 'Survey outcome',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('survey-outcome'),
        },
      ],
    },
    {
      key: 'booking',
      title: 'Booking reports',
      icon: <PhoneOutgoing />,
      reports: [
        {
          key: 'hearingTestBooking',
          title: 'Hearing Test booking',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('hearing-test-booking'),
        },
        {
          key: 'hearingTestOutcome',
          title: 'Hearing Test outcome',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('hearing-test-outcome'),
        },
        {
          key: 'hearingTestLeadTypeOutcome',
          title: 'Lead type outcome',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('hearing-test-lead-type-outcome'),
        },
        {
          key: 'notInterested',
          title: 'Customers not interested',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('customers-not-interested'),
        },
        {
          key: 'leadTypeOutcome',
          title: 'Dispenser & lead type outcome',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('lead-type-outcome'),
        },
      ],
    },
    {
      key: 'order',
      title: 'Order reports',
      icon: <ShoppingBag />,
      reports: [
        {
          key: 'orderLeadTypeReport',
          title: 'Order lead type',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('order-lead-type'),
        },
        {
          key: 'orderDispenserReport',
          title: 'Order dispenser',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('order-dispenser'),
        },
        {
          key: 'orderBookerReport',
          title: 'Order booker',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('order-booker'),
        },
        {
          key: 'orderSurveyorReport',
          title: 'Order surveyor',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('order-surveyor'),
        },
      ],
    },
    {
      key: 'customer',
      title: 'Customer reports',
      icon: <Folder />,
      reports: [
        {
          key: 'leadType',
          title: 'Lead type',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('customer-lead-type'),
        },
        {
          key: 'leadTypeStatus',
          title: 'Lead type status',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('customer-lead-type-status'),
        },
      ],
    },
    {
      key: 'service',
      title: 'Servicing reports',
      icon: <Smile />,
      reports: [
        {
          key: 'serviceCalls',
          title: 'Service call attendance',
          requiredPermission: Permissions.REPORTING_ALL,
          onClick: () => navigate('service-call-attendance'),
        },
      ],
    },
  ];

  const getReportsList = (section: IReportsOverviewSection) => {
    const reports = section.reports.filter((report) => userPermissions?.includes(report.requiredPermission));

    if (reports.length === 0) {
      return (
        <div className='p-4'>
          <p>No reports available</p>
        </div>
      );
    }

    return reports.map((report) => (
      <div key={report.key} className='border-b last:border-0 p-4 flex justify-between items-center'>
        <p>{report.title}</p>
        <SharedButton onClick={report.onClick} type='button' appearance='subtle' label='View' spacing='compact' />
      </div>
    ));
  };
  return (
    <>
      <PageHeader title='System reports' subtitle='Select from the options below to view a report' />
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6 h-max'>
        {sections.map((section) => (
          <div key={section.key} className='bg-white w-full rounded-md shadow-md h-fit'>
            <div className='flex items-center p-4 border-b'>
              {section.icon}
              <p className='ml-3 headline-05'>{section.title}</p>
            </div>

            {getReportsList(section)}
          </div>
        ))}
      </div>
    </>
  );
};

export default ReportsOverview;
