import { useFlags } from '@atlaskit/flag';
import { auth } from 'core/config/firebase';
import { ResetPasswordFormFields } from 'core/config/form-fields';
import { showSuccessFlag } from 'core/utilities/flags-helper';
import { confirmPasswordReset } from 'firebase/auth';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SharedForm from 'shared/components/form/form';
import AuthPageWrapper from 'shared/components/layout/auth-page-wrapper';

const ResetPasswordForm = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const [formError, setFormError] = useState<string>();

  const navigate = useNavigate();
  const flags = useFlags();
  const code = searchParams.get('oobCode');

  const resetPassword = async (data: { newPassword: string; confirmNewPassword: string }) => {
    setFormSubmitting(true);
    if (data.newPassword !== data.confirmNewPassword) {
      setFormError('Passwords do not match');
      setFormSubmitting(false);
      return;
    }

    try {
      if (!code) {
        throw new Error('No oobCode provided');
      }
      await confirmPasswordReset(auth, code, data.newPassword);
      navigate('/login');
      showSuccessFlag('Success', 'Your password has been successfully reset', flags);
    } catch (error) {
      setFormError('Password reset failed, please try again');
      setFormSubmitting(false);
    }
  };

  return (
    <AuthPageWrapper>
      <p className='headline-04 mb-8'>Reset your password</p>
      <p className='body-02 mb-8'>Please enter a new password</p>
      <SharedForm
        onSubmit={resetPassword}
        fields={ResetPasswordFormFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        formErrorMessage={formError}
      />
    </AuthPageWrapper>
  );
};

export default ResetPasswordForm;
