import { CustomerTitles } from 'core/constants/customer-title';
import { DocumentTypes } from 'core/constants/document-types';
import { AidGrantOptions } from 'core/constants/hearing-aid-grant-options';
import { AidImpressionsRequiredOptions } from 'core/constants/hearing-aid-impressions-required-options';
import { AidLengths } from 'core/constants/hearing-aid-lengths';
import { AidPowerOptions } from 'core/constants/hearing-aid-power-options';
import { AidReceiverGains } from 'core/constants/hearing-aid-receiver-gains';
import { AidStyles } from 'core/constants/hearing-aid-styles';
import { AidTypes } from 'core/constants/hearing-aid-types';
import { LeadTypes } from 'core/constants/lead-type';
import { MeatusOptions } from 'core/constants/meatus';
import { PaymentMethods } from 'core/constants/payment-methods';
import { PinnaOptions } from 'core/constants/pinna';
import { TMOptions } from 'core/constants/tm';
import { YesNoOptions } from 'core/constants/yes-no';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { getAppointment15MinuteTimeSlots } from 'core/utilities/appointment-helpers';
import { todayAsUSString } from 'core/utilities/date-helpers';
import dayjs from 'dayjs';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { Roles } from './roles';
import { BalancePaymentMethods } from 'core/constants/balance-payment-methods';

export const LoginFormFields: ISharedFormField[] = [
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email address',
    required: true,
  },
  {
    key: 'password',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Password',
    required: true,
  },
];

export const SetPasswordFormFields: ISharedFormField[] = [
  {
    key: 'currentPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Current Password',
    required: true,
  },
  {
    key: 'newPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'New Password',
    required: true,
  },
  {
    key: 'confirmNewPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Confirm New Password',
    required: true,
  },
];

export const ForgotPasswordFormFields: ISharedFormField[] = [
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email address',
    required: true,
  },
];

export const ResetPasswordFormFields: ISharedFormField[] = [
  {
    key: 'newPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'New Password',
    required: true,
  },
  {
    key: 'confirmNewPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Confirm Password',
    required: true,
  },
];

export const AddUserFormFields: ISharedFormField[] = [
  {
    key: 'fullName',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Full Name',
    required: true,
  },
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email address',
    required: true,
  },
  {
    key: 'roles',
    control: ControlType.SelectMulti,
    label: 'User Role',
    required: true,
    options: Roles.map((role) => ({
      value: role.key,
      label: role.label,
    })),
  },
  {
    key: 'lat',
    control: ControlType.TextField,
    type: InputType.Number,
    label: 'Home address latitude',
    required: false,
    placeholder: 'e.g. 54.0000000',
  },
  {
    key: 'lng',
    control: ControlType.TextField,
    type: InputType.Number,
    label: 'Home address longitude',
    required: false,
    placeholder: 'e.g. -7.0000000',
  },
];

export const AddCustomerFormFields: ISharedFormField[] = [
  {
    key: 'leadType',
    control: ControlType.Select,
    label: 'Lead Type',
    required: true,
    options: LeadTypes,
  },
  {
    key: 'title',
    control: ControlType.Select,
    options: CustomerTitles,
    type: InputType.Text,
    label: 'Title',
    required: true,
  },
  {
    key: 'fullName',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Full Name',
    required: true,
  },
  {
    key: 'address',
    control: ControlType.AddressSearch,
    label: 'Address',
    required: true,
  },
  {
    key: 'phoneNumber',
    control: ControlType.TextField,
    type: InputType.Tel,
    label: 'Phone Number',
    required: true,
  },
  {
    key: 'mobileNumber',
    control: ControlType.TextField,
    type: InputType.Tel,
    label: 'Other Number',
    required: false,
  },
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email Address',
    required: false,
  },
  {
    key: 'teleHear',
    control: ControlType.Select,
    options: YesNoOptions,
    label: 'TeleHear Enabled',
    required: false,
  },
  {
    key: 'dob',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Date of Birth',
    required: false,
  },
  {
    key: 'age',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Age',
    required: false,
  },
  {
    key: 'ppsn',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'PPSN Number',
    required: false,
  },
];

export const AddCustomerNoteFormFields: ISharedFormField[] = [
  {
    key: 'note',
    control: ControlType.TextArea,
    required: true,
  },
];

export const AddEditAppointmentFormFields: ISharedFormField[] = [
  {
    key: 'details',
    control: ControlType.TextArea,
    label: 'Details',
    required: false,
  },
];

export const SelectCustomerDialogFormFields: ISharedFormField[] = [
  {
    key: 'customer',
    control: ControlType.CustomerSearch,
    label: 'Customer',
    required: true,
  },
];

export const CreateSurveyFormFields: ISharedFormField[] = [
  {
    key: 'hearingProblems',
    control: ControlType.TextArea,
    label: 'Does anybody in your home have hearing problems?',
    required: true,
  },
  {
    key: 'length',
    control: ControlType.TextArea,
    label: 'How long have you had hearing trouble?',
    required: true,
  },
  {
    key: 'affects',
    control: ControlType.TextArea,
    label: "How would you say you're hearing effects you?",
    required: true,
  },
  {
    key: 'lastTest',
    control: ControlType.TextArea,
    label: 'When were you last tested?',
    required: true,
  },
  {
    key: 'additionalNotes',
    control: ControlType.TextArea,
    label: 'Any additional notes',
    required: false,
  },
  {
    key: 'title',
    control: ControlType.Select,
    options: CustomerTitles,
    type: InputType.Text,
    label: 'Title',
    required: true,
  },
  {
    key: 'fullName',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Full Name',
    required: true,
  },
  {
    key: 'address',
    control: ControlType.AddressSearch,
    label: 'Address',
    required: true,
  },
  {
    key: 'phoneNumber',
    control: ControlType.TextField,
    type: InputType.Tel,
    label: 'Phone Number',
    required: true,
  },
  {
    key: 'mobileNumber',
    control: ControlType.TextField,
    type: InputType.Tel,
    label: 'Mobile Number',
    required: false,
  },
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email Address',
    required: false,
  },
  {
    key: 'age',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Age',
    required: false,
  },
  {
    key: 'ppsn',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'PPSN Number',
    required: false,
  },
];

export const CallbackDialogFormFields: ISharedFormField[] = [
  {
    key: 'date',
    control: ControlType.DatePicker,
    label: 'Date',
    required: true,
    defaultValue: todayAsUSString(),
    minDate: todayAsUSString(),
  },
  {
    key: 'time',
    control: ControlType.Select,
    label: 'Time',
    required: false,
    options: getAppointment15MinuteTimeSlots().map((slot) => ({
      value: slot,
      label: slot,
    })),
  },
];

export const CancelAppointmentDialogFormFields: ISharedFormField[] = [
  {
    key: 'status',
    control: ControlType.Select,
    label: 'Customer status',
    required: true,
    options: [],
  },
];

export const UploadDocumentDialogFormFields: ISharedFormField[] = [
  {
    key: 'fileCapture',
    control: ControlType.File,
    label: 'File',
    required: true,
  },
  {
    key: 'type',
    control: ControlType.Select,
    label: 'Type',
    options: DocumentTypes.filter((type) => type.uploadable).map((type) => ({
      value: type.key,
      label: type.label,
    })),
    required: true,
  },
];

export const OrderProductDetailGeneralFormFields: ISharedFormField[] = [
  {
    key: 'power',
    control: ControlType.Select,
    label: 'Power',
    options: AidPowerOptions,
    required: true,
  },
  {
    key: 'impressionsRequired',
    control: ControlType.Select,
    label: 'Impressions required',
    options: AidImpressionsRequiredOptions,
    required: true,
  },
];

export const OrderProductDetailFormFields: ISharedFormField[] = [
  {
    key: 'type',
    control: ControlType.Select,
    label: 'Type',
    options: AidTypes,
    required: true,
  },
  {
    key: 'style',
    control: ControlType.Select,
    label: 'Style',
    options: AidStyles,
    required: true,
  },
  {
    key: 'length',
    control: ControlType.Select,
    label: 'Length',
    options: AidLengths,
    required: true,
  },
  {
    key: 'gain',
    control: ControlType.Select,
    label: 'Receiver gain',
    options: AidReceiverGains,
    required: true,
  },
];

export const OrderProductCostFormFields: ISharedFormField[] = [
  {
    key: 'grant',
    control: ControlType.Select,
    label: 'Grant',
    options: AidGrantOptions,
    required: true,
  },
  {
    key: 'grossPrice',
    control: ControlType.TextField,
    type: InputType.Number,
    label: 'Goods/Services price (€)',
    required: true,
  },
  {
    key: 'discount',
    control: ControlType.TextField,
    type: InputType.Number,
    label: 'Discount/Grant (€)',
    required: true,
  },
];

export const OrderDepositFormFields: ISharedFormField[] = [
  {
    key: 'deposit',
    control: ControlType.TextField,
    type: InputType.Number,
    label: 'Amount',
    required: true,
  },
  {
    key: 'paymentMethod',
    control: ControlType.Select,
    label: 'Payment method',
    options: PaymentMethods,
    required: true,
  },
];

export const OrderBalanceFormFields: ISharedFormField[] = [
  {
    key: 'balancePaymentMethod',
    control: ControlType.Select,
    options: BalancePaymentMethods,
    label: 'Payment method',
    required: true,
  },
];

export const OrderPPSNFormFields: ISharedFormField[] = [
  {
    key: 'ppsn',
    control: ControlType.TextField,
    label: 'PPSN number',
    required: true,
  },
];

export const OrderDOBFormFields: ISharedFormField[] = [
  {
    key: 'dob',
    control: ControlType.TextField,
    label: 'Date of birth',
    required: true,
  },
];

export const OrderAdditionalInfoFormFields: ISharedFormField[] = [
  {
    key: 'additionalInformation',
    control: ControlType.TextArea,
    required: false,
  },
];

export const OrderEmailFormFields: ISharedFormField[] = [
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    label: 'Customer email address',
    required: true,
  },
];

export const EarHealthFormFields: ISharedFormField[] = [
  {
    key: 'pinna',
    control: ControlType.SegmentedSelect,
    label: 'Pinna',
    options: PinnaOptions,
    required: true,
  },
  {
    key: 'meatus',
    control: ControlType.SegmentedSelect,
    label: 'Meatus',
    options: MeatusOptions,
    required: true,
  },
  {
    key: 'tm',
    control: ControlType.SegmentedSelect,
    label: 'TM',
    options: TMOptions,
    required: true,
  },
  {
    key: 'discharge',
    control: ControlType.SegmentedSelect,
    label: 'Discharge',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'earache',
    control: ControlType.SegmentedSelect,
    label: 'Earache',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'tinnitus',
    control: ControlType.SegmentedSelect,
    label: 'Tinnitus',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'conductive',
    control: ControlType.SegmentedSelect,
    label: 'Conductive',
    options: YesNoOptions,
    required: true,
  },
];

export const HearingLossCausesFormFields: ISharedFormField[] = [
  {
    key: 'noise',
    control: ControlType.SegmentedSelect,
    label: 'Noise',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'unilateral',
    control: ControlType.SegmentedSelect,
    label: 'Unilateral',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'asymmetrical',
    control: ControlType.SegmentedSelect,
    label: 'Asymmetrical',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'suddenOnset',
    control: ControlType.SegmentedSelect,
    label: 'Sudden onset',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'suddenWorsening',
    control: ControlType.SegmentedSelect,
    label: 'Sudden worsening',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'fluctuating',
    control: ControlType.SegmentedSelect,
    label: 'Fluctuating',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'vertigo',
    control: ControlType.SegmentedSelect,
    label: 'Vertigo',
    options: YesNoOptions,
    required: true,
  },
  {
    key: 'premature',
    control: ControlType.SegmentedSelect,
    label: 'Premature',
    options: YesNoOptions,
    required: true,
  },
];

export const ExportCustomersDialogFormFields = [
  {
    key: 'dateFrom',
    control: ControlType.DatePicker,
    label: 'Created after',
    required: true,
    defaultValue: dayjs(new Date()).format('YYYY-MM-DD'),
  },
  {
    key: 'dateTo',
    control: ControlType.DatePicker,
    label: 'Created before',
    required: true,
    defaultValue: dayjs(new Date()).format('YYYY-MM-DD'),
  },
];
