import { Permissions } from 'core/constants/permissions';
import RoleHandler from 'core/routing/role-handler';
import AudiogramForm from 'modules/audiogram/audiogram-form/audiogram-form';
import { Navigate, Route, Routes } from 'react-router-dom';
import CustomerOverview from './customer-overview/customer-overview';
import CustomersList from './customers-list/customers-list';

const CustomersRouting = () => {
  return (
    <Routes>
      <Route element={<RoleHandler requiredPermissions={[Permissions.CUSTOMERS_LIST]} includeLayout={false} />}>
        <Route path='' element={<CustomersList />} />
      </Route>
      <Route element={<RoleHandler requiredPermissions={[Permissions.CUSTOMERS_GET]} includeLayout={false} />}>
        <Route path=':uid/audiogram' element={<AudiogramForm />} />
        <Route path=':uid' element={<CustomerOverview />} />
      </Route>
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default CustomersRouting;
