import { getAppointment15MinuteTimeSlots } from 'core/utilities/appointment-helpers';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { AppointmentStatus } from './appointment-status';
import Fitting from 'assets/images/map-pin-fitting.png';
import Impressions from 'assets/images/map-pin-impressions.png';
import Service from 'assets/images/map-pin-service.png';
import ShopFitting from 'assets/images/map-pin-shop-fitting.png';
import ShopService from 'assets/images/map-pin-shop-service.png';
import ShopTest from 'assets/images/map-pin-shop-test.png';
import ShopWax from 'assets/images/map-pin-shop-wax.png';
import Test from 'assets/images/map-pin-test.png';
import Wax from 'assets/images/map-pin-wax.png';
import New from 'assets/images/map-pin-new.png';
import Admin from 'assets/images/map-pin-admin.png';
import TeleHear from 'assets/images/map-pin-telehear.png';
import WarrantyFitting from 'assets/images/map-pin-warranty-fitting.png';

export const AppointmentType = {
  HOME_TEST: 'homeTest',
  SHOP_TEST: 'shopTest',
  SERVICE: 'service',
  SHOP_SERVICE: 'shopService',
  WAX_REMOVAL: 'waxRemoval',
  SHOP_WAX_REMOVAL: 'shopWaxRemoval',
  FITTING: 'fitting',
  SHOP_FITTING: 'shopFitting',
  WARRANTY_FITTING: 'warrantyFitting',
  IMPRESSIONS: 'impressions',
  ADMIN: 'admin',
  TELE_HEAR: 'teleHear',
};

export const AppointmentTypes = [
  {
    label: 'Home hearing test',
    value: AppointmentType.HOME_TEST,
  },
  {
    label: 'Shop hearing test',
    value: AppointmentType.SHOP_TEST,
  },
  {
    label: 'Fitting',
    value: AppointmentType.FITTING,
  },
  {
    label: 'Shop fitting',
    value: AppointmentType.SHOP_FITTING,
  },
  {
    label: 'Warranty fitting',
    value: AppointmentType.WARRANTY_FITTING,
  },
  {
    label: 'Service',
    value: AppointmentType.SERVICE,
  },
  {
    label: 'Shop Service',
    value: AppointmentType.SHOP_SERVICE,
  },
  {
    label: 'Wax removal',
    value: AppointmentType.WAX_REMOVAL,
  },
  {
    label: 'Shop wax removal',
    value: AppointmentType.SHOP_WAX_REMOVAL,
  },
  {
    label: 'Impressions',
    value: AppointmentType.IMPRESSIONS,
  },
  {
    label: 'TeleHear',
    value: AppointmentType.TELE_HEAR,
  },
  {
    label: 'Admin',
    value: AppointmentType.ADMIN,
  },
];

export const getAppointmentTypeColor = (type: string) => {
  switch (type) {
    case AppointmentType.SERVICE:
      return '#0F8042';
    case AppointmentType.SHOP_SERVICE:
      return '#22c55e';
    case AppointmentType.WAX_REMOVAL:
      return '#06b6d4';
    case AppointmentType.SHOP_WAX_REMOVAL:
      return '#1e3a8a';
    case AppointmentType.FITTING:
      return '#8E24A9';
    case AppointmentType.SHOP_FITTING:
      return '#be185d';
    case 'new':
      return '#1d4ed8';
    case AppointmentType.SHOP_TEST:
      return '#f472b6';
    case AppointmentType.IMPRESSIONS:
      return '#134e4a';
    case AppointmentType.ADMIN:
      return '#9ca3af';
    case AppointmentType.TELE_HEAR:
      return '#ea580c';
    case AppointmentType.WARRANTY_FITTING:
      return '#CE8946';
    default:
      return '#F4BF28';
  }
};

export const getAppointmentTypePin = (type: string) => {
  switch (type) {
    case AppointmentType.SERVICE:
      return Service;
    case AppointmentType.SHOP_SERVICE:
      return ShopService;
    case AppointmentType.WAX_REMOVAL:
      return Wax;
    case AppointmentType.SHOP_WAX_REMOVAL:
      return ShopWax;
    case AppointmentType.FITTING:
      return Fitting;
    case AppointmentType.SHOP_FITTING:
      return ShopFitting;
    case 'new':
      return New;
    case AppointmentType.SHOP_TEST:
      return ShopTest;
    case AppointmentType.IMPRESSIONS:
      return Impressions;
    case AppointmentType.ADMIN:
      return Admin;
    case AppointmentType.TELE_HEAR:
      return TeleHear;
    case AppointmentType.WARRANTY_FITTING:
      return WarrantyFitting;
    default:
      return Test;
  }
};

export const getAppointmentTypeFromKey = (key: string) => {
  return AppointmentTypes.find((type) => type.value === key);
};

export const getAppointmentTypeNameFromKey = (key: string) => {
  const type = AppointmentTypes.find((type) => type.value === key);
  return type?.label ?? 'Unknown';
};

export const getAppointmentStyle = (
  start: string,
  end: string,
  type: string,
  status: string,
  widthPercent: number,
  leftPercent: number
) => {
  const allSlots = getAppointment15MinuteTimeSlots();
  const startIndex = allSlots.findIndex((slot) => slot === start);
  const endIndex = allSlots.findIndex((slot) => slot === end);
  const slotHeight = endIndex - startIndex;
  let style: StringIndexable = {
    top: `${startIndex * 15}px`,
    height: `${slotHeight * 15}px`,
    width: `${widthPercent * 100}%`,
    left: `${leftPercent * 100}%`,
  };

  if (status === AppointmentStatus.CANCELLED) {
    style.background = 'repeating-linear-gradient(45deg, #dc2626, #dc2626 10px, #b91c1c 10px, #b91c1c 20px)';
  } else {
    style.backgroundColor = getAppointmentTypeColor(type);
  }

  if (slotHeight < 3) {
    style.paddingTop = '0.1rem';
  }

  return style;
};

export const getWeeklyAppointmentStyle = (
  start: string,
  end: string,
  type: string,
  status: string,
  widthPercent: number,
  leftPercent: number,
  dayIndex: number,
  columnWidth: number,
  resourceColumnWidth: number
) => {
  const allSlots = getAppointment15MinuteTimeSlots();
  const startIndex = allSlots.findIndex((slot) => slot === start);
  const endIndex = allSlots.findIndex((slot) => slot === end);
  const slotHeight = endIndex - startIndex;
  const slotWidth = columnWidth / 44;
  let style: StringIndexable = {
    left: `${startIndex * slotWidth + resourceColumnWidth + columnWidth * dayIndex}px`,
    width: `${slotHeight * slotWidth}px`,
    height: `${widthPercent * 100}%`,
    top: `${leftPercent * 100}%`,
  };

  if (status === AppointmentStatus.CANCELLED) {
    style.background = 'repeating-linear-gradient(45deg, #dc2626, #dc2626 10px, #b91c1c 10px, #b91c1c 20px)';
  } else {
    style.backgroundColor = getAppointmentTypeColor(type);
  }

  if (slotHeight < 3) {
    style.paddingTop = '0.1rem';
  }

  return style;
};
