import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useCallback, useEffect, useState } from 'react';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { todayAsUSString, USStringToUKString } from 'core/utilities/date-helpers';
import { IAppointment, IAppointmentQueryOptions } from 'core/api/appointments/appointments-api-interface';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { DatePicker } from '@atlaskit/datetime-picker';
import { getAppointmentTypeNameFromKey } from 'core/constants/appointment-type';
import { useNavigate } from 'react-router-dom';

interface ISelectAppointmentDialog {
  customerUid?: string;
}

const SelectAppointmentDialog = ({ customerUid }: ISelectAppointmentDialog) => {
  const today = todayAsUSString();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState<string>(today);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  // Hooks
  const flags = useFlags();
  const navigate = useNavigate();
  const dialog = useDialog();

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createAppointmentsSubscription = useCallback(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IAppointment>) => {
      const list: IAppointment[] = [];
      querySnapshot.forEach((snap) => {
        list.push(snap.data());
      });
      setAppointments(list.filter((app) => app.prescribed !== true));
      setLoading(false);
    };
    const queryOptions: IAppointmentQueryOptions = {
      date,
    };

    if (customerUid) {
      queryOptions.customerId = customerUid;
    }
    return AppointmentsApiService.subscribeToAppointments(handleSnapshot, handleSubscriptionError, queryOptions);
  }, [customerUid, date, handleSubscriptionError]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    unsubscribe = createAppointmentsSubscription();
    return () => {
      unsubscribe();
    };
  }, [createAppointmentsSubscription]);

  const customContent = () => {
    return (
      <>
        <div className='p-4 border-b'>
          <p className='label-02 font-semibold mb-1 text-gray-500'>Appointment date</p>
          <DatePicker
            selectProps={{ isSearchable: false }}
            locale={'en-UK'}
            defaultValue={date ?? ''}
            onChange={(value) => setDate(value)}
            maxDate={today}
          />
        </div>
        {loading ? (
          <p className='p-4 body-02'>Loading appointments...</p>
        ) : (
          <div className='overflow-y-auto'>
            {appointments.map((app) => (
              <div
                key={app.uid}
                className='first:border-0 p-4 hover:bg-gray-50 cursor-pointer border-t'
                onClick={() => {
                  navigate(`/orders/new?appointment=${app.uid}`);
                  dialog?.closeDialog();
                }}
              >
                <p className='font-semibold'>
                  {!customerUid && `${app.customer.fullName} -`} {getAppointmentTypeNameFromKey(app.type)}
                </p>
                <p className='body-02'>{app.assignee.fullName}</p>
                <p className='body-02'>
                  {USStringToUKString(app.date)} at {app.start}
                </p>
              </div>
            ))}
            {appointments.length === 0 && <p className='p-4 body-02'>No appointments found</p>}
          </div>
        )}
      </>
    );
  };

  return (
    <SharedDialogBase
      title='Appointment selection'
      subTitle='Select the appointment that lead to this order'
      customContentTemplate={customContent()}
    />
  );
};

export default SelectAppointmentDialog;
