import { useFlags } from '@atlaskit/flag';
import { auth } from 'core/config/firebase';
import { ForgotPasswordFormFields } from 'core/config/form-fields';
import { showSuccessFlag } from 'core/utilities/flags-helper';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedForm from 'shared/components/form/form';
import AuthPageWrapper from 'shared/components/layout/auth-page-wrapper';

const ForgotPasswordForm = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const navigate = useNavigate();
  const flags = useFlags();

  const sendResetPasswordLink = async (data: { emailAddress: string }) => {
    setFormSubmitting(true);
    await sendPasswordResetEmail(auth, data.emailAddress);
    navigate('/login');
    showSuccessFlag(
      'Check your email',
      "Thanks, if the email address you entered is associated with an account, you will receive an email with instructions to reset your password. If you can't find the email, be sure to check your junk mailbox",
      flags
    );
  };

  const cancelButton: IButton = {
    onClick: () => navigate('/login'),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  return (
    <AuthPageWrapper>
      <p className='headline-04 mb-8'>Forgot password</p>
      <p className='body-02 mb-8'>
        Enter the email address associated with your account, and we'll send you a link to reset your password.
      </p>
      <SharedForm
        onSubmit={sendResetPasswordLink}
        fields={ForgotPasswordFormFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        cancelButton={cancelButton}
      />
    </AuthPageWrapper>
  );
};

export default ForgotPasswordForm;
