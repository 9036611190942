import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'core/api/users/users-api-interface';
import { StoreState } from 'store';

const initialState = [] as IUser[];

const slice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    resetUserState: () => initialState,
    addMultiple(state, action: PayloadAction<IUser[]>) {
      state.push(...action.payload);
    },
  },
});

const { resetUserState, addMultiple } = slice.actions;

const selectAll = (state: StoreState) => state.users;

const selectByUid = (state: StoreState, uid: string) => state.users.find((user) => user.uid === uid);

export const UsersSlice = {
  resetUserState,
  addMultiple,
  selectAll,
  selectByUid,
};

export default slice.reducer;
