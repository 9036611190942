import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { AppointmentType, getAppointmentTypeNameFromKey } from 'core/constants/appointment-type';
import { LeadTypes } from 'core/constants/lead-type';
import { getRandomColor } from 'core/utilities/color-helpers';
import { USStringToUKString } from 'core/utilities/date-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import ReportBreakdownCustomCell, {
  IReportBreakdownCustomCell,
} from '../report-breakdown-custom-cell/report-breakdown-custom-cell';

const HearingTestBooking = () => {
  const getCustomerListMetaElement = (app: IAppointment) => {
    return (
      <div>
        <p className='font-semibold'>{getAppointmentTypeNameFromKey(app.type)}</p>
        <p className='body-02'>
          {USStringToUKString(app.date)} at {app.start}
        </p>
      </div>
    );
  };

  const processRows = useCallback(async (querySnapshot: QuerySnapshot<IAppointment>) => {
    const rows: IReportBaseTableRows<IReportBreakdownCustomCell>[] = [];
    querySnapshot.forEach((doc) => {
      const { organiser, customer } = doc.data();
      const rowIndex = rows.findIndex((row) => row.key === organiser.uid);
      if (rowIndex !== -1) {
        rows[rowIndex].data.count += 1;
        const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === customer.leadType);
        if (typeIndex !== -1) {
          rows[rowIndex].data.breakdowns[typeIndex].count += 1;
          rows[rowIndex].data.breakdowns[typeIndex].customerListRows?.push({
            metaElement: getCustomerListMetaElement(doc.data()),
            customer,
          });
        }
      } else {
        rows.push({
          key: organiser.uid,
          color: getRandomColor(),
          data: {
            label: organiser.fullName,
            count: 1,
            breakdowns: LeadTypes.map((type) => ({
              key: type.value,
              count: customer.leadType === type.value ? 1 : 0,
              label: type.label,
              customerListRows:
                customer.leadType === type.value
                  ? [
                      {
                        metaElement: getCustomerListMetaElement(doc.data()),
                        customer,
                      },
                    ]
                  : [],
            })),
          },
        });
      }
    });
    return rows;
  }, []);

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'organiser',
    },
  ];

  const tableColumns = [{ label: 'Organiser', key: 'organiser', templateId: 'organiser', width: 100 }];

  return (
    <>
      <PageHeader title='Hearing Test booking report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
          types: [AppointmentType.HOME_TEST, AppointmentType.SHOP_TEST],
        })}
        subscriber={AppointmentsApiService.subscribeToAppointments}
        totalLabel={'Total number of bookings:'}
        emptyText={'No appointments created during the selected time frame'}
        customTemplates={customTemplates}
        queryOrdering={['createdAt']}
      />
    </>
  );
};

export default HearingTestBooking;
