import { Permissions } from 'core/constants/permissions';
import RoleHandler from 'core/routing/role-handler';
import { Navigate, Route, Routes } from 'react-router-dom';
import HearingTestBooking from './hearing-test-booking-report/hearing-test-booking-report';
import HearingTestOutcome from './hearing-test-outcome-report/hearing-test-outcome-report';
import NotInterestedReport from './not-interested-report/not-interested-report';
import OrderLeadTypeReport from './order-lead-type-report/order-lead-type-report';
import ReportsOverview from './reports-overview/reports-overview';
import SurveyCreationReport from './survey-creation-report/survey-creation-report';
import SurveyOutcomeReport from './survey-outcome-report/survey-outcome-report';
import OrderDispenserReport from './order-dispenser-report/order-dispenser-report';
import OrderBookerReport from './order-booker-report/order-booker-report';
import OrderSurveyorReport from './order-surveyor-report/order-surveyor-report';
import LeadTypeOutcomeReport from './lead-type-outcome-report/lead-type-outcome-report';
import CustomerLeadTypeReport from './customer-lead-type-report/customer-lead-type-report';
import ServiceCallAttendanceReport from './service-call-attendance-report/service-call-attendance-report';
import HearingTestLeadTypeOutcomeReport from './hearing-test-lead-type-outcome-report/hearing-test-lead-type-outcome-report';
import CustomerLeadTypeStatusReport from './customer-lead-type-status-report/customer-lead-type-status-report';

const ReportsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<ReportsOverview />} />
      <Route element={<RoleHandler includeLayout={false} requiredPermissions={[Permissions.REPORTING_ALL]} />}>
        <Route path='survey-creation' element={<SurveyCreationReport />} />
        <Route path='hearing-test-booking' element={<HearingTestBooking />} />
        <Route path='hearing-test-outcome' element={<HearingTestOutcome />} />
        <Route path='customers-not-interested' element={<NotInterestedReport />} />
        <Route path='survey-outcome' element={<SurveyOutcomeReport />} />
        <Route path='order-lead-type' element={<OrderLeadTypeReport />} />
        <Route path='order-dispenser' element={<OrderDispenserReport />} />
        <Route path='order-booker' element={<OrderBookerReport />} />
        <Route path='order-surveyor' element={<OrderSurveyorReport />} />
        <Route path='lead-type-outcome' element={<LeadTypeOutcomeReport />} />
        <Route path='customer-lead-type' element={<CustomerLeadTypeReport />} />
        <Route path='customer-lead-type-status' element={<CustomerLeadTypeStatusReport />} />
        <Route path='service-call-attendance' element={<ServiceCallAttendanceReport />} />
        <Route path='hearing-test-lead-type-outcome' element={<HearingTestLeadTypeOutcomeReport />} />
      </Route>
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default ReportsRouting;
