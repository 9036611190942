export const CertificateStatus = {
  NOT_REQUIRED: 'notRequired',
  AWAITING_FITTING: 'awaitingFitting',
  AWAITING_CERTIFICATE: 'awaitingCertificate',
  AWAITING_UPLOAD: 'awaitingUpload',
  UPLOADED: 'uploaded',
};

export const CertificateStatuses = [
  {
    label: 'Not required',
    value: CertificateStatus.NOT_REQUIRED,
  },
  {
    label: 'Awaiting fitting',
    value: CertificateStatus.AWAITING_FITTING,
  },
  {
    label: 'Awaiting certificate',
    value: CertificateStatus.AWAITING_CERTIFICATE,
  },
  {
    label: 'Awaiting upload',
    value: CertificateStatus.AWAITING_UPLOAD,
  },
  {
    label: 'Uploaded',
    value: CertificateStatus.UPLOADED,
  },
];

export const getCertificateStatusFromKey = (key: string) => {
  return CertificateStatuses.find((certStatus) => certStatus.value === key);
};

export const getCertificateStatusNameFromKey = (key: string) => {
  const certStatus = CertificateStatuses.find((certStatus) => certStatus.value === key);
  return certStatus?.label ?? 'Unknown';
};
