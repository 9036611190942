export const TM = {
  INTACT: 'intact',
  NOT_INTACT: 'notIntact',
};

export const TMOptions = [
  {
    label: 'Intact',
    value: TM.INTACT,
  },
  {
    label: 'Not intact',
    value: TM.NOT_INTACT,
  },
];

export const getTMFromKey = (key: string) => {
  return TMOptions.find((p) => p.value === key);
};

export const getTMNameFromKey = (key: string) => {
  const p = TMOptions.find((p) => p.value === key);
  return p?.label ?? 'Unknown';
};
