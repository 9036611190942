import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { IUser } from 'core/api/users/users-api-interface';
import { appointmentTimesToDate } from 'core/utilities/date-helpers';
import { useState, useCallback, useEffect } from 'react';
import SharedCalendarAppointment from '../calendar-appointment/calendar-appointment';
import { getAppointment60MinuteTimeSlots } from 'core/utilities/appointment-helpers';
import dayjs from 'dayjs';

interface IWeeklyCalendarRow {
  resource: IUser;
  appointments: IAppointment[];
  days: { label: string; date: dayjs.Dayjs }[];
  showAppointmentMenu: boolean;
  resourceColumnWidth: number;
  columnWidth: number;
  rowHeight: number;
}

const WeeklyCalendarRow = ({
  resource,
  appointments,
  showAppointmentMenu,
  days,
  resourceColumnWidth,
  columnWidth,
  rowHeight,
}: IWeeklyCalendarRow) => {
  const timeSlots = getAppointment60MinuteTimeSlots();
  const [groups, setGroups] = useState<IAppointment[][][]>([]);
  const collides = useCallback((a: IAppointment, b: IAppointment) => {
    const aDates = appointmentTimesToDate(a);
    const bDates = appointmentTimesToDate(b);
    return aDates.end > bDates.start && aDates.start < bDates.end;
  }, []);

  useEffect(() => {
    let g: IAppointment[][][] = [];
    let columns: IAppointment[][] = [];
    let lastEventEnding: Date | undefined;
    appointments
      .sort((a, b) => {
        const aDates = appointmentTimesToDate(a);
        const bDates = appointmentTimesToDate(b);
        if (aDates.start < bDates.start) return -1;
        if (aDates.start < bDates.start) return 1;
        if (aDates.end < bDates.end) return -1;
        if (aDates.end > bDates.end) return 1;
        return 0;
      })
      .forEach((e) => {
        const eDates = appointmentTimesToDate(e);
        // Check if a new event group needs to be started.
        if (lastEventEnding && eDates.start >= lastEventEnding) {
          // The event is later than any of the events in the
          // current group. There is no overlap. Output the
          // current event group and start a new one.
          g.push(columns);
          columns = [];
          lastEventEnding = undefined;
        }

        // Try to place the event inside an existing column.
        let placed = false;
        columns.some((col) => {
          if (!collides(col[col.length - 1], e)) {
            col.push(e);
            placed = true;
          }
          return placed;
        });

        // It was not possible to place the event (it overlaps
        // with events in each existing column). Add a new column
        // to the current event group with the event in it.
        if (!placed) columns.push([e]);

        // Remember the last event end time of the current group.
        if (!lastEventEnding || eDates.end > lastEventEnding) lastEventEnding = eDates.end;
      });
    g.push(columns);
    setGroups(g);
  }, [collides, appointments]);

  const expand = (a: IAppointment, index: number, cols: IAppointment[][]) => {
    let colSpan = 1;
    cols.slice(index + 1).some((col) => {
      if (col.some((app) => collides(a, app))) return true;
      colSpan += 1;
      return false;
    });
    return colSpan;
  };

  const getDayIndex = (date: string) => {
    return days.findIndex((day) => day.date.isSame(date, 'day'));
  };

  return (
    <div className='relative flex' style={{ height: `${rowHeight}px` }}>
      <div
        style={{ minWidth: `${resourceColumnWidth}px` }}
        className='body-02 bg-slate-100 p-4 h-full flex items-center font-semibold sticky left-0 border-b border-r border-r-gray-400 z-20'
      >
        {resource.fullName}
      </div>
      {groups.map((cols) =>
        cols.map((col, index) =>
          col.map((appointment) => (
            <SharedCalendarAppointment
              dayIndex={getDayIndex(appointment.date)}
              weekly={true}
              key={appointment.uid}
              appointment={appointment}
              widthPercent={expand(appointment, index, cols) / cols.length}
              leftPercent={index / cols.length}
              showMenu={showAppointmentMenu}
              columnWidth={columnWidth}
              resourceColumnWidth={resourceColumnWidth}
            />
          ))
        )
      )}
      {days.map((day) => (
        <div className='flex' key={`${resource.uid}${day.label}`}>
          {timeSlots.map((timeSlot) => (
            <div
              key={`${day.label}${timeSlot}`}
              style={{ minWidth: `${columnWidth / 11}px` }}
              className='border-r border-b last:border-r-gray-400  h-full'
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default WeeklyCalendarRow;
