import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { IAudiogramDiagramValues } from '../audiogram-form-interface';
import LineTo from 'react-lineto';
import clsx from 'clsx';
import Select from '@atlaskit/select';
import { SelectOption } from 'core/utilities/interface-helpers';
import rightAC from 'assets/images/audiogram-icons/rightAC.svg';
import rightACMask from 'assets/images/audiogram-icons/rightACMask.svg';
import rightUCL from 'assets/images/audiogram-icons/rightUCL.svg';
import rightBC from 'assets/images/audiogram-icons/rightBC.svg';
import rightBCMask from 'assets/images/audiogram-icons/rightBCMask.svg';
import leftAC from 'assets/images/audiogram-icons/leftAC.svg';
import leftACMask from 'assets/images/audiogram-icons/leftACMask.svg';
import leftUCL from 'assets/images/audiogram-icons/leftUCL.svg';
import leftBC from 'assets/images/audiogram-icons/leftBC.svg';
import leftBCMask from 'assets/images/audiogram-icons/leftBCMask.svg';
import bilAC from 'assets/images/audiogram-icons/bilAC.svg';
import bilACMask from 'assets/images/audiogram-icons/bilACMask.svg';
import leftNR from 'assets/images/audiogram-icons/leftNR.svg';
import rightNR from 'assets/images/audiogram-icons/rightNR.svg';

interface IAudiogramDiagram {
  currentMarker: string;
  currentEar: string;
  onValueChange: (x: number, y: number) => void;
  audiogramValues: IAudiogramDiagramValues;
  setCurrentMarker: Dispatch<SetStateAction<string>>;
  setCurrentEar: Dispatch<SetStateAction<string>>;
  showFormError: (fieldKey: string, formKey?: string) => boolean;
  viewOnly?: boolean;
  dimensions: { height: string; width: string };
  id: string;
}

interface IAudiogramDiagramLine {
  from: string;
  to: string;
  color: string;
}

const AudiogramDiagram = ({
  currentEar,
  currentMarker,
  onValueChange,
  audiogramValues,
  setCurrentMarker,
  setCurrentEar,
  showFormError,
  viewOnly = false,
  dimensions,
  id,
}: IAudiogramDiagram) => {
  const [lines, setLines] = useState<IAudiogramDiagramLine[]>([]);
  const xAxisValues = [125, 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000, 16000];
  const yAxisValues = [
    -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120,
    125, 130,
  ];
  const yHeight = 100 / yAxisValues.length;
  const xWidth = 100 / xAxisValues.length;

  useEffect(() => {
    const lines: IAudiogramDiagramLine[] = [];
    Object.entries(audiogramValues).forEach(([earKey, ear]) => {
      Object.entries(ear).forEach(([key, value]) => {
        const isNR = ['acNR', 'acMaskNR', 'bcNR', 'bcMaskNR'].some((markerType) => markerType === key);
        if (value && !isNR) {
          const markers = Object.keys(value).filter((marker) => value[parseInt(marker)] !== null);
          if (markers.length > 1) {
            let color: string;

            switch (earKey) {
              case 'left':
                color = 'blue';
                break;
              case 'right':
                color = 'red';
                break;
              case 'bil':
                color = 'green';
            }

            markers.forEach((marker, index) => {
              if (index === markers.length - 1) {
                return;
              }
              lines.push({
                from: `${id}${earKey}${key}${markers[index]}`,
                to: `${id}${earKey}${key}${markers[index + 1]}`,
                color,
              });
            });
          }
        }
      });
    });

    setLines(lines);
  }, [audiogramValues, id]);

  const audiogramEarOptions = [
    {
      value: 'left',
      label: 'Left',
    },
    {
      value: 'right',
      label: 'Right',
    },
    {
      value: 'bil',
      label: 'Bilateral',
    },
  ];

  const audiogramMarkerOptions = [
    {
      value: 'ac',
      label: 'AC',
    },
    {
      value: 'acMask',
      label: 'AC Masked',
    },
    {
      value: 'acNR',
      label: 'AC NR',
    },
    {
      value: 'acMaskNR',
      label: 'AC Masked NR',
    },
    {
      value: 'ucl',
      label: 'UCL',
    },
    {
      value: 'bc',
      label: 'BC',
    },
    {
      value: 'bcMask',
      label: 'BC Masked',
    },
    {
      value: 'bcNR',
      label: 'BC NR',
    },
    {
      value: 'bcMaskNR',
      label: 'BC Masked NR',
    },
  ];

  const shading = [
    {
      start: 0,
      height: 6,
      color: '#FFFFFF',
      label: 'Normal',
    },
    {
      start: 6,
      height: 3,
      color: '#DDF9DE',
      label: 'Slight',
    },
    {
      start: 9,
      height: 3,
      color: '#FDF9D8',
      label: 'Mild',
    },
    {
      start: 12,
      height: 3,
      color: '#F3E6CC',
      label: 'Moderate',
    },
    {
      start: 15,
      height: 3,
      color: '#F3DACC',
      label: 'Moderately severe',
    },
    {
      start: 18,
      height: 4,
      color: '#F3CCCC',
      label: 'Severe',
    },
    {
      start: 22,
      height: 8,
      color: '#F8B3B3',
      label: 'Profound',
    },
  ];

  const getMarkerOptions = () => {
    if (currentEar === 'bil') {
      return audiogramMarkerOptions.slice(0, 2);
    }

    return audiogramMarkerOptions;
  };

  const selectionFields = [
    {
      key: 'ear',
      options: audiogramEarOptions,
      onChange: setCurrentEar,
      label: 'Ear',
      value: currentEar,
    },
    {
      key: 'marker',
      options: getMarkerOptions(),
      onChange: setCurrentMarker,
      label: 'Type',
      value: currentMarker,
    },
  ];

  const getMarkerIcon = (ear: string, type: string) => {
    const isNR = ['acNR', 'acMaskNR', 'bcNR', 'bcMaskNR'].some((markerType) => markerType === type);
    let imagePath: string = leftAC;
    let nrPath: string = leftNR;
    switch (ear) {
      case 'left':
        switch (type) {
          case 'ac':
          case 'acNR':
            imagePath = leftAC;
            break;
          case 'acMask':
          case 'acMaskNR':
            imagePath = leftACMask;
            break;
          case 'ucl':
            imagePath = leftUCL;
            break;
          case 'bc':
          case 'bcNR':
            imagePath = leftBC;
            break;
          case 'bcMask':
          case 'bcMaskNR':
            imagePath = leftBCMask;
            break;
        }
        break;
      case 'bil':
        switch (type) {
          case 'ac':
            imagePath = bilAC;
            break;
          case 'acMask':
            imagePath = bilACMask;
            break;
        }
        break;
      case 'right':
        nrPath = rightNR;
        switch (type) {
          case 'ac':
          case 'acNR':
            imagePath = rightAC;
            break;
          case 'acMask':
          case 'acMaskNR':
            imagePath = rightACMask;
            break;
          case 'ucl':
            imagePath = rightUCL;
            break;
          case 'bc':
          case 'bcNR':
            imagePath = rightBC;
            break;
          case 'bcMask':
          case 'bcMaskNR':
            imagePath = rightBCMask;
            break;
        }
        break;
    }

    const icon = <img src={imagePath} alt={`${ear}${type}`} />;

    if (isNR) {
      const directionTranslate = ear === 'left' ? '-right-[9px]' : '-left-[9px]';
      return (
        <div className='relative'>
          <img className={`absolute -bottom-[9px] ${directionTranslate}`} src={nrPath} alt={`${ear}${type}NR`} />
          {icon}
        </div>
      );
    }

    return icon;
  };

  const getButton = (yValue: number, xValue: number, yIndex: number, xIndex: number) => {
    if (yValue < -10 || yValue > 120 || xValue > 8000) {
      return;
    }

    const baseStyle = clsx(
      !viewOnly && 'hover:opacity-100 cursor-pointer transition-all ease-in-out',
      'absolute h-[24px] w-[24px] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center'
    );

    const markerElement = (earKey: string, markerKey: string, styles: string) => (
      <div
        className={styles}
        style={{ top: `${yHeight * (yIndex + 1)}%`, left: `${xWidth * (xIndex + 1)}%` }}
        onClick={() => onValueChange(xValue, yValue)}
      >
        {getMarkerIcon(earKey, markerKey)}
      </div>
    );

    const elementsToPresent = [];

    if (!viewOnly) {
      elementsToPresent.push(markerElement(currentEar, currentMarker, `${baseStyle} opacity-0 z-10`));
    }

    Object.entries(audiogramValues).forEach(([earKey, ear]) => {
      Object.entries(ear).forEach(([key, value]) => {
        if (!value) {
          return;
        }

        if (value[xValue] === yValue) {
          elementsToPresent.push(markerElement(earKey, key, `${baseStyle} ${id}${earKey}${key}${xValue}`));
        }
      });
    });

    return elementsToPresent.map((element, index) => (
      <Fragment key={`button${id}${yValue}${xValue}${index}`}>{element}</Fragment>
    ));
  };

  const getYLabel = (val: number, index: number) => {
    if (val < -10 || val > 120 || val % 10 !== 0) {
      return;
    }

    return (
      <div className='absolute -translate-y-[50%]' style={{ top: `${yHeight * (index + 1)}%` }}>
        {val}
      </div>
    );
  };

  const getXLabel = (val: number, index: number) => {
    if (val > 8000) {
      return;
    }

    return (
      <div className='absolute -translate-x-[50%]' style={{ left: `${xWidth * (index + 1)}%` }}>
        {val}
      </div>
    );
  };

  const audiogramDiagram = (
    <div style={{ height: dimensions.height }} className='flex pb-6' id={id}>
      <div className='relative w-[45px]'>
        {yAxisValues.map((y, index) => (
          <Fragment key={`yLabel${y}`}>{getYLabel(y, index)}</Fragment>
        ))}
      </div>
      <div className='w-full flex flex-col border-2 border-black'>
        <div className={`h-full bg-white relative ${id}`}>
          {shading.map((shade) => (
            <div
              key={shade.label}
              className='absolute w-full flex justify-end'
              style={{
                height: `${shade.height * yHeight}%`,
                backgroundColor: shade.color,
                top: `${shade.start * yHeight}%`,
              }}
            >
              <p className='text-sm opacity-30 p-1 z-10'>{shade.label}</p>
            </div>
          ))}
          <div className='absolute w-full h-full'>
            {yAxisValues.map((y) => (
              <div
                key={`yDivider${y}`}
                className={`w-full border-b last:border-0 border-slate-400 ${y % 10 === 0 ? '' : 'opacity-30'}`}
                style={{ height: `${yHeight}%` }}
              ></div>
            ))}
          </div>
          <div className='absolute w-full h-full flex'>
            {xAxisValues.map((x) => (
              <div
                key={`xDivider${x}`}
                className='h-full border-r last:border-0 border-slate-400'
                style={{ width: `${xWidth}%` }}
              ></div>
            ))}
          </div>
          {yAxisValues.map((y, yi) => (
            <Fragment key={`yButtonRow${y}`}>
              {xAxisValues.map((x, xi) => (
                <Fragment key={`xButton${x}`}>{getButton(y, x, yi, xi)}</Fragment>
              ))}
            </Fragment>
          ))}
          {lines.map((line) => (
            <LineTo
              key={`${line.to}${line.from}`}
              to={line.to}
              from={line.from}
              borderColor={line.color}
              borderWidth={2}
              within={id}
            />
          ))}
        </div>
        <div className='relative'>
          {xAxisValues.map((x, index) => (
            <Fragment key={`xLabel${x}`}>{getXLabel(x, index)}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );

  return viewOnly ? (
    <div style={{ width: dimensions.width }}>{audiogramDiagram}</div>
  ) : (
    <div className='bg-white shadow-md rounded-md mt-4 overflow-x-auto h-fit' style={{ width: dimensions.width }}>
      <div className='p-4 border-b headline-06 flex items-center justify-between'>
        <p>Audiogram</p>
        {showFormError('audiogram') && (
          <p className='mt-1 label-02 text-red-600'>The audiogram is invalid, please ensure all values are completed</p>
        )}
      </div>
      <div className='p-4'>
        <div className='grid grid-cols-2 gap-4 mb-4 z-20 relative'>
          {selectionFields.map((field) => (
            <div key={field.key}>
              <p className='label-02 font-semibold mb-1 text-gray-500'>{field.label}</p>
              <Select<SelectOption>
                options={field.options}
                isSearchable={false}
                onChange={(value) => {
                  if (value) {
                    field.onChange(value.value);
                  }
                }}
                value={field.options.find((op) => op.value === field.value) ?? null}
              />
            </div>
          ))}
        </div>
        {audiogramDiagram}
      </div>
    </div>
  );
};

export default AudiogramDiagram;
