import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { useAuthState } from 'core/providers/AuthProvider';
import { getObjectChanges } from 'core/utilities/object-helpers';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { getAppointmentTypeNameFromKey } from 'core/constants/appointment-type';

interface IConfirmAppointmentDialog {
  appointment: IAppointment;
}

const ConfirmAppointmentDialog = ({ appointment }: IConfirmAppointmentDialog) => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const { userData } = useAuthState();

  const confirmAppointment = async () => {
    setLoading(true);
    const payload = {
      confirmedByOffice: true,
    };
    try {
      await AppointmentsApiService.update(
        appointment.uid,
        appointment.customer.uid,
        {
          fullName: userData?.fullName!,
          uid: userData?.uid!,
        },
        {
          confirmedByOffice: true,
        },
        getObjectChanges(appointment, payload)
      );
      await CustomersApiService.setCustomerNote({
        uid: uuidv4(),
        content: `${getAppointmentTypeNameFromKey(appointment.type)} for ${dayjs(appointment.date).format(
          'DD/MM/YYYY'
        )} at ${appointment.start} was marked as confirmed by ${userData?.fullName}`,
        createdAt: new Date(),
        authorName: 'ILAH Bot',
        authorUid: '',
        customerName: appointment.customer.fullName,
        customerUid: appointment.customer.uid,
      });
      showSuccessFlag(
        'Appointment confirmed',
        `The appointment for ${appointment.customer.fullName} has been marked as confirmed`,
        flags
      );
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      showErrorFlag(
        'Appointment confirmation failed',
        `The appointment could not be marked as confirmed due to an error, please try again`,
        flags
      );
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Cancel',
        type: 'button',
      },
    },
    {
      key: 'confirmAppointment',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => confirmAppointment(),
        appearance: 'primary',
        label: 'Confirmed',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={`Mark appointment for ${appointment.customer.fullName} as confirmed?`}
      textContent='Are you sure you want to mark this appointment as confirmed?'
      customButtons={customButtons}
    />
  );
};

export default ConfirmAppointmentDialog;
