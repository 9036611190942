import { StringIndexable } from 'core/utilities/interface-helpers';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import { ISharedTableColumn } from '../table-interface';
import { ISharedTableBody } from './table-body-interface';

const SharedTableBody = <T extends StringIndexable>({
  columns,
  rows,
  templates = [],
  isLoading,
}: ISharedTableBody<T>) => {
  const findTemplate = (rowData: T, column: ISharedTableColumn) => {
    const cellTemplate = templates.find((template) => template.id === column.templateId);
    return cellTemplate?.template(column.templateId === 'text' ? rowData[column.key] : rowData);
  };

  return (
    <tbody>
      {isLoading ? (
        <tr className='h-[56px]'>
          {columns.map((column) => (
            <td key={column.key} className='body-02 p-3 border-t'>
              <SkeletonElement width='50%' height='1.25rem' />
            </td>
          ))}
        </tr>
      ) : (
        <>
          {rows.map((row) => (
            <tr key={row.key} className='hover:bg-gray-50 h-[56px]'>
              {columns.map((column) => (
                <td key={column.key} className='body-02 p-3 border-t'>
                  {findTemplate(row.data, column)}
                </td>
              ))}
            </tr>
          ))}
        </>
      )}
    </tbody>
  );
};

export default SharedTableBody;
