import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { AppointmentStatus } from 'core/constants/appointment-status';
import {
  AppointmentType,
  getAppointmentStyle,
  getAppointmentTypeNameFromKey,
  getWeeklyAppointmentStyle,
} from 'core/constants/appointment-type';
import { CustomerStatus } from 'core/constants/customer-status';
import { Check, X } from 'react-feather';
import SharedCalendarAppointmentDropdownMenu from '../calendar-appointment-dropdown-menu/calendar-appointment-dropdown-menu';
import dayjs from 'dayjs';
import { doesAppointmentNeedConfirmation, isShopAppointment } from 'core/utilities/appointment-helpers';
import { getClinicNameFromKey } from 'core/constants/clinic';

export interface ISharedCalendarAppointment {
  appointment: IAppointment;
  showMenu?: boolean;
  widthPercent: number;
  leftPercent: number;
  weekly: boolean;
  dayIndex?: number;
  columnWidth?: number;
  resourceColumnWidth?: number;
}

const SharedCalendarAppointment = ({
  appointment,
  showMenu,
  widthPercent,
  leftPercent,
  weekly,
  dayIndex,
  columnWidth,
  resourceColumnWidth,
}: ISharedCalendarAppointment) => {
  const { start, end, type, customer, uid, status, clinic } = appointment;
  const hideAddress = [
    AppointmentType.SHOP_FITTING,
    AppointmentType.SHOP_TEST,
    AppointmentType.SHOP_SERVICE,
    AppointmentType.SHOP_WAX_REMOVAL,
    AppointmentType.ADMIN,
  ].some((appType) => appType === type);

  const getAppointmentTag = () => {
    if (!showMenu) return null;

    const defaultStyle = 'p-2 flex items-center';
    let showTag = false;
    let tagColor = null;
    let tagText = null;
    let tagIcon = null;

    if (appointment.prescribed) {
      tagColor = 'bg-green-600';
      tagText = 'Prescribed';
      tagIcon = <Check size={16} />;
      showTag = true;
    } else {
      switch (appointment.customer.status) {
        case CustomerStatus.TESTED_NOT_PRESCRIBED:
          tagColor = 'bg-orange-700';
          tagText = 'TNP';
          tagIcon = <X size={16} />;
          showTag = true;
          break;
        case CustomerStatus.SERVICE_COMPLETE:
          tagColor = 'bg-green-600';
          tagText = 'Complete';
          tagIcon = <Check size={16} />;
          showTag = true;
          break;
        case CustomerStatus.FITTING_COMPLETE:
          tagColor = 'bg-green-600';
          tagText = 'Fitted';
          tagIcon = <Check size={16} />;
          showTag = true;
          break;
        case CustomerStatus.FITTED_AWAITING_UPLOAD:
          tagColor = 'bg-green-600';
          tagText = 'Fitted A-U';
          tagIcon = <Check size={16} />;
          showTag = true;
          break;
        case CustomerStatus.FITTED_AWAITING_DOC_CERT:
          tagColor = 'bg-green-600';
          tagText = 'Fitted A-DR';
          tagIcon = <Check size={16} />;
          showTag = true;
          break;
        case CustomerStatus.CLOSED_SPREAD_PAYMENT:
          tagColor = 'bg-green-600';
          tagText = 'Fitted SP';
          tagIcon = <Check size={16} />;
          showTag = true;
      }
    }

    return showTag ? (
      <div className={`${defaultStyle} ${tagColor}`}>
        <p className='mr-1 body-03 font-semibold whitespace-nowrap'>{tagText}</p>
        {tagIcon}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div
      key={uid}
      className={`group absolute w-full rounded-md shadow-sm text-white z-10 overflow-y-auto appointment ${
        appointment.status === AppointmentStatus.CANCELLED && 'line-through'
      } ${appointment.priority && 'border-4 border-dashed border-gray-900'}`}
      style={
        weekly
          ? getWeeklyAppointmentStyle(
              start,
              end,
              type,
              status,
              widthPercent,
              leftPercent,
              dayIndex ?? 0,
              columnWidth!,
              resourceColumnWidth!
            )
          : getAppointmentStyle(start, end, type, status, widthPercent, leftPercent)
      }
    >
      {doesAppointmentNeedConfirmation(appointment) && (
        <div
          className='p-2 flex items-center'
          style={{ background: 'repeating-linear-gradient(45deg, #f97316, #f97316 10px, #ea580c 10px, #ea580c 20px' }}
        >
          <p className='mr-1 body-03 font-semibold whitespace-nowrap'>{`Unconfirmed (${dayjs().diff(
            dayjs(appointment.createdAt.toDate()),
            'days'
          )}d ago)`}</p>
        </div>
      )}
      {getAppointmentTag()}
      <div className='p-2'>
        {showMenu && (
          <SharedCalendarAppointmentDropdownMenu
            className='absolute top-2 right-2'
            customer={customer}
            appointment={appointment}
            showViewCustomerButton
            needsConfirming={doesAppointmentNeedConfirmation(appointment)}
          />
        )}
        <p className='body-02 font-semibold'>{customer.fullName}</p>
        <p className='body-03'>
          {start} - {end}
        </p>
        {!hideAddress && <p className='body-03'>{customer.address?.formatted_address}</p>}
        <p className='body-03 opacity-70'>
          <span>{getAppointmentTypeNameFromKey(type)}</span>
          {isShopAppointment(type) && <span>{` - ${getClinicNameFromKey(clinic ?? '')} clinic`}</span>}
        </p>
      </div>
    </div>
  );
};

export default SharedCalendarAppointment;
