import UsersApiService from 'core/api/users/users-api.service';
import { auth } from 'core/config/firebase';
import { SetPasswordFormFields } from 'core/config/form-fields';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { assertIsError } from 'core/utilities/asset-is-error';
import { ErrorMappingHelper } from 'core/utilities/error-mapping-helper';
import { getObjectChanges } from 'core/utilities/object-helpers';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { useEffect, useState } from 'react';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';

const SetPasswordDialog = () => {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { user, userData } = useAuthState();

  useEffect(() => {
    if (userData?.resetPassword === false) {
      dialog?.closeDialog();
    }
  }, [dialog, userData?.resetPassword]);

  const onFormSubmit = async (data: any) => {
    setError(undefined);
    try {
      setLoading(true);

      const { uid, email } = user!;

      await signInWithEmailAndPassword(auth, email!, data.currentPassword);

      if (data.newPassword !== data.confirmNewPassword) {
        throw new Error('Passwords do not match');
      }

      await updatePassword(user!, data.newPassword);
      const payload = {
        resetPassword: false,
      };
      await UsersApiService.update(
        uid,
        userData!.fullName,
        payload,
        {
          fullName: userData!.fullName,
          uid: userData!.uid,
        },
        getObjectChanges(payload, userData)
      );
    } catch (error) {
      setLoading(false);
      const assertedError = assertIsError(error);
      setError(ErrorMappingHelper(assertedError, 'Failed to update user password, please try again'));
    }
  };

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <p className='body-02 mb-4'>You are required to set a new password in order to continue.</p>
        <SharedForm
          onSubmit={onFormSubmit}
          fields={SetPasswordFormFields}
          buttonLabel='Submit'
          loading={loading}
          formErrorMessage={error}
        />
      </div>
    );
  };

  return <SharedDialogBase title='Set your password' customContentTemplate={customContent()} showButtons={false} />;
};

export default SetPasswordDialog;
