import { Navigate, Route, Routes } from 'react-router-dom';
import AddEditAppointmentForm from './add-edit-appointment/add-edit-appointment-form';
import AppointmentsList from './appointments-list/appointments-list';

const AppointmentsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AppointmentsList />} />
      <Route path='new' element={<AddEditAppointmentForm />} />
      <Route path='edit/:appointmentId' element={<AddEditAppointmentForm />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AppointmentsRouting;
