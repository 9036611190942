export const CustomerTitles = [
  {
    label: 'Mr',
    value: 'mr',
  },
  {
    label: 'Mrs',
    value: 'mrs',
  },
  {
    label: 'Miss',
    value: 'miss',
  },
  {
    label: 'Ms',
    value: 'ms',
  },
  {
    label: 'Dr',
    value: 'dr',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const getCustomerTitleNameFromKey = (key: string) => {
  const title = CustomerTitles.find((title) => title.value === key);
  return title?.label ?? 'Unknown';
};
