import { DatePicker } from '@atlaskit/datetime-picker';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { IUser } from 'core/api/users/users-api-interface';
import { useResponsiveState } from 'core/providers/ResponsiveProvider';
import dayjs from 'dayjs';
import { ChevronLeft, ChevronRight } from 'react-feather';
import CalendarColumn from './calendar-column/calendar-column';
import ProgressBar from '@atlaskit/progress-bar';

export interface ISharedCalendar {
  timeSlots: string[];
  resources: IUser[];
  appointments: IAppointment[];
  date: string;
  changeDate: Function;
  highlightedResource?: string;
  newAppointment?: {
    start: string;
    end: string;
  };
  minDate?: string;
  showDatePicker?: boolean;
  showAppointmentMenu?: boolean;
  isLoading?: boolean;
}

const SharedCalendar = ({
  timeSlots,
  resources,
  appointments,
  date,
  changeDate,
  highlightedResource,
  newAppointment,
  minDate,
  showDatePicker = false,
  showAppointmentMenu = false,
  isLoading = false,
}: ISharedCalendar) => {
  const { isMobile } = useResponsiveState();

  const goForward = () => {
    const nextDay = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
    changeDate(nextDay);
  };

  const goBackward = () => {
    const previousDay = dayjs(date).add(-1, 'day').format('YYYY-MM-DD');
    changeDate(previousDay);
  };

  const currentTimeLinePosition = () => {
    const calculatedMargin = (dayjs().hour() - 9) * 60 + dayjs().minute() + 56;
    if (calculatedMargin < 57) {
      return '57px';
    }
    if (calculatedMargin > 715) {
      return '715px';
    }
    return `${calculatedMargin}px`;
  };

  return (
    <>
      {showDatePicker && isMobile && (
        <div className='p-3'>
          <DatePicker
            value={dayjs(date).format('YYYY-MM-DD')}
            formatDisplayLabel={() => 'Select date'}
            onChange={(date) => changeDate(date)}
            hideIcon
            selectProps={{ isSearchable: false }}
          />
        </div>
      )}
      <div className='p-3 border-b items-center flex relative'>
        {(minDate === undefined || date > minDate) && (
          <button
            className='absolute left-3 p-3 rounded-full hover:bg-gray-100 opacity-80'
            onClick={() => goBackward()}
          >
            <ChevronLeft />
          </button>
        )}
        {showDatePicker && !isMobile && (
          <div className='absolute left-20 w-[105px]'>
            <DatePicker
              value={dayjs(date).format('YYYY-MM-DD')}
              formatDisplayLabel={() => 'Select date'}
              onChange={(date) => changeDate(date)}
              hideIcon
              selectProps={{ isSearchable: false }}
            />
          </div>
        )}
        <div className='text-center flex-grow p-3'>{dayjs(date).format('dddd, D MMMM YYYY')}</div>
        <button className='p-3 absolute right-3 rounded-full hover:bg-gray-100 opacity-80' onClick={() => goForward()}>
          <ChevronRight />
        </button>
      </div>
      {isLoading && <ProgressBar ariaLabel='Loading appointments' isIndeterminate />}
      <div className='flex'>
        <div className='min-w-[56px] relative pr-[8px]'>
          <div className='h-[56px]' />
          {timeSlots.map((slot) => (
            <div className='h-[60px] relative' key={slot}>
              <p className='body-02 text-gray-500 -top-[10px] absolute right-0'> {slot}</p>
            </div>
          ))}
        </div>
        <div className='border-l w-full flex overflow-auto relative'>
          {dayjs().format('YYYY-MM-DD') === date && (
            <div
              className='absolute w-full h-px bg-red-600 z-30'
              style={{
                top: currentTimeLinePosition(),
              }}
            />
          )}
          <div className='flex overflow-auto w-full'>
            {resources?.map((resource) => (
              <CalendarColumn
                key={resource.uid}
                resource={resource}
                columnAppointments={appointments.filter((app) => app.assignee.uid === resource.uid)}
                timeSlots={timeSlots}
                highlightedResource={highlightedResource}
                newAppointment={newAppointment}
                showAppointmentMenu={showAppointmentMenu}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedCalendar;
