import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import dayjs from 'dayjs';

interface IOrderFormDetails {
  appointment: IAppointment;
}

const OrderFormDetails = ({ appointment }: IOrderFormDetails) => {
  const orderDetails = [
    {
      key: 'advisor',
      label: "Hearing advisor's name:",
      value: appointment.assignee.fullName,
    },
    {
      key: 'name',
      label: "Customer's name:",
      value: appointment.customer.fullName,
    },
    {
      key: 'address',
      label: 'Address:',
      value: appointment.customer.address?.formatted_address,
    },
    {
      key: 'emailAddress',
      label: 'Email address:',
      value: isNotNullOrEmpty(appointment.customer.emailAddress) ? appointment.customer.emailAddress : 'Not provided',
    },
    {
      key: 'phoneNumber',
      label: 'Phone number:',
      value: appointment.customer.phoneNumber,
    },
    {
      key: 'orderDate',
      label: 'Order date:',
      value: dayjs(new Date()).format('DD/MM/YYYY'),
    },
  ];

  return (
    <div className='bg-white w-full rounded-md shadow-md h-fit mt-4 lg:sticky lg:top-4'>
      <div className='flex justify-between items-center p-4 border-b headline-06'>Order details</div>
      <div className='p-4'>
        {orderDetails.map((details) => (
          <div key={details.key} className='mt-4 first:mt-0'>
            <p className='body-02 text-gray-500'>{details.label}</p>
            <p className='body-01 break-all'>{details.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderFormDetails;
