import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './login';

const LoginRouting = () => {
  return (
    <Routes>
      <Route path='' element={<Login />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default LoginRouting;
