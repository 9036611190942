import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import { useAuthState } from 'core/providers/AuthProvider';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { ISendAppointmentConfirmationRequestDto } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { AppointmentType } from 'core/constants/appointment-type';

interface IAppointmentConfirmationDialog {
  customer: ICustomer;
  date: string;
  start: string;
  type: string;
  appointmentUId: string;
}

interface IAppointmentConfirmationDialogActionState extends StringIndexable {
  sms: boolean;
  email?: boolean;
}

const AppointmentConfirmationDialog = ({
  customer,
  date,
  start,
  type,
  appointmentUId,
}: IAppointmentConfirmationDialog) => {
  const actionState: IAppointmentConfirmationDialogActionState = {
    sms: false,
  };
  const emailAvailable =
    customer.emailAddress && [AppointmentType.HOME_TEST, AppointmentType.SHOP_TEST].some((t) => type === t);
  if (emailAvailable) {
    actionState.email = false;
  }
  const [loading, setLoading] = useState<IAppointmentConfirmationDialogActionState>(actionState);
  const [completed, setCompleted] = useState<IAppointmentConfirmationDialogActionState>(actionState);

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const { userData } = useAuthState();

  const handleSelection = async (action: 'sms' | 'email') => {
    const { uid, fullName, emailAddress, phoneNumber } = customer;
    const payload: ISendAppointmentConfirmationRequestDto = {
      customerUid: uid,
      emailAddress: emailAddress!,
      date,
      start,
      fullName,
      appointmentUid: appointmentUId,
      userFullName: userData?.fullName ?? 'Independent Living and Hearing Ltd',
      phoneNumber,
      type,
    };
    setLoading({ ...actionState, [action]: true });
    try {
      switch (action) {
        case 'email':
          await AppointmentsApiService.sendConfirmationEmail(payload);
          break;
        case 'sms':
          await AppointmentsApiService.sendConfirmationSMS(payload);
          break;
        default:
          throw new Error('Incorrect action type');
      }
      const newState = { ...completed, [action]: true };
      setCompleted(newState);
      setLoading(actionState);
      showSuccessFlag(
        'Appointment confirmation sent',
        `The appointment confirmation ${action} was successfully sent to ${fullName}`,
        flags
      );
      if (Object.values(newState).every((bool) => bool)) {
        dialog?.closeDialog();
      }
    } catch (error) {
      setLoading(actionState);
      showErrorFlag(
        'Appointment confirmation failed to send',
        `Unable to send appointment confirmation ${action}, please try again.`,
        flags
      );
    }
  };

  const anyLoading = Object.values(loading).some((bool) => bool);

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'close',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Close',
        type: 'button',
        disabled: anyLoading,
      },
    },
    {
      key: 'sms',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading.sms,
        onClick: () => handleSelection('sms'),
        appearance: 'primary',
        label: completed.sms ? 'SMS sent' : 'Send SMS',
        type: 'button',
        disabled: anyLoading || completed.sms,
      },
    },
  ];

  if (emailAvailable) {
    customButtons.splice(2, 0, {
      key: 'email',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading.email,
        onClick: () => handleSelection('email'),
        appearance: 'primary',
        label: completed.email ? 'Email sent' : 'Send email',
        type: 'button',
        disabled: anyLoading || completed.email,
      },
    });
  }

  return (
    <SharedDialogBase
      title={`Send appointment confirmation?`}
      textContent='Would you like to send an appointment confirmation email or sms to this customer?'
      customButtons={customButtons}
    />
  );
};

export default AppointmentConfirmationDialog;
