import { ReactNode, useState } from 'react';
import Popup from '@atlaskit/popup';
interface ISharedPopupCell {
  previewContent: ReactNode;
  popupContent: ReactNode;
  hover?: boolean;
}

const SharedPopupCell = ({ previewContent, popupContent, hover = false }: ISharedPopupCell) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={() => popupContent}
      trigger={(triggerProps) => (
        <div
          {...triggerProps}
          onMouseEnter={() => (hover ? setIsOpen(true) : null)}
          onMouseLeave={() => (hover ? setIsOpen(false) : null)}
          onClick={() => (hover ? null : setIsOpen(!isOpen))}
          className='flex items-center cursor-pointer'
        >
          {previewContent}
        </div>
      )}
      placement='bottom-start'
    />
  );
};

export default SharedPopupCell;
