import { IUser } from 'core/api/users/users-api-interface';
import { getRoleNameFromKey } from 'core/config/roles';
import { useDialog } from 'core/providers/DialogProvider';
import { PageElement } from 'core/utilities/interface-helpers';
import SharedButton from 'shared/components/buttons/button';
import PageHeader from 'shared/components/page-header/page-header';
import SharedTable from 'shared/components/table/table';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import DeleteUserDialog from '../delete-user-dialog/delete-user-dialog';
import { useSelector } from 'react-redux';
import { UsersSlice } from '../users-slice';
import AddEditUserDialog from '../add-user/add-edit-user-dialog/add-edit-user-dialog';
import { useNavigate } from 'react-router-dom';

const UsersTableColumns = [
  { label: 'User', key: 'user', templateId: 'user', width: 45 },
  { label: 'Roles', key: 'rolesString', templateId: 'text', width: 45 },
  { label: '', key: 'action', templateId: 'action', width: 10 },
];

const bookableRoles: string[] = ['audiologist', 'hearingAidDispenser', 'hearingSpecialist', 'waxSpecialist'];

const UsersList = () => {
  // Hooks
  const dialog = useDialog();
  const users = useSelector(UsersSlice.selectAll);
  const navigate = useNavigate();

  // Page specifics
  const userCellTemplate = (user: IUser) => {
    return (
      <div className='flex'>
        <div className='h-10 w-10 rounded-full bg-primary mr-3 flex items-center justify-center text-white body-01'>
          {user.fullName.charAt(0).toUpperCase()}
        </div>
        <div className='flex flex-col'>
          <p>{user.fullName}</p>
          <p className='text-gray-400'>{user.emailAddress}</p>
        </div>
      </div>
    );
  };
  const actionCellTemplate = (user: IUser) => {
    return (
      <div className='w-full flex justify-end space-x-2'>
        {bookableRoles.some((role) => user.roles.includes(role)) && (
          <SharedButton
            onClick={() => navigate(`/users/manage-work-area/${user.uid}`)}
            type='button'
            appearance='link'
            label={`Manage work areas (${user.areas ? user.areas.length : 0})`}
          />
        )}
        <SharedButton
          onClick={() => dialog?.openDialog(<AddEditUserDialog userUid={user.uid} />)}
          type='button'
          appearance='link'
          label='Edit'
        />
        <SharedButton onClick={() => showDeleteDialog(user)} type='button' appearance='link' label='Delete' />
      </div>
    );
  };

  const showDeleteDialog = (user: IUser) => {
    dialog?.openDialog(<DeleteUserDialog user={user} />);
  };
  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: userCellTemplate,
      id: 'user',
    },
    {
      template: actionCellTemplate,
      id: 'action',
    },
  ];
  const headerActions: PageElement[] = [
    {
      key: 'addUser',
      element: (
        <SharedButton
          onClick={() => dialog?.openDialog(<AddEditUserDialog />)}
          type='button'
          appearance='primary'
          label='Add User'
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Users' actions={headerActions} />
      <div className='mt-4'>
        <SharedTable
          columns={UsersTableColumns}
          customTemplates={customTemplates}
          rows={users.map((user) => ({
            key: user.uid,
            data: {
              ...user,
              rolesString: user.roles?.map((role) => getRoleNameFromKey(role)).join(', '),
            },
          }))}
        />
      </div>
    </>
  );
};

export default UsersList;
