import Select from '@atlaskit/select';
import TextArea from '@atlaskit/textarea';
import TextField from '@atlaskit/textfield';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { ControlType } from 'core/enums/control-type';
import { PageElement, SelectOption } from 'core/utilities/interface-helpers';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ISharedFormField } from 'shared/components/form/form-interface';
import PageHeader from 'shared/components/page-header/page-header';
import {
  OrderAdditionalInfoFormFields,
  OrderBalanceFormFields,
  OrderDepositFormFields,
  OrderDOBFormFields,
  OrderEmailFormFields,
  OrderProductCostFormFields,
  OrderProductDetailFormFields,
  OrderProductDetailGeneralFormFields,
} from 'core/config/form-fields';
import OrderFormDetails from './order-form-details/order-form-details';
import { IOrderFormError, IOrderFormValues, IOrderFormValuesProductDetail } from './order-form-interface';
import OrderFormProductDetails from './order-form-product-details/order-form-product-details';
import OrderFormProductCost from './order-form-product-cost/order-form-product-cost';
import OrderFormDeposit from './order-form-deposit/order-form-deposit';
import OrderFormCustomerDeclaration from './order-form-customer-declaration/order-form-customer-declaration';
import { IAddOrderRequestDto, IOrder } from 'core/api/orders/orders-api-interface';
import { useAuthState } from 'core/providers/AuthProvider';
import SurveysApiService from 'core/api/surveys/surveys-api.service';
import { v4 as uuid } from 'uuid';
import { InputType } from 'core/enums/input-type';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import SignatureCanvas from 'react-signature-canvas';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import { showErrorFlag, showInfoFlag } from 'core/utilities/flags-helper';
import { useFlags } from '@atlaskit/flag';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { AidGrantOption, getAidGrantOptionsFromKey } from 'core/constants/hearing-aid-grant-options';
import DocumentApiService from 'core/api/documents/documents-api.service';
import { OrderStatus } from 'core/constants/order-status';
import CustomersApiService from 'core/api/customers/customers-api.service';
import PageHeaderSkeleton from 'shared/components/page-header/page-header-skeleton';
import SharedButton from 'shared/components/buttons/button';
import { useDialog } from 'core/providers/DialogProvider';
import OrderFormSuccessDialog from './order-form-success-dialog/order-form-success-dialog';
import Toggle from '@atlaskit/toggle';
import { getAidTypeFromKey } from 'core/constants/hearing-aid-types';
import { getAidReceiverGainFromKey } from 'core/constants/hearing-aid-receiver-gains';
import { getAidLengthFromKey } from 'core/constants/hearing-aid-lengths';
import { getAidStyleFromKey } from 'core/constants/hearing-aid-styles';
import { getAidPowerOptionFromKey } from 'core/constants/hearing-aid-power-options';
import { getAidImpressionsRequiredOptionFromKey } from 'core/constants/hearing-aid-impressions-required-options';
import { getPaymentMethodFromKey } from 'core/constants/payment-methods';
import { getObjectChanges } from 'core/utilities/object-helpers';
import { ISurvey } from 'core/api/surveys/surveys-api-interface';
import { getBalancePaymentMethodFromKey } from 'core/constants/balance-payment-methods';
import OrderFormBalance from './order-form-balance/order-form-balance';
import { CertificateStatus } from 'core/constants/certificate-status';

const OrderForm = () => {
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointment');
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useState<IAppointment>();
  const [formLoading, setFormLoading] = useState(false);
  const [formValues, setFormValues] = useState<IOrderFormValues>({
    left: {
      required: false,
    },
    right: {
      required: false,
    },
    financial: {},
    customer: {},
    productDetail: {},
    communication: {
      email: false,
      post: false,
    },
  });
  const { userData } = useAuthState();
  const [formErrors, setFormErrors] = useState<IOrderFormError[]>([]);
  const [dispenserSignature, setDispenserSignature] = useState<SignatureCanvas | null>(null);
  const [customerSignature, setCustomerSignature] = useState<SignatureCanvas | null>(null);
  const [previousSignatures, setPreviousSignatures] = useState<string[]>([]);
  const flags = useFlags();
  const dialog = useDialog();
  const { orderUid } = useParams();
  const editing = orderUid !== undefined;
  const [orderData, setOrderData] = useState<IOrder>();

  const mainFormSections = [
    {
      key: 'productDetail',
      fields: OrderProductDetailGeneralFormFields,
      required: true,
    },
    {
      key: 'left',
      fields: OrderProductDetailFormFields,
      required: formValues.left.required,
    },
    {
      key: 'right',
      fields: OrderProductDetailFormFields,
      required: formValues.right.required,
    },
    {
      key: 'financial',
      fields: OrderProductCostFormFields,
      required: true,
    },
    {
      key: 'financial',
      fields: OrderDepositFormFields,
      required: true,
    },
    {
      key: 'financial',
      fields: OrderBalanceFormFields,
      required: true,
    },
    {
      key: 'customer',
      fields: OrderDOBFormFields,
      required: true,
    },
  ];

  const formSignatures = [
    {
      key: 'dispenserSignature',
      ref: dispenserSignature,
    },
    {
      key: 'customerSignature',
      ref: customerSignature,
    },
  ];

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Appointment information could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createAppointmentSubscription = useCallback(
    (uid: string) => {
      const handleSnapshot = (data: IAppointment) => {
        if (data) {
          setAppointmentData(data);
        } else {
          showErrorFlag(
            'An error occurred',
            'Appointment information could not be retrieved, please try again.',
            flags
          );
        }
      };
      return AppointmentsApiService.subscribeToAppointment(uid, handleSnapshot, handleSubscriptionError);
    },
    [flags, handleSubscriptionError]
  );

  const populateOrderForm = useCallback(async (order: IOrder) => {
    const {
      grant,
      grossPrice,
      discount,
      deposit,
      paymentMethod,
      power,
      impressionsRequired,
      left,
      right,
      ppsn,
      customer,
      additionalInformation,
      status,
      balancePaymentMethod,
    } = order;
    const { dob, emailAddress } = customer;
    const payload: IOrderFormValues = {
      left: {
        required: false,
      },
      right: {
        required: false,
      },
      financial: {
        grant: getAidGrantOptionsFromKey(grant),
        grossPrice,
        discount,
        deposit,
        paymentMethod: getPaymentMethodFromKey(paymentMethod),
        additionalInformation,
        balancePaymentMethod: balancePaymentMethod ? getBalancePaymentMethodFromKey(balancePaymentMethod) : undefined,
      },
      customer: {
        ppsn,
        dob,
      },
      productDetail: {
        power: getAidPowerOptionFromKey(power),
        impressionsRequired: getAidImpressionsRequiredOptionFromKey(impressionsRequired),
      },
      communication: {
        email: false,
        post: status === OrderStatus.AWAITING_FITTING_SEND_PAPERWORK ? true : false,
        emailAddress,
      },
    };

    [left, right].forEach((ear, index) => {
      const key = index === 0 ? 'left' : 'right';
      if (ear) {
        const { type, gain, length, style } = ear;
        payload[key] = {
          required: true,
          type: getAidTypeFromKey(type),
          gain: getAidReceiverGainFromKey(gain),
          length: getAidLengthFromKey(length),
          style: getAidStyleFromKey(style),
        };
      }
    });

    setFormValues(payload);
  }, []);

  const getOrderData = useCallback(
    async (uid: string) => {
      try {
        const { data } = await OrdersApiService.get(uid);
        const signaturePromises = [
          DocumentApiService.getFileBase64(data.dispenserSignaturePath),
          DocumentApiService.getFileBase64(data.customerSignaturePath),
        ];
        const result = await Promise.all(signaturePromises);
        setPreviousSignatures(result.map((sig) => `data:png;base64,${sig}`));
        setOrderData(data);
        populateOrderForm(data);
        createAppointmentSubscription(data.linkedAppointmentUid);
      } catch (error) {
        showErrorFlag('An error occurred', 'Order information could not be retrieved, please try again.', flags);
      }
    },
    [createAppointmentSubscription, flags, populateOrderForm]
  );

  useEffect(() => {
    if (orderUid) {
      getOrderData(orderUid);
    } else if (!appointmentId || appointmentId === 'undefined') {
      navigate('orders');
    } else {
      createAppointmentSubscription(appointmentId);
    }
  }, [appointmentId, createAppointmentSubscription, getOrderData, navigate, orderUid]);

  useEffect(() => {
    if (appointmentData && !editing) {
      const { ppsn, dob, emailAddress } = appointmentData.customer;
      setFormValues((prevState) => ({
        ...prevState,
        customer: {
          dob,
          ppsn,
        },
        communication: {
          ...prevState.communication,
          emailAddress,
        },
      }));
    }
  }, [appointmentData, editing]);

  if (!appointmentData || !userData) {
    return <PageHeaderSkeleton actions={[{ key: 'button', element: <></> }]} subtitle={false} />;
  }

  const showFormError = (fieldKey: string, formKey?: string) =>
    formErrors.some((err) => {
      return err.fieldKey === fieldKey && (formKey ? err.groupKey === formKey : true);
    });

  const getFormInput = (field: ISharedFormField, formKey: string) => {
    switch (field.control) {
      case ControlType.Select:
        return (
          <Select<SelectOption>
            options={field.options}
            isSearchable={false}
            onChange={(value) => valueChanged(formKey, field.key, value)}
            value={formValues[formKey][field.key] ?? null}
            validationState={showFormError(field.key, formKey) ? 'error' : 'default'}
            isDisabled={formLoading}
          />
        );
      case ControlType.TextArea:
        return (
          <TextArea
            minimumRows={8}
            onChange={(e) => valueChanged(formKey, field.key, e.currentTarget.value)}
            value={formValues[formKey][field.key] ?? ''}
            isDisabled={formLoading}
          />
        );
      default:
        return (
          <TextField
            type={field.type}
            onChange={(e) => {
              let value: string | number = e.currentTarget.value;
              if (field.type === InputType.Number && isNotNullOrEmpty(value)) {
                value = +value;
              }
              valueChanged(formKey, field.key, value);
            }}
            value={formValues[formKey][field.key] ?? ''}
            isInvalid={showFormError(field.key, formKey)}
            isDisabled={formLoading}
          />
        );
    }
  };

  const valueChanged = (formKey: string, fieldKey: string, value: string | boolean | SelectOption | null | number) => {
    setFormErrors([]);
    setFormValues((prevState) => {
      return {
        ...prevState,
        [formKey]: {
          ...prevState[formKey],
          [fieldKey]: value,
        },
      };
    });
  };

  const copyProductDetail = (key: string) => {
    const dataToCopy = formValues[key];
    const keyToUpdate = key === 'left' ? 'right' : 'left';
    setFormErrors([]);
    setFormValues((prevState) => {
      return {
        ...prevState,
        [keyToUpdate]: dataToCopy,
      };
    });
  };

  const getFormFields = (fields: ISharedFormField[], formKey: string) =>
    fields
      .filter((field) => !field.hidden)
      .map((field) => (
        <div key={field.key} className='mt-3 first:mt-0'>
          {field.label && <p className='label-02 font-semibold mb-1 text-gray-500'>{field.label}</p>}
          {getFormInput(field, formKey)}
          {showFormError(field.key, formKey) && <p className='mt-1 label-02 text-red-600'>This field is required</p>}
        </div>
      ));

  const validateForm = (): boolean => {
    const { left, right, financial, customer, communication } = formValues;
    let formErrors: IOrderFormError[] = [];

    const validationSteps: { truthy: boolean; fieldKey: string; groupKey?: string }[] = [
      {
        truthy: !left.required && !right.required,
        fieldKey: 'noProducts',
      },
      ...mainFormSections.flatMap((formGroup) => {
        const { key, fields, required } = formGroup;
        return fields
          .filter((field) => field.required && required)
          .map((field) => ({
            truthy: !isNotNullOrEmpty(formValues[key][field.key]),
            groupKey: key,
            fieldKey: field.key,
          }));
      }),
      ...formSignatures.map((signature) => ({
        truthy: signature.ref?.isEmpty()!,
        fieldKey: signature.key,
      })),
      {
        truthy: financial.grant?.value === AidGrantOption.GRANT && !isNotNullOrEmpty(customer.ppsn),
        groupKey: 'customer',
        fieldKey: 'ppsn',
      },
      {
        truthy: communication.email && !isNotNullOrEmpty(communication.emailAddress),
        groupKey: 'communication',
        fieldKey: 'emailAddress',
      },
    ];

    validationSteps.forEach((check) => {
      const { truthy, fieldKey, groupKey } = check;
      if (truthy) {
        const payload = { fieldKey, ...(groupKey && { groupKey }) };
        formErrors.push(payload);
      }
    });

    if (Object.values(formErrors).length) {
      setFormErrors(formErrors);
      setFormLoading(false);
      return false;
    }
    return true;
  };

  const submitForm = async () => {
    setFormErrors([]);
    setFormLoading(true);
    try {
      const valid = validateForm();
      if (!valid) {
        return;
      }
      const { impressionsRequired, power } = formValues.productDetail;
      const { grant, grossPrice, discount, deposit, paymentMethod, additionalInformation, balancePaymentMethod } =
        formValues.financial;
      const { dob, ppsn } = formValues.customer;
      const { emailAddress, email, post } = formValues.communication;
      const now = new Date();
      const { uid, fullName } = userData;
      const author = { uid, fullName };
      const { roles, ...rest } = appointmentData.assignee;

      const ears = ['left', 'right'].map((side) => {
        const values: IOrderFormValuesProductDetail = formValues[side];
        if (values.required) {
          const { type, style, length, gain } = values;
          return {
            type: type?.value!,
            style: style?.value!,
            length: length?.value!,
            gain: gain?.value!,
          };
        }

        return null;
      });

      const base = {
        uid: editing ? orderUid : uuid(),
        dispenser: rest,
        customer: {
          ...appointmentData.customer,
          dob,
          emailAddress: emailAddress ?? appointmentData.customer.emailAddress,
        },
        linkedAppointmentUid: appointmentData.uid,
        updatedAt: now,
        updatedBy: author,
        grant: grant?.value!,
        grossPrice: grossPrice!,
        discount: discount!,
        deposit: deposit!,
        totalPaid: deposit!,
        paymentMethod: paymentMethod?.value!,
        balancePaymentMethod: balancePaymentMethod?.value!,
        additionalInformation: additionalInformation ?? '',
        ppsn: ppsn ?? '',
        impressionsRequired: impressionsRequired?.value!,
        power: power?.value!,
        discountedPrice: grossPrice! - discount!,
        left: ears[0],
        right: ears[1],
        status: post ? OrderStatus.AWAITING_FITTING_SEND_PAPERWORK : OrderStatus.AWAITING_FITTING,
      };
      if (!editing || !orderData) {
        let relatedSurveys: ISurvey[] = [];
        try {
          const { data } = await SurveysApiService.getSurveysForCustomer(appointmentData.customer.uid);
          relatedSurveys = data;
        } catch (error) {
          console.error(
            `There was an error trying to retrieve the surveys for customer:${appointmentData.customer.uid}`
          );
        }
        try {
          const signatureUploads = formSignatures.map((sig) =>
            DocumentApiService.upload(
              sig.ref?.getTrimmedCanvas().toDataURL()!,
              'image/png',
              `signatures/${sig.key}/${base.uid}`
            )
          );
          await Promise.all(signatureUploads);
        } catch (error) {
          console.error(`Signature upload failed customer:${appointmentData.customer.uid}`);
          throw error;
        }
        const payload: IAddOrderRequestDto = {
          createdAt: now,
          createdBy: author,
          customerSignaturePath: `signatures/customerSignature/${base.uid}`,
          dispenserSignaturePath: `signatures/dispenserSignature/${base.uid}`,
          booker: appointmentData.organiser,
          certificate: {
            status:
              grant?.value! === AidGrantOption.NO_GRANT
                ? CertificateStatus.NOT_REQUIRED
                : CertificateStatus.AWAITING_FITTING,
          },
          ...base,
        };
        if (relatedSurveys.length) {
          payload.surveyor = relatedSurveys[0].surveyor;
        }
        const { customer, status, ...rest } = { ...payload };
        try {
          await OrdersApiService.set(payload, getObjectChanges(rest, {}));
        } catch (error) {
          console.error(`Could not create the order for customer:${appointmentData.customer.uid}`);
          throw error;
        }
      } else {
        const { customer, status, ...rest } = { ...base };
        try {
          await OrdersApiService.update(base.uid, base.customer.uid, base, getObjectChanges(rest, orderData));
        } catch (error) {
          console.error(`Could not update the order for customer:${appointmentData.customer.uid}`);
          throw error;
        }
      }
      const payload = {
        dob,
        emailAddress: emailAddress ?? appointmentData.customer.emailAddress,
      };

      try {
        await CustomersApiService.update(
          appointmentData.customer.uid,
          {
            fullName: userData.fullName,
            uid: userData.uid,
          },
          payload,
          getObjectChanges(payload, appointmentData.customer)
        );
      } catch (error) {
        console.error(
          `Tried to perform a customer update as part of an order for this customer:${appointmentData.customer.uid}, but this failed`
        );
      }

      let docPath: string | undefined;

      try {
        docPath = await OrdersApiService.generateSalesOrder({ uid: base.uid });
      } catch (error) {
        console.error(
          `Sales order could not be generated for customer:${appointmentData.customer.uid}, order:${base.uid}`
        );
        showInfoFlag(
          'Something went wrong',
          `The order was successfully saved but the sales order document failed to generate, please inform the office.`,
          flags
        );
      }

      if (email && docPath) {
        try {
          await OrdersApiService.sendOrderConfirmationEmail({ uid: base.uid });
        } catch (error) {
          console.error(
            `Confirmation email could not be sent to the customer:${appointmentData.customer.uid}, order:${base.uid}, error: ${error}`
          );
          showInfoFlag(
            'Something went wrong',
            `The order was successfully saved but the requested confirmation email failed to send, please inform the customer and the office.`,
            flags
          );
        }
      }

      if (docPath) {
        dialog?.openDialog(<OrderFormSuccessDialog path={docPath} />);
      } else {
        navigate(-1);
      }
      setFormLoading(false);
    } catch (error) {
      console.error(error);
      showErrorFlag('Order creation failed', `Unable to create this order, please try again`, flags);
      setFormLoading(false);
    }
  };

  const headerActions: PageElement[] = [
    {
      key: 'cancel',
      element: <SharedButton onClick={() => navigate(-1)} type='button' appearance='subtle' label='Cancel' />,
    },
    {
      key: 'submit',
      element: (
        <SharedLoadingButton
          isLoading={formLoading}
          onClick={submitForm}
          type='button'
          appearance='primary'
          label='Submit'
        />
      ),
    },
  ];

  const communicationOptions = [
    { key: 'email', label: 'Send the customer a copy of the sales order via email' },
    { key: 'post', label: 'Request office to send physical copy of the sales order in the post' },
  ];

  return (
    <>
      <PageHeader title='Order form' actions={headerActions} />
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-4'>
        <OrderFormDetails appointment={appointmentData} />
        <div className='lg:col-span-3'>
          <OrderFormProductDetails
            valueChanged={valueChanged}
            formValues={formValues}
            getFormFields={getFormFields}
            copyProductDetail={copyProductDetail}
            showFormError={showFormError}
          />
          <OrderFormProductCost formValues={formValues} getFormFields={getFormFields} />
          <OrderFormDeposit
            formValues={formValues}
            getFormFields={getFormFields}
            setSignature={setDispenserSignature}
            showFormError={showFormError}
            editing
            previousSignature={previousSignatures[0]}
          />
          <OrderFormBalance
            formValues={formValues}
            getFormFields={getFormFields}
            showFormError={showFormError}
            editing
          />
          <OrderFormCustomerDeclaration
            formValues={formValues}
            getFormFields={getFormFields}
            setSignature={setCustomerSignature}
            showFormError={showFormError}
            editing
            previousSignature={previousSignatures[1]}
          />
          <div className='bg-white w-full rounded-md shadow-md h-fit mt-4'>
            <div className='p-4 border-b headline-06'>Additional information</div>
            <div className='p-4'>{getFormFields(OrderAdditionalInfoFormFields, 'financial')}</div>
          </div>
          <div className='bg-white w-full rounded-md shadow-md h-fit mt-4 p-4'>
            {communicationOptions.map((option) => (
              <div className='flex justify-between items-center' key={option.key}>
                <p className='body-02'>{option.label}</p>
                <Toggle
                  size='large'
                  onChange={() => valueChanged('communication', option.key, !formValues.communication[option.key])}
                  isChecked={formValues.communication[option.key]}
                />
              </div>
            ))}
            {formValues.communication.email && getFormFields(OrderEmailFormFields, 'communication')}
          </div>
          <div className='pt-6 flex justify-end'>
            {headerActions.map((action) => (
              <div key={action.key} className='mr-4 last:mr-0 mb-6 lg:mb-0'>
                {action.element}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderForm;
