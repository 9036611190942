import { Permissions } from 'core/constants/permissions';
import RoleHandler from 'core/routing/role-handler';
import { Navigate, Route, Routes } from 'react-router-dom';
import OrderForm from './order-form/order-form';
import OrdersList from './orders-list/orders-list';

const OrdersRouting = () => {
  return (
    <Routes>
      <Route element={<RoleHandler requiredPermissions={[Permissions.ORDER_LIST]} includeLayout={false} />}>
        <Route path='' element={<OrdersList />} />
      </Route>
      <Route element={<RoleHandler requiredPermissions={[Permissions.ORDER_CREATE]} includeLayout={false} />}>
        <Route path='new' element={<OrderForm />} />
      </Route>
      <Route element={<RoleHandler requiredPermissions={[Permissions.ORDER_UPDATE]} includeLayout={false} />}>
        <Route path='edit/:orderUid' element={<OrderForm />} />
      </Route>
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default OrdersRouting;
