import { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { InputType } from 'core/enums/input-type';
import { createAddressString } from 'core/utilities/address-string-builder';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import { useEffect, useState } from 'react';
import SharedButton from 'shared/components/buttons/button';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';

interface IAddressSearch {
  fieldProps: any;
  formError?: string;
}

const fields = [
  {
    key: 'line1',
    label: 'Line 1',
    required: true,
  },
  {
    key: 'line2',
    label: 'Line 2',
    required: true,
  },
  {
    key: 'city',
    label: 'City/Town',
    required: true,
  },
  {
    key: 'eircode',
    label: 'Eircode',
    required: false,
  },
];

interface AddressFormOutput extends StringIndexable {
  line1?: string;
  line2?: string;
  city?: string;
  eircode?: string;
}

interface LatLng {
  lat: number;
  lng: number;
}

const AddressSearch = ({ fieldProps, formError }: IAddressSearch) => {
  const [value, setValue] = useState<AddressFormOutput>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [valid, setValid] = useState(false);
  const [showEircodeFinder, setShowEircodeFinder] = useState(false);
  const [position, setPosition] = useState<LatLng>();

  useEffect(() => {
    if (fieldProps.value.fullAddress) {
      const { line_1, line_2, town_or_city, postcode, longitude, latitude } = fieldProps.value.fullAddress;
      setValue({
        line1: line_1,
        line2: line_2,
        city: town_or_city,
        eircode: postcode,
      });
      setPosition({ lng: longitude, lat: latitude });
      setValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputChanged = (key: string, value?: string) => {
    setValid(false);
    setError(undefined);
    setValue((prevState) => {
      return { ...prevState, [key]: value };
    });
    fieldProps.onChange({
      valid: false,
    });
  };

  const validateAddress = async () => {
    setLoading(true);
    setShowEircodeFinder(false);
    const valid = fields.filter((field) => field.required).every((field) => isNotNullOrEmpty(value[field.key]));
    if (!valid) {
      setError('Please complete all of the fields');
      setLoading(false);
    } else {
      try {
        const address = createAddressString(fields.map((field) => value[field.key].trim()));
        const results = await getGeocode({
          address,
          componentRestrictions: {
            country: 'ie',
          },
        });
        const { lat, lng } = getLatLng(results[0]);
        setPosition({ lat, lng });
        const fullAddress: StringIndexable = {
          postcode: value.eircode ?? '',
          latitude: lat,
          longitude: lng,
          formatted_address: address,
          thoroughfare: '',
          building_name: '',
          sub_building_name: '',
          sub_building_number: '',
          building_number: '',
          line_1: value.line1!.trim(),
          line_2: value.line2!.trim(),
          line_3: '',
          line_4: '',
          locality: '',
          town_or_city: value.city!.trim(),
          county: '',
          district: '',
          country: '',
          residential: true,
        };
        fieldProps.onChange({
          fullAddress,
          valid: true,
        });
        setLoading(false);
        setValid(true);
      } catch (error) {
        fieldProps.onChange({
          valid: false,
        });
        setError('Address could not be found, please try again');
        setLoading(false);
      }
    }
  };

  const mapContainerStyle = {
    height: '200px',
    width: '100%',
  };

  return (
    <div className='relative mt-6 my-4 pb-6 py-4 border-t border-b'>
      <p className='font-semibold mb-4 body-02'>Address</p>
      {valid ? (
        <>
          <GoogleMap id='marker-example' mapContainerStyle={mapContainerStyle} zoom={14} center={position}>
            <Marker position={position!} />
          </GoogleMap>
          <p className='body-02 mt-3'>{createAddressString(fields.map((field) => value[field.key]))}</p>
        </>
      ) : (
        <>
          {fields.map((field) => (
            <Field
              key={field.key}
              aria-required={field.required}
              name={field.key}
              defaultValue={(value[field.key] as string) ?? ''}
              label={field.label}
              isRequired={field.required}
              isDisabled={loading || valid}
            >
              {({ fieldProps }) => (
                <TextField
                  {...fieldProps}
                  type={InputType.Text}
                  onChange={(value) => inputChanged(field.key, value.currentTarget.value)}
                />
              )}
            </Field>
          ))}
          <div className='label-01 mt-4 text-red-600'>
            {error && <p>{error}</p>}
            {formError && <p>{formError}</p>}
          </div>
        </>
      )}
      <div className='mt-4 flex items-center'>
        {!valid ? (
          <>
            <SharedLoadingButton
              onClick={validateAddress}
              type='button'
              appearance='default'
              label='Validate address'
              isLoading={loading}
            />
            <div className='ml-4'>
              <SharedButton
                onClick={() => setShowEircodeFinder(!showEircodeFinder)}
                type='button'
                appearance='default'
                label={showEircodeFinder ? 'Hide Eircode finder' : 'Show Eircode finder'}
              />
            </div>
          </>
        ) : (
          <SharedButton
            onClick={() => {
              setValid(false);
              fieldProps.onChange({
                valid: false,
              });
            }}
            type='button'
            appearance='default'
            label='Edit address'
          />
        )}
      </div>
      {showEircodeFinder && (
        <iframe className='w-full h-[400px] mt-6' title='eircodeFinder' src='https://finder.eircode.ie/#/' />
      )}
    </div>
  );
};

export default AddressSearch;
