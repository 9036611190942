import { Navigate, Route, Routes } from 'react-router-dom';
import SurveyorDashboard from './surveyor-dashboard/surveyor-dashboard';

const SurveyingRouting = () => {
  return (
    <Routes>
      <Route path='' element={<SurveyorDashboard />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default SurveyingRouting;
