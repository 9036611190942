import { useFlags } from '@atlaskit/flag';
import { ISurvey } from 'core/api/surveys/surveys-api-interface';
import SurveysApiService from 'core/api/surveys/surveys-api.service';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { PageElement } from 'core/utilities/interface-helpers';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { CustomersSlice } from 'modules/customers/customers-slice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SharedButton from 'shared/components/buttons/button';
import PageHeader from 'shared/components/page-header/page-header';
import SharedTable from 'shared/components/table/table';
import { ISharedTableColumn } from 'shared/components/table/table-interface';
import CreateSurveyDialog from '../create-survey-dialog/create-survey-dialog';
import { ISurveyDashboardTableRow } from './surveyor-dashboard-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';

const SurveyorDashboardTableColumns: ISharedTableColumn[] = [
  { label: 'Customer', key: 'customerName', templateId: 'text', width: 50 },
  { label: 'Created', key: 'createdTime', templateId: 'text', width: 50 },
];

const SurveyorDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<ISurveyDashboardTableRow[]>([]);

  const flags = useFlags();
  const dialog = useDialog();
  const { userData } = useAuthState();
  const customers = useSelector(CustomersSlice.selectAll);

  useEffect(() => {
    const handleSnapshot = async (querySnapshot: QuerySnapshot<ISurvey>) => {
      const promises = querySnapshot.docs.map((snapshot) => {
        return new Promise<ISurveyDashboardTableRow | undefined>(async (resolve, reject) => {
          const surveyData = snapshot.data();
          const customer = customers.find((customer) => customer.uid === surveyData.customerUid);
          if (customer) {
            resolve({
              key: surveyData.uid,
              data: {
                customerName: customer.fullName,
                createdTime: dayjs(surveyData.createdAt.toDate()).format('HH:mm'),
              },
            });
          } else {
            try {
              const response = await CustomersApiService.get(surveyData.customerUid);
              resolve({
                key: surveyData.uid,
                data: {
                  customerName: response.data.fullName,
                  createdTime: dayjs(surveyData.createdAt.toDate()).format('HH:mm'),
                },
              });
            } catch (error) {
              resolve(undefined);
            }
          }
        });
      });
      const result = await Promise.all(promises);
      setRows(result.filter((data): data is ISurveyDashboardTableRow => !!data));
      setLoading(false);
    };

    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Could not retrieve users, please try again.', flags);
    };

    let unsubscribe: Unsubscribe;
    setLoading(true);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    unsubscribe = SurveysApiService.subscribeToSurveys(
      handleSnapshot,
      handleSubscriptionError,
      {
        surveyorUid: userData?.uid,
        afterDate: date,
      },
      ['createdAt']
    );

    return () => {
      unsubscribe();
    };
  }, [customers, flags, userData?.uid]);

  const headerActions: PageElement[] = [
    {
      key: 'createSurvey',
      element: (
        <SharedButton
          onClick={() => {
            dialog?.openDialog(<CreateSurveyDialog />);
          }}
          type='button'
          appearance='primary'
          label='Create survey'
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Surveying Dashboard' actions={headerActions} />
      <div className='flex py-4 border-b body-02'>
        <p>
          Surveys completed today: <span className='font-semibold'>{rows.length}</span>
        </p>
      </div>
      <div className='mt-4'>
        <SharedTable
          columns={SurveyorDashboardTableColumns}
          rows={rows}
          emptyText={"You've not created any surveys today"}
          isLoading={loading}
        />
      </div>
    </>
  );
};

export default SurveyorDashboard;
