import { Navigate, Route, Routes } from 'react-router-dom';
import WelfareDashboard from './welfare-dashboard/welfare-dashboard';

const WelfareRouting = () => {
  return (
    <Routes>
      <Route path='' element={<WelfareDashboard />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default WelfareRouting;
