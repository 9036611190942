export const PaymentMethod = {
  CHEQUE: 'cheque',
  CASH: 'cash',
  CARD: 'card',
  NO_DEPOSIT: 'noDeposit',
};

export const PaymentMethods = [
  {
    label: 'Cheque',
    value: PaymentMethod.CHEQUE,
  },
  {
    label: 'Cash',
    value: PaymentMethod.CASH,
  },
  {
    label: 'Credit/Debit card',
    value: PaymentMethod.CARD,
  },
  {
    label: 'No deposit',
    value: PaymentMethod.NO_DEPOSIT,
  },
];

export const getPaymentMethodFromKey = (key: string) => {
  return PaymentMethods.find((option) => option.value === key);
};

export const getPaymentMethodNameFromKey = (key: string) => {
  const option = PaymentMethods.find((option) => option.value === key);
  return option?.label ?? 'Unknown';
};
