export const BalancePaymentMethod = {
  CHEQUE: 'cheque',
  CASH: 'cash',
  CARD: 'card',
  ILAH_SPREAD: 'ilahSpread',
  HUMM: 'humm',
  NO_BALANCE: 'noBalance',
};

export const BalancePaymentMethods = [
  {
    label: 'Cheque',
    value: BalancePaymentMethod.CHEQUE,
  },
  {
    label: 'Cash',
    value: BalancePaymentMethod.CASH,
  },
  {
    label: 'Credit/Debit card',
    value: BalancePaymentMethod.CARD,
  },
  {
    label: 'ILAH spread payment',
    value: BalancePaymentMethod.ILAH_SPREAD,
  },
  {
    label: 'Humm',
    value: BalancePaymentMethod.HUMM,
  },
  {
    label: 'No balance',
    value: BalancePaymentMethod.NO_BALANCE,
  },
];

export const getBalancePaymentMethodFromKey = (key: string) => {
  return BalancePaymentMethods.find((option) => option.value === key);
};

export const getBalancePaymentMethodNameFromKey = (key: string) => {
  const option = BalancePaymentMethods.find((option) => option.value === key);
  return option?.label ?? 'Unknown';
};
