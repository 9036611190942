import { OrderBalanceFormFields } from 'core/config/form-fields';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { IOrderFormValues } from '../order-form-interface';
import { formatToEuro } from 'core/utilities/currency-helpers';
interface IOrderFormBalance {
  formValues: IOrderFormValues;
  getFormFields: (fields: ISharedFormField[], formKey: string) => JSX.Element[];
  showFormError: (fieldKey: string, formKey?: string) => boolean;
  editing: boolean;
}

const OrderFormBalance = ({ formValues, getFormFields, showFormError }: IOrderFormBalance) => {
  const getBalanceDue = () => {
    const { deposit, grossPrice, discount } = formValues.financial;
    return formatToEuro((grossPrice ?? 0) - (discount ?? 0) - (deposit ?? 0));
  };

  return (
    <div className='bg-white w-full rounded-md shadow-md h-fit mt-4'>
      <div className='p-4 border-b headline-06'>Balance</div>
      <div className='p-4'>
        {getFormFields(OrderBalanceFormFields, 'financial')}
        <div className='headline-07 mt-4'>Balance: {getBalanceDue()}</div>
      </div>
    </div>
  );
};

export default OrderFormBalance;
