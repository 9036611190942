import { FlagAPI } from '@atlaskit/flag';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import InfoIcon from '@atlaskit/icon/glyph/info';

export const showSuccessFlag = (title: string, description: string, flags: FlagAPI) => {
  flags.showFlag({
    icon: (
      <div className='text-green-500'>
        <CheckCircleIcon label='' />
      </div>
    ),
    title,
    description,
    isAutoDismiss: true,
  });
};

export const showErrorFlag = (title: string, description: string, flags: FlagAPI) => {
  flags.showFlag({
    icon: (
      <div className='text-red-500 text-[1px]'>
        <CrossCircleIcon label='' />
      </div>
    ),
    title,
    description,
    isAutoDismiss: true,
  });
};

export const showInfoFlag = (title: string, description: string, flags: FlagAPI) => {
  flags.showFlag({
    icon: (
      <div className='text-blue-500 text-[1px]'>
        <InfoIcon label='' />
      </div>
    ),
    title,
    description,
    isAutoDismiss: true,
  });
};
